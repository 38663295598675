// Modules
import React from "react";
import PropTypes from "prop-types";

const StrategyOne = (props) => {
	const { clssName } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			x="0px"
			y="0px"
			viewBox="0 0 310 71"
			className={clssName}
			style={{ enableBackground: "new 0 0 310 71" }}
		>
			<g>
				<text
					transform="matrix(1 0 0 1 149.2609 33.5022)"
					style={{ fill: "var(--primary)", fontFamily: "var(--PlayfairMedium)", fontSize: ".88vh" }}
				>
					Équilibrée 
				</text>
				<g>
					<path
						style={{ fill: "none", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
						d="M159.5,42.9c0,0-0.1-4.5,4.2-4.3s7.9-0.1,9.9-0.7c3.4-0.9,7.3-0.2,9.3,3.9c2.3,4.7,1.6,7.3-3.1,8.7    c-3.7,1-8,1.3-14.1-0.6C159.7,48.1,159.5,42.9,159.5,42.9z"
					/>
					<path
						style={{ fill: "var(--primary)" }}
						d="M163.7,43.4c0,0-0.1-3,2.8-2.8c2.8,0.1,5.3-0.1,6.6-0.4c2.2-0.6,4.9-0.1,6.2,2.6c1.5,3.1,1.1,4.9-2.1,5.8    c-2.5,0.7-5.3,0.9-9.4-0.4S163.7,43.4,163.7,43.4z"
					/>
					<path
						style={{ fill: "var(--white)" }}
						d="M167.4,43.9c0,0,0-1.6,1.5-1.6c1.6,0.1,2.9-0.1,3.6-0.2c1.2-0.3,2.7-0.1,3.4,1.4c0.8,1.7,0.6,2.7-1.2,3.2    c-1.4,0.4-2.9,0.5-5.1-0.2C167.4,45.8,167.4,43.9,167.4,43.9z"
					/>
				</g>
			</g>
			<text
				transform="matrix(1 0 0 1 33.6759 29.8853)"
				style={{ fill: "var(--primary)", fontFamily: "var(--PlayfairMedium)", fontSize: "1.23vh" }}
			>
				Placement
			</text>
			<g>
				<path
					style={{ fill: "none", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
					d="M47.7,44.9c0,0-0.2-6.4,5.9-6.1s11.2-0.2,14.1-1c4.8-1.3,10.4-0.3,13.3,5.6c3.3,6.7,2.3,10.4-4.5,12.3   c-5.3,1.4-11.4,1.9-20-0.8S47.7,44.9,47.7,44.9z"
				/>
				<path
					style={{ fill: "none", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
					d="M53.6,45.4c0,0,0-4.3,4-3.9c4,0.3,7.5,0.1,9.4-0.4c3.2-0.8,6.9,0,8.7,4c2.1,4.5,1.4,7-3.2,8.1   C69,54,65,54.2,59.3,52.3C53.5,50.3,53.6,45.4,53.6,45.4z"
				/>
				<path
					style={{ fill: "var(--primary)" }}
					d="M58.8,46.4c0,0-0.1-2.3,2.2-2.2c2.2,0.1,4.1-0.1,5.1-0.4c1.8-0.5,3.8-0.1,4.9,2c1.2,2.4,0.9,3.8-1.6,4.5   c-1.9,0.5-4.2,0.7-7.3-0.3C58.9,49.1,58.8,46.4,58.8,46.4z"
				/>
			</g>
			<g>
				<text
					transform="matrix(1 0 0 1 228.5666 32.7191)"
					style={{ fill: "var(--primary)", fontFamily: "var(--PlayfairMedium)", fontSize: ".88vh" }}
				>
					Développement
				</text>
				<g>
					<path
						style={{ fill: "var(--primary)" }}
						d="M249.8,43.5c0,0-0.1-4.6,4.3-4.4c4.4,0.2,8.1-0.1,10.1-0.7c3.4-0.9,7.5-0.2,9.5,4c2.3,4.8,1.7,7.5-3.2,8.8    c-3.8,1-8.2,1.4-14.4-0.6C249.9,48.8,249.8,43.5,249.8,43.5z"
					/>
					<path
						style={{ fill: "var(--white)", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
						d="M254,43.8c0,0,0-3.1,2.9-2.8c2.9,0.2,5.4,0,6.7-0.3c2.3-0.6,5,0,6.3,2.8c1.5,3.2,1,5-2.3,5.8    c-2.5,0.6-5.5,0.8-9.6-0.6C254,47.4,254,43.8,254,43.8z"
					/>
					<path
						style={{ fill: "var(--white)", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
						d="M257.8,44.5c0,0,0-1.7,1.6-1.6s2.9-0.1,3.7-0.3c1.3-0.3,2.7-0.1,3.5,1.5c0.9,1.8,0.6,2.7-1.2,3.2    c-1.4,0.4-3,0.5-5.3-0.2C257.8,46.5,257.8,44.5,257.8,44.5z"
					/>
				</g>
			</g>
			<rect
				x="127"
				y="17.2"
				style={{ fill: "none", stroke: "var(--primary)", strokeMiterlimit: "10" }}
				width="90.3"
				height="42.3"
			/>
			<rect
				x="217.3"
				y="17.2"
				style={{ fill: "none", stroke: "var(--primary)", strokeMiterlimit: "10" }}
				width="90.3"
				height="42.3"
			/>
			<rect
				x="2.4"
				y="5.6"
				style={{ fill: "none", stroke: "var(--orange)", strokeMiterlimit: "10" }}
				width="124.6"
				height="59.8"
			/>
		</svg>
	);
};

StrategyOne.propTypes = {
	clssName: PropTypes.string,
};

StrategyOne.defaultProps = {
	clssName: "",
};

export default StrategyOne;
