// Modules
import React from "react";
import PropTypes from "prop-types";

const Checked = (props) => {
	const {
		clssName,
	} = props;

	return (
		<svg
			enableBackground="new 0 0 515.556 515.556"
			height="512"
			width="512"
			viewBox="0 0 515.556 515.556"
			xmlns="http://www.w3.org/2000/svg"
			className={clssName}
		>
			<path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
		</svg>
	);
};

Checked.propTypes = {
	clssName: PropTypes.string,
};

Checked.defaultProps = {
	clssName: "",
};

export default Checked;