// Modules
import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

// Context
import ApiContext from "../../../contexts/ApiContext";

// Assets
import "./tooltip.scss";

const Tooltip = (props) => {
	const {
		visibleText,
		tooltipText,
		className,
		loader,
		keyProp,
		isScrolling,
	} = props;
	const [isHovering, setIsHovering] = useState(false);
	const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);

	useEffect(() => {
		if (isScrolling === true && isHovering === true)
			setIsHovering(false);
	}, [isScrolling]); // eslint-disable-line

	return (
		<React.Fragment>
			{visibleText !== null && (typeof (visibleText) === "string" || visibleText.type)
				? (
					<h6 className={`visible-text ${className}`}>
						{loader
							? (
								<Skeleton
									animation="wave"
									variant="text"
									width="9vh"
									className="visible-text"
								/>
							) : visibleText}
						<div
							className="bubble-info"
							onMouseEnter={() => {
								if (isMobile === true || isTablet === true || isSplitScreen === true)
									return;
								setIsHovering(true);
							}}
							onMouseLeave={() => {
								if (isMobile === true || isTablet === true || isSplitScreen === true)
									return;
								setIsHovering(false);
							}}
							onClick={() => {
								if (isMobile === false && isTablet === false)
									return;
								setIsHovering(!isHovering);
							}}
						>
							<span />
							?
						</div>
						<div className={`tooltip ${keyProp} ${isHovering === true ? "hovered" : ""}`}>
							<p>{tooltipText}</p>
						</div>
					</h6>
				) : null}
			{visibleText === null
				? (
					<div className={`visible-text ${className}`}>
						<div
							className="bubble-info"
							onMouseEnter={() => {
								if (isMobile === true || isTablet === true || isSplitScreen === true)
									return;
								setIsHovering(true);
							}}
							onMouseLeave={() => {
								if (isMobile === true || isTablet === true || isSplitScreen === true)
									return;
								setIsHovering(false);
							}}
							onClick={() => {
								if (isMobile === false && isTablet === false)
									return;
								setIsHovering(!isHovering);
							}}
						>
							<span />
							?
							<div className={`tooltip tooltip-dpe ${keyProp} ${isHovering === true ? "hovered" : ""}`}>
							<div 
										onClick={() => {
											if (isMobile === false && isTablet === false)
												return;
											setIsHovering(false);
										}} 
										className="closeTooltip">x</div>
								<p>{tooltipText}</p>
							</div>
						</div>
					</div>
				) : null}
		</React.Fragment>
	);
};

Tooltip.propTypes = {
	visibleText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltipText: PropTypes.string.isRequired,
	className: PropTypes.string,
	loader: PropTypes.bool,
	keyProp: PropTypes.string.isRequired,
	isScrolling: PropTypes.bool,
};

Tooltip.defaultProps = {
	visibleText: null,
	className: "",
	loader: false,
	isScrolling: false,
};

export default Tooltip;