// Modules
import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

// Assets
import "./switch.scss";

const Switch = (props) => {
  const {
    clssName,
    onClick,
    isActive,
    loader,
    label,
  } = props;

  function handleClickCheckbox(e) {
    e.stopPropagation();
  }

  return (
    <div className={`switch-svg-container ${clssName}`}>
      {label !== null && typeof (label) === "string"
        ? (
          <h6 className="label">
            {loader
              ? <Skeleton animation="wave" variant="text" width="9vh" />
              : label}
          </h6>
        ) : null}
      {label !== null && label.type
        ? label
        : null}
      <div className="input-switch-wrapper">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          className="switch-svg"
          y="0px"
          viewBox="0 0 40 25"
          style={{ enableBackground: "new 0 0 40 25" }}
        >
          <g>
            <path
              className={`${isActive ? "active" : ""}`}
              fill="gray"
              d="M24.4,18.1h-9.6c-3,0-5.5-2.5-5.5-5.5v0c0-3,2.5-5.5,5.5-5.5h9.6c3,0,5.5,2.5,5.5,5.5v0
						C29.9,15.6,27.4,18.1,24.4,18.1z"
            />
          </g>
          <circle
            className={`circle ${isActive ? "active" : ""}`}
            r="3.9"
            cx="14.4"
            cy="12.6"
          />
        </svg>
        <input
          type="checkbox"
          checked={isActive}
          onClick={e => handleClickCheckbox(e)}
          onChange={onClick}
          name={clssName}
          className="checkbox-switch"
        />
      </div>
    </div>
  );
};

Switch.propTypes = {
  clssName: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Switch.defaultProps = {
  clssName: "",
  loader: false,
  label: null,
};

export default Switch;
