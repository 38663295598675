// Modules
import React, { useState, useEffect } from "react";

import {
  Route,
  Router,
  Switch,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
import LogRocket from "logrocket";
import { HelmetProvider } from "react-helmet-async";
import Airtable from "airtable";
import Grid from '@mui/material/Grid';


// Context
import ApiContext from "./contexts/ApiContext";
import config from "./config/environment";

// Hoc
import { history } from "./components/hoc/history";
import useWindowSize from "./components/hooks/useWindowSize";
// Local

import Home from "./routes/Home";
import Page404 from "./routes/PageNotFound";
import NavbarMobile from "./components/0.1 - NavbarMobile";
import NavbarDesktop from "./components/0 - NavbarDesktop";
import Helmet from "./components/hoc/withMetaTags";

if (config.BRANCH_STATE === "production")
  LogRocket.init("bsm9zy/plateforme-de-propo");
ReactGA.initialize("UA-197584363-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const [width, height] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isSplitScreen, setIsSplitScreen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState("");
  const [proposeur, setProposeur] = useState(null);
  const [showActionModal, setShowActionModal] = useState(true);

  /**
   * Takes the userAgents the user is on, and checks with a regex if it's a mobile or a desktop, 
   * and updates the isMobile variable.
   */
  window.mobileCheck = function () {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) // eslint-disable-line
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) // eslint-disable-line
        check = true;
    }
    )
      (navigator.userAgent || navigator.vendor || window.opera);
    setIsMobile(check);
  };

  window.tabletCheck = function () {
    let check = false;
    (function (a) {
      if (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(a))
        check = true;
    }
    )
      (navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || window.opera.toLowerCase());
    setIsTablet(check);
  };

  /**
   * Extracts the corresponding value with the given param name from the window URL.
   * Triggered on componentDidMount() through the fetchData() function.
   * @param {*} name : name of the paramater to extract from the URL
   * @param {*} url : window URL
   * @returns {String} value of the required parameter
   */
  const getParameterByName = (name, url = window.location.href) => {
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    let results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  /**
   * If there is a recid in the URL, we extract it to fetch informations linked to this real estate.
   * Triggered on componentDidMount().
   */
  useEffect(() => {
    const recid = window.location.pathname.split("/")[1]?.split("/")[0];

    const fetchData = async (recid) => {
      try {
        // Trying to see if we can associate this user session to a user in LogRocket.
        if (config.BRANCH_STATE === "production")
          if (localStorage.length > 0)
            for (const [key, value] of Object.entries(localStorage))
              // We check if we have AWS Cognito values stored
              if (key.includes("CognitoIdentityServiceProvider") === true) {
                let storedItemName = key.split(".");
                const lastIdx = storedItemName.length - 1;

                // If there's one referencing some data about a current user
                if (storedItemName[lastIdx] === "userData") {
                  const valuesObjt = { arr: JSON.parse(value)?.UserAttributes, username: JSON.parse(value)?.Username };

                  /* 
                   * If it contains at least a username, it means that there's a session cookie
                   * because user was previously logged.
                   * Now we can identify him/her into LogRocket.
                   */
                  if (valuesObjt?.arr.length >= 3 && typeof valuesObjt?.username === "string") {
                    LogRocket.identify(valuesObjt?.username, {
                      name: valuesObjt?.username || valuesObjt?.arr[2]?.Value,
                      email: valuesObjt?.arr[2]?.Value,
                    });
                  }
                }
              }

        const result = await axios.get(`${config.API_URL}id=${recid}`);
        // Récupération mail client hashé au niveau de la proposition déclarée dans l'url
        let base = new Airtable({ apiKey: config.AIRTABLE_API_KEY }).base(config.AIRTABLE_BASE);
        let params_proposition = getParameterByName("proposition", window.location.search);

        // If the URL's got a proposition ID
        if (params_proposition !== null) {
          base("Propositions").find(params_proposition, function (err, record) {
            if (err) {
              console.error("propo not found", err);
              history.push("/page-not-found");
              return;
            }

            setProposeur(record.fields['Personne qui propose'] ? record.fields['Personne qui propose'] : null);

            // console.log(result?.data?.fields);
            if (result.status === 200 && (result?.data?.statusCode === 404 || result.data === null))
              history.push("/page-not-found");
            else if (result.status === 200 && result.data?.id) {
              setId(result.data.id);
              setData(result.data?.fields);
              setLoading(false);
            }
          });
        }
        else {
          // console.log(result?.data?.fields);
          if (result.status === 200 && (result?.data?.statusCode === 404 || result.data === null))
            history.push("/page-not-found");
          else if (result.status === 200 && result.data?.id) {
            setId(result.data.id);
            setData(result.data?.fields);
            setLoading(false);
          }
        }
      }
      catch (err) {
        setLoading(false);
        console.error("Error in fetching datas", err);
        window.location.href =`https://app.bevouac.com/fiche/`;
        //history.push("https://app.bevouac.com/fiche/2");
      }
    };

    if (recid)
      fetchData(recid);
  }, []);

  /**
   * Changes display of the interface depending on the size of the window.
   * Triggered on resize of window.
   */
  useEffect(() => {
    const screenWidth = window.screen.width;
    // If the user agent isn't mobile but display should act as so.
    if (width < 620)
      setIsMobile(true);
    // If the user agent isn't tablet but display should act as so.
    else if (width <= 1026 && (height >= 900 && height <= 1366))
      setIsTablet(true);
    // If the browser is in fullscreen and the window is smaller than the size of the screen,
    // or if the height of the window is bigger than its width, means that user is in splitscreen.
    else if ((window.fullscreen && screenWidth > width) || height >= width)
      setIsSplitScreen(true);
    else {
      setIsMobile(false);
      setIsTablet(false);
      setIsSplitScreen(false);
    }
  }, [width, height]);
  let size = 12;
  {isMobile === true || isTablet === true || isSplitScreen === true ?size=12:size=12}
  return (
    
      <ApiContext.Provider
        value={{
          isMobile: isMobile,
          isTablet: isTablet,
          isSplitScreen: isSplitScreen,
        }}
      >
        <HelmetProvider>
          <Router history={history}>
          
          <Grid container xs={12} style={{backgroundColor:"white"}} >
            <main>
                <section>
                    <div class="content">
          
                          {isMobile === true || isTablet === true || isSplitScreen === true
                          ?
                          
                        <Grid container item xs={size} style={{backgroundColor:"white"}} className="contentGlobal">
                          <Switch>
                            <Route
                              exact
                              path="/page-not-found"
                              render={() => {
                                window.location.href =`https://app.bevouac.com/fiche/`;
                                return null;
                              }} 
                            />
                            
                            <Route
                            path="/:recid"
                            render={() => {
                              const recid=window.location.pathname;
                              const cgp = window.location.hash.split("#")[1];
                              cgp ? (
                                window.location.href =`https://app.bevouac.com/fiche${recid}#${cgp}`
                              ) : (
                                window.location.href =`https://app.bevouac.com/fiche${recid}`
                              )
                              return null;
                            }} 
                          />
                            <Redirect from="*" to="/page-not-found" />
                          </Switch>
                        </Grid>:
                        <Grid container item xs={size} style={{backgroundColor:"white"}} className="contentGlobal">
                        <Switch>
                          <Route
                            exact
                            path="/page-not-found"
                            render={() => (
                              <Helmet
                                route="error"
                                component={Page404}
                                data={data}
                                id={id}
                                loading={loading}
                              />
                            )}
                          />
                          <Route
                            path="/:recid"
                            render={() => {
                              const recid=window.location.pathname;
                              const cgp = window.location.hash.split("#")[1];
                              cgp ? (
                                window.location.href =`https://app.bevouac.com/fiche${recid}#${cgp}`
                              ) : (
                                window.location.href =`https://app.bevouac.com/fiche${recid}`
                              )
                              return null;
                            }} 
                          />
                          <Route
                            render={() => {
                              window.location.href =`https://app.bevouac.com/fiche/`;
                              return null;
                            }} 
                          />
                        </Switch>
                      </Grid>}
                    </div>
                </section>
              </main>
            </Grid>
          
          </Router>
        </HelmetProvider>
      </ApiContext.Provider>
   
  );
};

export default App;
