// Modules
import React, { useContext, useState } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Lazy,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "@material-ui/lab/Skeleton";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// Context
import ApiContext from "../../contexts/ApiContext";

// Assets
import CloseIcon from "../../assets/img/svg/Close_icon.jsx";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

const Carousel = (props) => {
  const {
    loader,
    data,
    rooms,
  } = props;
  const {
    photo_interieur_toilettes,
    photo_copro,
    photo_facade,
    photo_interieur_idr_lot,
    descriptif_bien_2,
    descriptif_bien_3,
    m2_carrez,
    ville,
    est_termine,
    photo_salon_apres,
    titre_fiche_frml,
    eligible_denormandie_chck,
  } = data;
  const contentIframe = (
    <iframe
      title="view-3d"
      width="100%"
      height="100%" 
      loading="lazy" 
      className="iframe-matterport lazyload"
      data-src={data["visite virtuelle"]}
      frameBorder="0"
      allowFullScreen
    />
  );

  // Slides for regular appartments
  const slidesRegular = rooms.length > 0 && (est_termine === false || est_termine === undefined)
    ? [
      contentIframe,
      ...rooms.map((room, idx) => idx >= 2 ? room.img : null),
      ...rooms.map((room, idx) => idx <= 1 ? room.img : null),
      photo_interieur_toilettes,
      photo_copro,
      photo_facade,
      
    ] : rooms.length > 0 && est_termine === true
      ? [
        photo_salon_apres,
        ...rooms.map((room) => room.imgBack),
        photo_copro,
        photo_facade,
      ] : [
        photo_salon_apres,
        contentIframe,
        photo_interieur_toilettes,
        photo_copro,
        photo_facade,
      ];
  // Slides for IDR and Lots
  const slidesIDR = rooms.length > 0 && photo_interieur_idr_lot.length > 0 && (est_termine === false || est_termine === undefined)
    ? [
      contentIframe,
      ...rooms.map((room, idx) => idx >= 2 ? room.img : null),
      ...rooms.map((room, idx) => idx <= 1 ? room.img : null),
      ...photo_interieur_idr_lot,
      photo_interieur_toilettes,
      photo_copro,
      photo_facade,
      ...rooms.map((room) => room.imgBack !== null && room.img !== null ? room.imgBack : null),
    ] : rooms.length > 0 && photo_interieur_idr_lot.length > 0 && est_termine === true
      ? [
        ...rooms.map((room) => room.imgBack),
        ...photo_interieur_idr_lot,
        photo_copro,
        photo_facade,
      ] : photo_interieur_idr_lot?.length > 0
        ? [
          contentIframe,
          ...photo_interieur_idr_lot,
          photo_interieur_toilettes,
          photo_copro,
          photo_facade,
        ] : [
          contentIframe,
          photo_interieur_toilettes,
          photo_copro,
          photo_facade,
        ];
  const slides = photo_interieur_idr_lot?.length > 0 ? slidesIDR : slidesRegular;

  const opportunityTitle = `${descriptif_bien_2} de ${m2_carrez}m², ${descriptif_bien_3} à ${ville}`;
  const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);
  const [isOpenFullscreen, setIsOpenFullscreen] = useState(false);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#308fe8',
    },
  }));

  function LinearProgressWithLabel(props) {
    return (
      <Box 
        spacing={2} 
        direction="row" 
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          width: '100%', 
          height: '100%', 
          background:'#000',
          color: 'grey.500'}}>
        <Box
          sx={{
            width: '20%', 
            height:'50px',
          }}
        >
            <Typography variant="caption" component="div" color="#FFFFFF" align="center">
                Chargement de la fiche {`${Math.round(props.value)}%`}
            </Typography>
            <BorderLinearProgress color="inherit" variant="determinate" {...props} />
        </Box>
      </Box>
    );
  }

  const [progress, setProgress] = React.useState(10);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div>
    <div className={`caroussel-container ${isMobile === true || isTablet === true || isSplitScreen === true ? "mobile" : ""}`}>
      
      {isOpenFullscreen === true
        ? (
          <div className="full-screen-swiper-container">
            <div className="background-modal" onClick={() => setIsOpenFullscreen(false)} />
            <div className={`modal-content ${isOpenFullscreen === true ? "opened" : "closed"}`}>
              <CloseIcon clssName="close-icon" onClick={() => setIsOpenFullscreen(false)} />
              
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                navigation
                Lazy={true}
                pagination={{
                  clickable: true,
                  type: 'fraction',
                    formatFractionCurrent: function (number) {
                        return number;
                    }
                }}
              >
                {loader
                  /* /!\ Numérotation ? */
                  ? [1, 2, 3, 4, 6].map((slide, index) => {
                    return (
                      <SwiperSlide key={index} >
                        <LinearProgressWithLabel value={progress} />
                      </SwiperSlide>
                    );
                  })
                  : slides.map((slide, index) => {
                    if (data["visite virtuelle"] && slide !== null) {
                      if (contentIframe === slide)
                      return null;
                      else
                        return (
                          <SwiperSlide key={index}>
                            <div
                              className="image-carousel lazyload" 
                              loading="lazy" 
                              style={{ backgroundImage: `url(${slide})` }}
                            >
                              
                            </div>{/*<img src={slide} className="image-inside" width="87%" height="auto" alt="Photo du bien" />*/}
                          </SwiperSlide>
                        );
                    }
                    else if (slide !== null) {
                      if (contentIframe === slide)
                        return null;
                      else
                        return (
                          <SwiperSlide key={index}>
                           <div
                              className="image-carousel lazyload" 
                              loading="lazy" 
                              style={{ backgroundImage: `url(${slide})` }}
                            >
                            </div>{/*<img src={slide} className="image-inside" width="87%" height="auto" alt="Photo du bien" />*/}
                          </SwiperSlide>
                        );
                    }
                    else
                      return null;
                  })}
              </Swiper>
            </div>
          </div>
        ) : null}
       
      
    
      <div class="slider-content" onClick={() => setIsOpenFullscreen(true)} style={loader === true ? { backgroundColor:"black" } : { backgroundColor:"white" }}>
    
      {loader
                  /* /!\ Numérotation ? */
                  ?  (
                    <div style={{backgroundColor:"black",width:"100%"}} className={`slider-content-item-loader`}>
                        <LinearProgressWithLabel value={progress} />
                    </div>
                    
                    )
                        : 
                        
                        slides.map((slide, index) => {
                          if (data["visite virtuelle"] && slide !== null) {
                            if (contentIframe === slide)
                              return null;
                            else
                            
                                return (
                                  <picture key={index} className={`slider-content-item-${index}`} style={{ backgroundImage: `url(${slide})` }}>
                                      <div class="background-black">
                                        <img height="185px" width="100%" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC" />
                                      </div>
                                  </picture>
                                );
                          }
                          else if (slide !== null) {
                            if (contentIframe === slide)
                              return null;
                            else
                              return (
                                <picture key={index} className={`slider-content-item-${index}`} style={{ backgroundImage: `url(${slide})` }}>
                                  <div class="background-black">
                                      <img height="185px" width="100%" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC" />
                                  </div>       
                                </picture>
                              );
                          }
                          else
                            return null;
                        })}
                <button type="button" className="slider-button" onClick={() => setIsOpenFullscreen(true)}>
                  <div>
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.9875 14.35C11.1958 14.35 12.2083 13.9417 13.025 13.125C13.8417 12.3083 14.25 11.2958 14.25 10.0875C14.25 8.87917 13.8417 7.87083 13.025 7.0625C12.2083 6.25417 11.1958 5.85 9.9875 5.85C8.77917 5.85 7.77083 6.25417 6.9625 7.0625C6.15417 7.87083 5.75 8.87917 5.75 10.0875C5.75 11.2958 6.15417 12.3083 6.9625 13.125C7.77083 13.9417 8.77917 14.35 9.9875 14.35ZM9.9875 12.85C9.19583 12.85 8.54167 12.5875 8.025 12.0625C7.50833 11.5375 7.25 10.8792 7.25 10.0875C7.25 9.29583 7.50833 8.64167 8.025 8.125C8.54167 7.60833 9.19583 7.35 9.9875 7.35C10.7792 7.35 11.4375 7.60833 11.9625 8.125C12.4875 8.64167 12.75 9.29583 12.75 10.0875C12.75 10.8792 12.4875 11.5375 11.9625 12.0625C11.4375 12.5875 10.7792 12.85 9.9875 12.85ZM1.5 18C1.1 18 0.75 17.85 0.45 17.55C0.15 17.25 0 16.9 0 16.5V3.675C0 3.29167 0.15 2.94583 0.45 2.6375C0.75 2.32917 1.1 2.175 1.5 2.175H5.175L7 0H13L14.825 2.175H18.5C18.8833 2.175 19.2292 2.32917 19.5375 2.6375C19.8458 2.94583 20 3.29167 20 3.675V16.5C20 16.9 19.8458 17.25 19.5375 17.55C19.2292 17.85 18.8833 18 18.5 18H1.5ZM18.5 16.5V3.675H14.125L12.3 1.5H7.7L5.875 3.675H1.5V16.5H18.5Z" fill="black"/>
                  </svg>
                  <div className="slider-button-text">Afficher toutes les photos</div>
                </div>
            </button>    
      </div>
    </div>
    <div className={`${eligible_denormandie_chck === true ? "ban-denormandie" : "ban-denormandie-invisible"}`}>
        Bien éligible Denormandie
    </div>
    <div id="titreh1">
        <div class="divider-container">
        {loader
            ? (
              <Skeleton
                variant="text"
                width="35%"
                height="8vh"
                className="opportunity-title-mobile"
              />
            ) : <h1 class="opportunity-title">{titre_fiche_frml}</h1>} 
        </div>
    </div>
    </div>
  );
};

Carousel.propTypes = {
  loader: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  idFiche: PropTypes.string.isRequired,
  rooms: PropTypes.array.isRequired,
  imagesNeighborhoodArr: PropTypes.array.isRequired,
};

export default Carousel;
