/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import env from "./environment.jsx"

export default {
	"": {
		title: "Bevouac | Fiche de proposition de bien",
		description: "Fiche d'aide au positionnement sur un bien immobilier.",
		url: env.CURRENT_URL,
		type: "website",
		robots: ["index, follow"],
	},
	"error": {
		title: "Error 404: Not Found",
		description: "The requested URL doesn't exists on this server.",
		url: `${env.CURRENT_URL}/page-not-found`,
		type: "website",
		robots: ["nofollow"],
	},
};
