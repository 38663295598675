import React from 'react';

import { ChartDonut, ChartThemeColor} from '@patternfly/react-charts';


  
class Graphs extends React.Component {
  //{this.props.data}
    render() {
      console.log(this.props.data);
      
      function numStr(a, b) {
        a = '' + a;
        b = b || ' ';
        var c = '',
            d = 0;
        while (a.match(/^0[0-9]/)) {
          a = a.substr(1);
        }
        for (var i = a.length-1; i >= 0; i--) {
          c = (d != 0 && d % 3 == 0) ? a[i] + b + c : a[i] + c;
          d++;
        }
        return c;
      }
      const BudgetTotal = numStr(parseInt(this.props.data[0].y + this.props.data[1].y + this.props.data[2].y + this.props.data[3].y)) + "€";
    
      return (
        
        <div style={{ height: 'auto', width: '100%', align: 'center' }}>
        <ChartDonut
        ariaDesc=""
        ariaTitle="Budget total"
        constrainToVisibleArea={true}
        data={this.props.data}
        labels={({ datum }) => `${datum.x} : ${numStr(parseInt(datum.y))} €`}
        //legendData={[
        //  { name: 'Prix du bien(FAI)' }, 
        //  { name: 'Travaux et ameublement' }, 
        //  { name: 'Frais de notaire' }, 
        //  { name: 'Frais Bevouac' }
        //]}
        legendOrientation="vertical"
        legendPosition="right"
        padding={{
          bottom: 20,
          left: 20,
          right: 150, // Adjusted to accommodate legend
          top: 20
        }}
        colorScale={["#7600D3", "#F8C670", "#3CA466", "#4B6AB9"]}
        cornerRadius={({ datum }) => datum.y * 5}
        style={{
          labels: {
            fill: "#fff"
          },
          legends: {
            fill: "#c43a31"
          },
        }}
        subTitle="Budget total"
        subTitlePosition="bottom"
        title={BudgetTotal}
        themeColor={ChartThemeColor.multiOrdered}
        width={460}
      height={400}
      />
      </div>
        
      );
    }
  }
  
  function customizeText(arg) {
    return `${arg.valueText} € (${arg.percentText})`;
  }
  export default Graphs;
  
  