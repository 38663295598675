/* eslint-disable no-undef */
// Modules
import React, {
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Loader } from "@googlemaps/js-api-loader";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

// Context
import ApiContext from "../../../contexts/ApiContext";

// Local
import config from "../../../config/environment";
import CustomCheckbox from "../../utils/Checkbox";

// Assets
import "./map.scss";
import LogoSecondary from "../../../assets/img/png/Favicon-smaller.png"

// Iris contours test before API available 
import contours_paris from "../../../config/iris_contours_paris";

import contours_lille from "../../../config/iris_contours_lille";
import contours_marseille from "../../../config/iris_contours_marseille";
import contours_havre from "../../../config/iris_contours_havre";
import contours_rennes from "../../../config/iris_contours_rennes";
import contours_angers from "../../../config/iris_contours_angers";
import contours_nancy from "../../../config/iris_contours_nancy";
import contours_lyon from "../../../config/iris_contours_lyon";

import fiches from "../../../config/social_validation";

const Map = (props) => {
  // Props
  const {
    className,
    latitude,
    longitude,
    loader,
    ville,
  } = props;

  // Iris contours according to city cases
  let contours_city;

  if (ville === "Lille")
    contours_city = contours_lille;
  else if (ville === "Nancy")
    contours_city = contours_nancy;
  else if (ville === "Marseille")
    contours_city = contours_marseille;
  else if (ville === "Angers")
    contours_city = contours_angers;
  else if (ville === "Rennes")
    contours_city = contours_rennes;
  else if (ville === "Le Havre")
    contours_city = contours_havre;
  else if (ville === "Lyon")
    contours_city = contours_lyon;
  else if (ville === "Paris")
    contours_city = contours_paris;
  else
    contours_city = contours_rennes;

  // Context
  const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);

  // Global variables
  const [isChecked, setIsChecked] = useState({
    publicTransport: {
      checked: true,
      googleMapsValue: "transit",
    },
    schools: {
      checked: true,
      googleMapsValue: "poi.school",
    },
    shops: {
      checked: true,
      googleMapsValue: "poi.business",
    },
  });
  const [isInitialized, setIsInitialized] = useState(false);

  // Google Map variables
  const googleMapRef = useRef(null);
  const googleMapObjectRef = useRef(null);
  const mapControls = useRef(null);
  const [styleOptions, setStyleOptions] = useState([
    {
      featureType: "all",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    /* Transports en commun - CHECKBOX OPTION */
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    /* Écoles - CHECKBOX OPTION */
    {
      featureType: "poi.school",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    /* Magasins - CHECKBOX OPTION */
    {
      featureType: "poi.business",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    /* Noms des rues */
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [{ visibility: "on" }],
    },
    /* Quartiers */
    {
      featureType: "administrative.neighborhood",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    /* Villes, villages */
    {
      featureType: "administrative.locality",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    /* Paysages */
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
  ]);

  /**
   * Listener on the style object which defines what places
   * to display on the map. Triggered whenever a checkbox turns
   * on/off some styling.
   */
  useEffect(() => {
    if (googleMapObjectRef.current === null)
      return;

    googleMapObjectRef.current.setOptions({ styles: styleOptions });
  }, [styleOptions]);

  /**
   * Listener on "isChecked", which turns on/off the
   * visibility of some icons connected to the checkboxes.
   */
  useEffect(() => {
    if (googleMapObjectRef.current === null)
      return;

    let newStyleOption = [...styleOptions];

    newStyleOption.map((style) => {
      return Object.keys(isChecked).map((option) => {
        if (isChecked[option].googleMapsValue === style.featureType) {
          return style.stylers = isChecked[option].checked === true
            ? [{ visibility: "on" }]
            : [{ visibility: "off" }];
        }
        return style;
      });
    });

    setStyleOptions(newStyleOption);
  }, [isChecked]); // eslint-disable-line

  /**
   * Initialize Goggle Maps.
   * Triggered once we receive the longitude & latitude props,
   * and the DOM is mounted.
   */
  useEffect(() => {
    const loader = new Loader({
      apiKey: config.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"]
    });
    const mapOptions = {
      center: {
        lat: latitude ? latitude : 0,
        lng: longitude ? longitude : 0,
      },
      zoom: 11,
      mapTypeControl: false,
    };

    // definition of the shapes to display (test only before API available)
    const iris_shape = contours_city.features.map(f => { return (f.geometry.coordinates[0].map(x => { return ({ lng: Number(Number.parseFloat(x[0]).toFixed(10)), lat: Number(Number.parseFloat(x[1]).toFixed(10)) }) })) });
    const iris_name = contours_city.features.map(f => { return (f.properties.nom_iris) });
    let iris_center = contours_city.features.map(f => { return (f.properties.geo_point_2d) });
    let iris_strat = contours_city.features.map(f => { return (f.properties.Stratégie) });
    let iris_price = contours_city.features.map(f => { return (f.properties['Prix au mètre carré']) });
    let iris_demo_evol = contours_city.features.map(f => { return (f.properties['Evolution démographique']) });

    iris_center = iris_center.map(x => { return ({ lat: x[0], lng: x[1] }) });

    const initMap = async (mapOptions) => {
      loader
        .load()
        .then(() => {
          if (googleMapRef.current !== null && mapControls.current !== null) {
            // Init map
            googleMapObjectRef.current = new google.maps.Map(
              googleMapRef.current,
              mapOptions,
            );

            // Add marker for the current estate proposal
            new google.maps.Marker({
              map: googleMapObjectRef.current,
              position: mapOptions.center,
              // Uncomment if you want to add a label to the marker
              // title: "C'est ici",
              // label: {
              //   text:  "C'est ici",
              //   fontFamily: "var(--OpenSansSemiBold)",
              //   color: "var(--primary)",
              //   fontSize: "1.5vh",
              // },
            });

            let addListenersOnPolygon = function (polygon, display) {
              const infowindow = new google.maps.InfoWindow();
              google.maps.event.addListener(polygon, 'mouseout', function (evt) {
                infowindow.close();
                infowindow.opened = false;
              });
              google.maps.event.addListener(polygon, 'click', function (event) {
                //alert(polygon.indexId);
                if (typeof polygon.strategie !== 'undefined') {
                  infowindow.setContent('<div ><p style="font-weight:bold" > Quartier ℹ️ ' + polygon.indexId + '</p> <p style="font-weight:bold" > 💶     ' + polygon.prix + ' €/m²  </p><p style="font-weight:bold" >♟️ ' + polygon.strategie + '</p><p style="font-weight:bold" >Démographie 📈 ' + polygon.evol_demo.substring(0, 4) + ' % </p></div>');
                }
                if (display) { infowindow.setPosition(polygon.center); } // or evt.latLng
                infowindow.open(googleMapObjectRef.current);
              });
            };

            let addListenerOnBevouac = function (mymark, fiche_online, ptype) {
              const infowindow = new google.maps.InfoWindow();
              google.maps.event.addListener(mymark, 'click', function () {
                // in case we want to add a button to redirect to the fiche of the property : let contentString = "<form action="+fiche_online+"><input type='submit' value='Consulter' /></form>"
                let contentString = "<div >" + ptype + "</div>";
                // Replace our Info Window's content and position 
                infowindow.setContent(contentString);
                infowindow.setPosition(mymark.position);
                infowindow.open(googleMapObjectRef.current)
              });
            };


            // Define the LatLng coordinates for the polygon's path.
            const triangleCoords = iris_shape;
            // Construct the polygon.
            for (const [i, v] of triangleCoords.entries()) {
              let color;
              let opaciti = 0.11;

              if (iris_strat[i] === "Développement") {
                color = "#F7B67B";
              }
              else if (iris_strat[i] === "Placement") {
                color = "#1A7D6A";
              }
              else if (iris_strat[i] === "Équilibré") {
                color = "#2577A8";
              }
              else {
                color = "#F7B67B";
                opaciti = 0.0;
              }
              const iris = new google.maps.Polygon({
                paths: v,
                strokeColor: "#000000",
                strokeOpacity: 0.2,
                strokeWeight: 1.5,
                fillColor: color,
                fillOpacity: opaciti,
                indexId: iris_name[i],
                center: iris_center[i],
                strategie: iris_strat[i],
                prix: iris_price[i],
                evol_demo: iris_demo_evol[i].toString(),
              });

              iris.setMap(googleMapObjectRef.current);

              addListenersOnPolygon(iris, iris_strat[i]);
            }

            // Bevouac markers

            const markerimg = LogoSecondary;
            for (const f of fiches) {
              const myLatLng = { lat: parseFloat(f.lat), lng: parseFloat(f.long) };

              let mymark = new google.maps.Marker({
                position: myLatLng,
                map: googleMapObjectRef.current,
                icon: markerimg,
              });

              // Listen for click event  
              addListenerOnBevouac(mymark, f.fiche_online, f.type);
            }

            // Add controls to the map, allowing users to hide/show features.
            googleMapObjectRef.current.controls[google.maps.ControlPosition.TOP_LEFT].push(mapControls.current);

            // Initialize options through checkboxes
            googleMapObjectRef.current.setOptions({ styles: styleOptions });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (googleMapRef.current !== null)
      initMap(mapOptions);
  }, [googleMapRef, mapControls, latitude, longitude]); // eslint-disable-line

  useEffect(() => {
    if (googleMapObjectRef.current === null)
      return;

    if (googleMapObjectRef.current?.controls[google.maps.ControlPosition.TOP_LEFT]?.length >= 1)
      setIsInitialized(true);
  }, [googleMapObjectRef.current?.controls])

  /**
   * Toggle check/uncheck the targeted checkbox.
   * Triggered on click of the checkbox.
   * @param {Event} evt
   */
  const toggleShowOptions = (evt) => {
    setIsChecked({
      ...isChecked,
      [evt.target.name]: {
        ...isChecked[evt.target.name],
        checked: evt.target.checked,
      },
    });
  };

  return (
    <div className={`map-wrapper ${isMobile === true || isTablet === true ? "mobile" : ""} ${isSplitScreen === true ? "split-screen" : ""}`}>
      <ul
        ref={mapControls}
        className="map-controls"
        style={loader === true || isInitialized === false ? { display: "none" } : { display: "flex" }}
      >
        <li>
          <CustomCheckbox
            label="Transports en commun"
            checked={isChecked.publicTransport.checked}
            onChange={toggleShowOptions}
            name="publicTransport"
            color="transparent"
            loader={loader}
          />
        </li>
        <li>
          <CustomCheckbox
            label="Écoles"
            checked={isChecked.schools.checked}
            onChange={toggleShowOptions}
            name="schools"
            color="transparent"
            loader={loader}
          />
        </li>
        <li>
          <CustomCheckbox
            label="Magasins"
            checked={isChecked.shops.checked}
            onChange={toggleShowOptions}
            name="shops"
            color="transparent"
            loader={loader}
          />
        </li>
      </ul>
      {loader === true
        ? (
          <Skeleton
            variant="rect"
            className="google-map-container"
          />
        ) : null}
      <div
        className={`${className} google-map-container`}
        style={loader === true ? { display: "none" } : { display: "flex" }}
      >
        <div ref={googleMapRef} className="google-map" />
      </div>
    </div>
  );
};

Map.propTypes = {
  className: PropTypes.string,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  loader: PropTypes.bool.isRequired,
};

Map.defaultProps = {
  className: "",
  longitude: 0,
  latitude: 0,
}

export default Map;


