// Modules
import React, { useContext } from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

// Context
import ApiContext from "../../../contexts/ApiContext";


const Divider = (props) => {
	const {
		loader,
		text,
		img,
		clssName,
		centered,
	} = props;
	const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);

	return (
		<div
			className={`divider-container ${clssName} ${centered === true ? "center" : ""} ${isMobile === true || isTablet === true || isSplitScreen === true ? "mobile" : ""}`}
		>
			
			<div className="text-wrapper">
				{loader === true && text === null
					? (
						<Skeleton
							variant="text"
							animation="wave"
							className="name"
							width="5vh"
						/>
					) : <h2 className={`name ${img !== null ? "with-image" : ""}`}>{text}</h2>}
				{img
					? img
					: null}
			</div>
			
		</div>
	);
};

Divider.propTypes = {
	loader: PropTypes.bool.isRequired,
	text: PropTypes.string,
	img: PropTypes.element,
	clssName: PropTypes.string,
	centered: PropTypes.bool,
};

Divider.defaultProps = {
	text: null,
	img: null,
	clssName: "",
	centered: false,
};

export default Divider;