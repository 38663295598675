import ApiContext from "../../contexts/ApiContext";

// Modules
import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Chart, Legend
} from 'devextreme-react/chart';
import { Grid } from "@material-ui/core";
import Divider from "../utils/Divider";

import Paper from "@material-ui/core/Paper";
import Typography from "@mui/material/Typography";

// Local
import Map from "../utils/Map";
import { withStyles } from "@material-ui/core/styles";

const MarketAnalysisDesktop = (props) => {
  const {  data, loader, agglomeration, ville, arg_q1, arg_q2, arg_q3, latitude, longitude } = props;

  const location = useLocation();

  function numStr(a, b) {
    a = '' + a;
    b = b || ' ';
    var c = '',
        d = 0;
    while (a.match(/^0[0-9]/)) {
      a = a.substr(1);
    }
    for (var i = a.length-1; i >= 0; i--) {
      c = (d != 0 && d % 3 == 0) ? a[i] + b + c : a[i] + c;
      d++;
    }
    return c;
  }

  const [an, setAn] = useState({});
  const [an2, setAn2] = useState({});
  const [an4, setAn4] = useState({});
  const [an6, setAn6] = useState({});
  const [an8, setAn8] = useState({});
  const [an9, setAn9] = useState({});

  useEffect(() => {
    
    if (Object.keys(data).length !== 0) {
      if (Object.keys(data.se_loue_facilement).length !== 0) {
        if (
          Object.keys(data.se_loue_facilement.part_locataires_proprietaires)
            .length !== 0
        ) {
          //console.log("analyssssse", data);
          const mdata = [
            {
              level:
                "Quartier",
                legend:
                data.se_loue_facilement.part_locataires_proprietaires.quartier +
                "%",
              val: parseInt(data.se_loue_facilement.part_locataires_proprietaires
                .quartier),
            },
            {
              level: "Ville",
              legend:
                data.se_loue_facilement.part_locataires_proprietaires.ville +
                "%",
              val: parseInt(data.se_loue_facilement.part_locataires_proprietaires.ville),
            },
            {
              level: "France" ,
              legend:data.se_loue_facilement.part_locataires_proprietaires.france + " %",
              val: parseInt(data.se_loue_facilement.part_locataires_proprietaires.france),
            }
          ];
          setAn(mdata);
          const mdata2 = [
            {
              level:
                "Ville",
                legend:
                data.se_loue_facilement.part_etudiants.ville +
                " ",
              val: parseInt(data.se_loue_facilement.part_etudiants.ville),
            },
            {
              level:
                "France",
                legend:
                data.se_loue_facilement.part_etudiants.france +
                " ",
              val: parseInt(data.se_loue_facilement.part_etudiants.france),
            }
          ];
          setAn2(mdata2);
          const mdata4 = [
            {
              level:
                "Quartier",
              legend:data.prend_de_la_valeur.revenu_annuel_median.quartier,
              val: numStr(parseInt(data.prend_de_la_valeur.revenu_annuel_median.quartier)),
            },
            {
              level:
                "Ville",
              legend:data.prend_de_la_valeur.revenu_annuel_median.ville,
              val: numStr(parseInt(data.prend_de_la_valeur.revenu_annuel_median.ville)),
            },
            {
              level:
                "France",
              legend: data.prend_de_la_valeur.revenu_annuel_median.france,
              val: numStr(parseInt(data.prend_de_la_valeur.revenu_annuel_median.france)),
            }
          ];
          setAn4(mdata4);

          const mdata6 = [
            {
              level:
                "Ville" ,
        
              val: numStr(parseInt(data.se_revend_facilement.nombre_acheteurs_100_biens.ville)),
            },{
              level:
                "France" ,
        
              val: numStr(parseInt( data.se_revend_facilement.nombre_acheteurs_100_biens.france)),
            },
          ];
          setAn6(mdata6);
          
          const mdata8 = [
            {
              level:
                "Ville" ,
        
              val: data.se_revend_facilement.evolution_demo_10_ans.ville.y[9],
            },
            {
              level:
                "France" ,
        
              val: data.se_revend_facilement.evolution_demo_10_ans.france.y[9],
            },
            
          ];
          setAn8(mdata8);
          
          const mdata9 = [
            {
              level:
                "Ville" ,
        
              val: parseInt(100 - (data.prend_de_la_valeur.evolution_prix_immo.ville.y[0]*100/data.prend_de_la_valeur.evolution_prix_immo.ville.y[5])),
            },
            {
              level:
                "France" ,
        
              val: parseInt(100 - (data.prend_de_la_valeur.evolution_prix_immo.france.y[0]*100/data.prend_de_la_valeur.evolution_prix_immo.france.y[5])),
            },
            
          ];
          setAn9(mdata9);

        }
      }
    }
  }, [data]); // eslint-disable-line

  // Graph purpose
  const chartRootStyles = {
    chart: {
      paddingRight: "20px",
    },
  };
  const legendStyles = {
    root: {
      display: "flex",
      margin: "auto",
      flexDirection: "row",
    },
  };
  const legendLabelStyles = (theme) => ({
    label: {
      paddingTop: theme.spacing(1),
    },
  });
  const legendItemStyles = {
    item: {
      flexDirection: "column",
    },
  };

  const ChartRootBase = ({ classes, ...restProps }) => (
    <Chart.Root {...restProps} className={classes.chart} />
  );
  const LegendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
  );
  const LegendLabelBase = ({ classes, ...restProps }) => (
    <Legend.Label {...restProps} className={classes.label} />
  );
  const LegendItemBase = ({ classes, ...restProps }) => (
    <Legend.Item {...restProps} className={classes.item} />
  );
  const ChartRoot = withStyles(chartRootStyles, { name: "ChartRoot" })(
    ChartRootBase
  );
  const LegendRoot = withStyles(legendStyles, { name: "LegendRoot" })(
    LegendRootBase
  );
  const LegendLabel = withStyles(legendLabelStyles, { name: "LegendLabel" })(
    LegendLabelBase
  );
  const LegendItem = withStyles(legendItemStyles, { name: "LegendItem" })(
    LegendItemBase
  );
  
  function formatLabel(arg) {
    return `<span class="label">{val}</span> %`;
  };

  const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);

  const cgp = location.hash.split("#")[1];

  return (
    <React.Fragment>
      <Grid container>
        <Divider text="Analyse de marché "  />
        </Grid>
        {arg_q1 === null && arg_q2 === null && arg_q3 === null
          ? null
          : (<Grid container spacing={0}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
            <h3 className="marge-top">Analyse du quartier</h3>
               <p className="arg" >{arg_q1}</p>
               <p className="arg" >{arg_q2}</p>
               <p className="arg" >{arg_q3}</p>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
        )}
        <Grid container style={cgp === "cgp" ? { display: "none" } : { display: "flex" }}>
            <Grid item xs={3}></Grid> 
      
            <Grid item xs={6} className="map-column" >
            <h3>Les autres projets réalisés par nos investisseurs</h3>
              <iframe src={`https://view-awesome-table.com/-NYXMjxKUi3xtJ005T_D/view?hideFilters=true&filterB=${agglomeration}&ville=`+ville} width="100%" height="600px" frameBorder="0" scrolling="no" />
            </Grid>
            <Grid item xs={3}></Grid>
         </Grid>
         
      <Grid  container spacing={0} className="container-pad2">
        <Grid  item container xs={3} ></Grid>
        <Grid  item container xs={6} spacing={0} className="container-pad-left">
          <h3>Indicateurs statistiques</h3>
          <Grid item xs={12} >
            <Paper
              variant="outlined"
            >
              <div class="panel1a-content panel1a-border">
                 <Typography>
                    <div className="madivtitle svglouer lazyloaded" loading="lazy">
                      {" "}
                      Se loue facilement ? 
                    </div>{" "}
                  </Typography>
                
                    <h3 class="MuiTypography-root jss13 MuiTypography-h6 left marge-top">Part de locataires VS. propriétaires</h3>
                    {Object.keys(an).length !== 0 && (
                      an.map((item,i) => <p key={i}><strong>{item.level}</strong> : {item.val} %</p>))}
                    
                    <h3 class="MuiTypography-root jss13 MuiTypography-h6 left marge-top">Part d'étudiants et de jeunes actifs</h3>
                    {Object.keys(an2).length !== 0 && (
                      an2.map((item,i) => <p key={i}><strong>{item.level}</strong> : {item.val} %</p>))}
              </div>
              <div class="panel1a-content">
                    <Typography>
                        
                        <div className="madivtitle svgvaleur lazyloaded" loading="lazy">
                          {" "}
                          Prend de la valeur dans le temps
                        </div>{" "}
                      </Typography>
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left marge-top">Revenu annuel médian</h3>
                      {Object.keys(an4).length !== 0 && (
                      an4.map((item,i) => <p key={i}><strong>{item.level}</strong> : {item.val} &euro;</p>))}
                      
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left marge-top">Evolution des prix de l'immobilier sur 5 ans</h3>

                      {Object.keys(an9).length !== 0 && (
                      an9.map((item,i) => <p key={i}><strong>{item.level}</strong> : {item.val} %</p>))}
              </div>
            </Paper>
          </Grid>

      

      </Grid>
      <Grid  item container xs={3} ></Grid>
    </Grid>
    
    </React.Fragment>
  );

  
};

MarketAnalysisDesktop.propTypes = {
  loader: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,

  
};
function customizeText(arg) {
  return arg.target.select();
}

export default MarketAnalysisDesktop;
