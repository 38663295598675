// Modules
import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { StyledEngineProvider } from "@mui/material/styles";
import Divider from "../../utils/Divider";
  
// Context
import ApiContext from "../../../contexts/ApiContext";
// Local
import Switch from "../../utils/Switch";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



const Results = (props) => {
  const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);
  const [isHovering, setIsHovering] = useState(false);
  const location = useLocation();
  const [urlWithParams, setUrlWithParams] = useState(null);
  const [activeLink, setActiveLink] = useState("dataanalysis");
  const [isFound, setIsFound] = useState(true);



  const {
    data,
    loader,
    valuesAPI,
    valuesSimulation,
    hasDataChanged,
    fraisCourtageSwitch,
    stateSwitch,
		setStateSwitch,
		setValuesAPI,
    def_prix_vente_fai,
    def_frais_garantie_bancaire,
    def_valorisation_annuelle_bien,
    def_honoraires_bevouac,
    def_loyercc,
    def_loyercc_courteduree,
    def_frais_mise_location_gestion_locative,
    def_vacance_locative,
    def_vacance_locative_courteduree,
    def_provisions_depenses_imprevues,
    def_provisions_depenses_imprevues_courte_duree,
    def_frais_mise_location
  } = props;
  
  function numStr(a, b) {
    a = '' + a;
    b = b || ' ';
    var c = '',
        d = 0;
    while (a.match(/^0[0-9]/)) {
      a = a.substr(1);
    }
    for (var i = a.length-1; i >= 0; i--) {
      c = (d != 0 && d % 3 == 0) ? a[i] + b + c : a[i] + c;
      d++;
    }
    return c;
  }



  /* Fixed data from API, not impacted by the changes in the simulation. */
  const {
    amenagement_interieur_ttc,
    honoraire_bevouac_ttc,
    mise_vente_fai,
    negociation,
    achat_fai,
    frais_notaire,
    montant_travaux_ttc,
    charges_reccurentes_mois,
    rendement_brut,
    rendement_brut_france,
    taux_livret_a,
    loyer_cc,
    charges_copro_mois,
    taxe_fonciere_mois,
    pno_mois,
    revalorisation,
    total_revenus_annuels,
    charges_reccurentes_annuelles,
    rendement_agglo,
    arg_b1,
    arg_b2,
    arg_b3,
    frais_ingenierie,
    vacance_locative,
    courte_duree_check,
    gestion_locative_mois,
  } = data;
  /* Volatile data, is impacted by the simulation. */
  const {
    frais_courtage,
    charges_aleatoires_mois,
    frais_mise_en_location_mois,
    provisions_pour_imprevus_mois,
    vacance_locative_mois,
    expert_comptable_mois,
    caution_frais_dossier,
    bilan_mensuel,
    gain_net_revente,
    taux_emprunt_recommande,
    mensualite,
    montant_emprunte,
    prix_revente,
    rente_nette,
    total_acquisition,
    total_depenses_mois, // eslint-disable-line
    graphs,
  } = valuesAPI;
  const {
    // frais_courtage,
    mise_depart,
    duree_emprunt,
    // taux_emprunt,
    // taux_assurance,
    duree_revente,
  } = valuesSimulation;
 
  const [isOpen, setIsOpen] = useState(false);
  const {
		fraisCourtage,
    fraisChargesAleatoires,
		/* V2 or V3 */
		// effortEpargne,
		// equilibre
	} = stateSwitch;

  const acquisition_total =
    fraisCourtageSwitch === true
      ? achat_fai + frais_notaire + frais_courtage + caution_frais_dossier + montant_travaux_ttc + amenagement_interieur_ttc + honoraire_bevouac_ttc
      : achat_fai + frais_notaire + caution_frais_dossier + montant_travaux_ttc + amenagement_interieur_ttc + honoraire_bevouac_ttc;
  
  const tresorerie_mensuelle = loyer_cc - mensualite - charges_reccurentes_mois - charges_aleatoires_mois;
  
      /* Variables state V2 */
  const [tabView, setTabView] = useState("table"); // eslint-disable-line
  const cgp = location.hash.split("#")[1];

  function negatifStr(d,e) {
    if (d <0)
      {
        e = d.toString().replace("-", "- "); 
        return e;
      }
    else 
      {
        e = d.toString().replace(d, "+ " + d);
        return e;
      }
  }

  /* Sub-tables of each accordion header */
  const negociation_details = [
    { poste: "Prix de vente FAI", val: mise_vente_fai, signe:"", tooltip: def_prix_vente_fai, changed: false },
    { poste: "Négociation envisagée", val: negociation, signe:"- ", tooltip:"default", changed: false },
    { poste: "Prix d’achat FAI envisagé", val: achat_fai, signe:"= ", tooltip:"default", changed: false },
  ];

  const acquisition_details1 = [
    { poste: "Prix d’achat FAI envisagé", val: achat_fai, signe:"", tooltip:"default", changed: false },
    { poste: "Frais de notaire", val: frais_notaire, signe:"", tooltip:"default", changed: false },
  ];

  const acquisition_details2 = [
    {
      poste: "Frais garantie bancaire",
      val: caution_frais_dossier, 
      signe:"",
      tooltip: def_frais_garantie_bancaire,
      changed: hasDataChanged.caution_frais_dossier,
    },
    { poste: "Travaux", val: montant_travaux_ttc, signe:"", tooltip:"default", changed: false },
    { poste: "Mobilier et équipement", val: amenagement_interieur_ttc, signe:"", tooltip:"default", changed: false },
    { poste: "Honoraires Bevouac", val: honoraire_bevouac_ttc, signe:"", tooltip: def_honoraires_bevouac, changed: false },
    { poste: "Total acquisition", val: total_acquisition, signe:"= ", tooltip: "default", changed: hasDataChanged.total_acquisition },
  ];

  const financement_details = [
    { poste: "Mise de départ", val: mise_depart, signe:"", tooltip:"default", changed: hasDataChanged.mise_depart },
    { poste: "Montant emprunté", val: montant_emprunte, signe:"", tooltip:"default", changed: hasDataChanged.montant_emprunte },
    { poste: "Total acquisition", val: total_acquisition, signe:"= ", tooltip: "default", changed: hasDataChanged.total_acquisition },
  ];
    const def_loyercc_choix =
    courte_duree_check === true
      ? def_loyercc_courteduree 
      : def_loyercc;

  const bilan_comptable1 = [
    { poste: "Loyer CC mensuel", val: loyer_cc, signe:"", tooltip:def_loyercc_choix, changed: false },
    { poste: "Mensualités d’emprunt", val: mensualite, signe:"- ", tooltip:"default", changed: hasDataChanged.mensualite },
    { poste: "Charges récurrentes (i)", val: charges_reccurentes_mois, signe:"- ", tooltip: "default", changed: false },
    { poste: "Charges de gestion locative (ii)", val: charges_aleatoires_mois, signe:"- ", tooltip: "default", changed: hasDataChanged.charges_aleatoires_mois },
    { poste: "Flux de trésorerie mensuel", val: tresorerie_mensuelle, signe:"= ", tooltip: "default", changed: false },
  ];

  const bilan_comptable2 = [
    { poste: "Charges de copropriété", val: charges_copro_mois, signe:"", tooltip:"default", changed: false },
    { poste: "Taxe foncière", val: taxe_fonciere_mois, signe:"", tooltip:"default", changed: false },
    { poste: "Assurance propriétaire (PNO)", val: pno_mois, signe:"", tooltip: "default", changed: false },
    { poste: "Charges récurrentes (i)", val: charges_reccurentes_mois, signe:"= ", tooltip: "default", changed: false },
  ];

  const acquisition_details = [
    { poste: "Prix de vente FAI", val: mise_vente_fai, tooltip: def_prix_vente_fai, changed: false },
    { poste: "- Négociation envisagée", val: negociation, tooltip:"default", changed: false },
    { poste: "= Prix d'achat envisagé", val: achat_fai, tooltip:"default", changed: false },
    { poste: "Frais de notaire", val: frais_notaire, tooltip:"default", changed: false },
    {
      poste: "Frais garantie bancaire",
      val: caution_frais_dossier,
      tooltip: def_frais_garantie_bancaire,
      changed: hasDataChanged.caution_frais_dossier,
    },
    { poste: "Travaux", val: montant_travaux_ttc, tooltip:"default", changed: false },
    { poste: "Mobilier et équipement", val: amenagement_interieur_ttc, tooltip:"default", changed: false },
    { poste: "Honoraires Bevouac", val: honoraire_bevouac_ttc, tooltip: "default", changed: false },
  ];
  const construction_details = [
    { poste: "Travaux", val: montant_travaux_ttc, changed: false },
    {
      poste: "Mobilier et équipement",
      val: amenagement_interieur_ttc,
      changed: false,
    },
  ];
  const recurring_charges_details = [
    {
      poste: "Charges de copropriété",
      val: charges_copro_mois,
      changed: false,
    },
    { poste: "Taxe foncière", val: taxe_fonciere_mois, changed: false },
    { poste: "Assurance propriétaire (PNO)", val: pno_mois, changed: false },
    {
      poste: "Frais de gestion locative",
      val: gestion_locative_mois,
      changed: false,
    },
  ];

  const def_vacance_locative_choix =
    courte_duree_check === true
      ? def_vacance_locative_courteduree 
      : def_vacance_locative;
  
  const vacance_locative_choix =
      courte_duree_check === true
        ? "Charges courantes" 
        : "Vacance locative estimée (" + vacance_locative + " mois)";

  const frais_choix =
      courte_duree_check === true
        ? "Frais de dossier conciergerie" 
        : "Frais de mise en location";

  const def_frais_choix =
          courte_duree_check === true
            ? def_frais_mise_location 
            : def_frais_mise_location;
  
  const provisions_depenses_choix =
        courte_duree_check === true
          ? "Charges consommables" 
          : "Provisions pour dépenses imprévues";
  const def_provisions_depenses_choix =
          courte_duree_check === true
            ? def_provisions_depenses_imprevues_courte_duree 
            : def_provisions_depenses_imprevues;


  const gestion_locative_mois_toggle =
  fraisChargesAleatoires === true
              ? gestion_locative_mois 
              : 0;
  const hazards_expenses_details = [
    { 
      poste: "Frais de gestion locative", 
      val: gestion_locative_mois_toggle, 
      tooltip: def_frais_mise_location_gestion_locative, 
      changed: hasDataChanged.gestion_locative_mois,
    },
    {
      poste: frais_choix,
      val: frais_mise_en_location_mois ?? 0,
      signe:"", 
      tooltip:def_frais_choix,
      changed: hasDataChanged.frais_mise_en_location_mois,
    },
  ];
  const resale_details = [
    
    {
      poste: "Prix de revente",
      val: prix_revente ?? 0,
      signe:"", 
      tooltip:"default",
      changed: hasDataChanged.prix_revente,
    },
    {
      poste: "Montant travaux TTC",
      val: montant_travaux_ttc ?? 0,
      signe:"", 
      tooltip:"default",
      changed: hasDataChanged.montant_travaux_ttc,
    },
    { 
      poste: "Valorisation annuelle du bien", 
      val: revalorisation * 100, 
      signe:"", 
      tooltip:def_valorisation_annuelle_bien,
      changed: false 
    },
    {
      poste: "Épargne mensuelle placée ou cashflow",
      val: bilan_mensuel ?? 0,
      signe:"", 
      tooltip:"default",
      changed: hasDataChanged.bilan_mensuel,
    },
    {
      poste: "Mise de départ",
      val: mise_depart,
      signe:"- ", 
      tooltip:"default",
      changed: hasDataChanged.mise_depart,
    },
    {
      poste: "Gain net à la revente",
      val: gain_net_revente,
      signe:"= ", 
      tooltip:"default",
      changed: hasDataChanged.gain_net_revente,
    },
  ];
  const location_details = [
    { 
      poste: "Revenus annuels", 
      val: total_revenus_annuels, 
      signe:"", 
      tooltip:"default",
      changed: false 
    },
    {
      poste: "Charges récurrentes annuelles",
      val: charges_reccurentes_annuelles,
      signe:"- ", 
      tooltip:"default",
      changed: false,
    },
    {
      poste: "Charges aléatoires annuelles",
      val: charges_aleatoires_mois * 12,
      signe:"- ", 
      tooltip:"default",
      changed: false,
    },
    {
      poste: "Rente nette après remboursement de l'emprunt",
      val: rente_nette,
      signe:"= ", 
      tooltip:"default",
      changed: hasDataChanged.rente_nette,
    },
    
  ];
  const return_details = [
    {
      poste: "Rendement brut moyen agglo",
      val: rendement_agglo * 100,
      signe:"", 
      tooltip:"default",
      changed: false,
    },
    {
      poste: "Rendement du livret A",
      val: taux_livret_a * 100,
      signe:"", 
      tooltip:"default",
      changed: false,
    }
  ];

  /**
   * Creates an object.
   * @param {String} keyProp : destined to be a key in a map.
   * @param {String} rubrique : header name for the accordion.
   * @param {Number} total : total of all the values displayed in the sub-table.
   * @param {Object} detail : object that represents the sub-table.
   * @returns an object.
   */
  const createData = (keyProp, rubrique, total, detail, hasChanged) => {
    return {
      keyProp,
      rubrique,
      total,
      detail,
      hasChanged,
    };
  };

  /* "Le projet" */
  const rowsProject = [
    createData(
      "acquisition",
      "Acquisition",
      acquisition_total,
      acquisition_details,
      negociation_details,
      acquisition_details1,
      acquisition_details2,
      financement_details,
      bilan_comptable1,
      bilan_comptable2,
      montant_emprunte,
      mise_depart,
      hasDataChanged.frais_courtage
    ),
    createData(
      "amenagement-ttc",
      "Aménagement",
      amenagement_interieur_ttc + montant_travaux_ttc,
      construction_details,
      false
    ),
    createData(
      "honoraires-bevouac-ttc",
      "Honoraires Bevouac",
      honoraire_bevouac_ttc,
      [],
      false
    ),
    createData(
      "budget-total",
      "Budget total du projet",
      total_acquisition ?? 0,
      [],
      hasDataChanged.total_acquisition
    ),
  ];
  /* "Hypothèse de financement" */
  const rowsFinance = [
    createData(
      "montant-empruntee",
      "Montant emprunté",
      montant_emprunte ?? 0,
      [],
      hasDataChanged.montant_emprunte
    ),
    createData(
      "mensualite-emprunt",
      "Mensualité d'emprunt",
      mensualite ?? 0,
      [],
      hasDataChanged.mensualite
    ),
  ];
  /* "Dépenses récurrentes" */
  const rowsRecurringExpenses = [
    createData(
      "mensualite-emprunt-2",
      "Mensualité emprunt",
      mensualite ?? 0,
      [],
      hasDataChanged.mensualite
    ),
    createData(
      "charges-reccurentes",
      "Charges récurrentes",
      charges_reccurentes_mois,
      recurring_charges_details,
      false
    ),
    createData(
      "charges-aleatoires",
      "Charges aléatoires",
      charges_aleatoires_mois,
      hazards_expenses_details,
      hasDataChanged.charges_aleatoires_mois
    ),
  ];
  /* Épargne mensuelle placée ou cashflow */
  const rowMonthlyCashflow = [
    createData(
      "bilan-mensuel",
      "Épargne mensuelle placée ou cashflow",
      bilan_mensuel ?? 0,
      [],
      hasDataChanged.bilan_mensuel
    ),
  ];
  /* "Revenus" */
  const rowsIncomes = [
    createData("loyer-mensuel", "Loyer mensuel", loyer_cc, [], false),
  ];
  /* "Projection dans 20 ans" */
  const rowsProjection = [
    createData(
      "revente",
      "Gain net à la revente",
      gain_net_revente ?? 0,
      resale_details,
      hasDataChanged.gain_net_revente
    ),
    createData(
      "location",
      "Rente nette après remboursement de l’emprunt",
      rente_nette ?? 0,
      location_details,
      hasDataChanged.rente_nette
    ),
  ];
  /* "Rendement" */
  const rowsReturn = [
    createData(
      "rendement-brut-investissement",
      "Rendement brut de l'investissement",
      rendement_brut,
      return_details,
      false
    ),
    createData(
      "rendement-livret-a",
      "Rendement du Livret A",
      taux_livret_a * 100,
      [],
      false
    ),
  ];

  /**
   * FOR V2 : Switch between tabs on click.
   * @param {Event} event
   * @param {String} newTab : name of the clicked tab
   */
  // const handleTabView = (event, newTab) => {
  //   setTabView(newTab);
  // };


  /**
	 * Handles the change of state for switch
	 * @param {String} name : name of the key to change from the "stateSwitch" object
	 */
	const handleChangeSwitch = (event, name) => {
		// If the switch is off for "frais_courtage", we change its value to 0 for an impact in the results.
		if (name === "fraisCourtage") {
			if (stateSwitch.fraisCourtage === true) {
				// setValuesSimulation({ ...valuesSimulation, frais_courtage: 0 });
				setValuesAPI({ ...valuesAPI, frais_courtage: 0 });
			}
			else if (stateSwitch.fraisCourtage === false) {
				// setValuesSimulation({ ...valuesSimulation, frais_courtage: 2000 });
				setValuesAPI({ ...valuesAPI, frais_courtage: valuesAPI.frais_courtage });
			}
		}
		setStateSwitch({
			...stateSwitch,
			[name]: !stateSwitch[name],
		});
	};

  const handleChangeSwitch2 = (event, name) => {
		// If the switch is off for "charges_aleatoires_mois", we change its value to 0 for an impact in the results.
    if (name === "fraisChargesAleatoires") {
      //setExpandedPanel(isExpanded ? panel : false);
      //event.stopPropagation();
			if (stateSwitch.fraisChargesAleatoires === true) {
				// setValuesSimulation({ ...valuesSimulation, charges_aleatoires_mois: 0 });
				setValuesAPI({ ...valuesAPI, charges_aleatoires_mois: 0 });
			}
			else if (stateSwitch.fraisChargesAleatoires === false) {
				// setValuesSimulation({ ...valuesSimulation, charges_aleatoires_mois: 2000 });
				setValuesAPI({ ...valuesAPI, charges_aleatoires_mois: data.charges_aleatoires_mois });
			}
		}
		setStateSwitch({
			...stateSwitch,
			[name]: !stateSwitch[name],
		});
	};

  /**
	* Function fired by Material UI which returns a string value that provides
	* a user-friendly name for the current value of the slider.
	* @param {Number} value : The thumb label's value to format.
	* @param {Number} index : The thumb label's index to format.
	* @returns {String} : value formatted with €
	*/
	const parseValue = (value, index) => {
		return `${new Intl.NumberFormat("fr-FR", {
			style: "currency",
			currency: "EUR",
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		}).format(value)}`;
	};
  /**
   *
   * @param {*} x
   * @param {*} y
   * @returns {Number}
   */
  const numRoundMultiple = (x, y) => {
    return Math.round(x / y) * y;
  };
  const chartData = [
    { region: 'Prix du bien ( FAI )', val: mise_vente_fai },
    { region: 'Travaux et ameublement', val: amenagement_interieur_ttc+ montant_travaux_ttc },
    { region: 'Frais de notaire', val: frais_notaire },
    { region: 'Frais Bevouac', val: honoraire_bevouac_ttc },

  ];
  const chartData2 = [
    { type: "Gain net de l'investissement Bevouac", val: graphs.comparatif_livret_a?.data[0]?.y[1]},
    { type: 'Livret A', val: graphs.comparatif_livret_a?.data[0]?.y[0] },
  ];
  
  const chartData3 = [
    { x: 'Prix du bien (FAI)', y: mise_vente_fai}, 
    { x: 'Travaux et ameublement', y: amenagement_interieur_ttc+ montant_travaux_ttc }, 
    { x: 'Frais de notaire', y: frais_notaire },
    { x: 'Frais Bevouac', y: honoraire_bevouac_ttc },
  ];
  const chartData3Total = { z : parseInt(mise_vente_fai+amenagement_interieur_ttc+ montant_travaux_ttc+frais_notaire+honoraire_bevouac_ttc) }
  const chartData4 = [{ 
    state: "",
    type1: parseInt(graphs.comparatif_livret_a?.data[0]?.y[1]?.toFixed(1)),
    type2: parseInt(graphs.comparatif_livret_a?.data[0]?.y[0]?.toFixed(1))   
  }];

  const chartData4Gain = { z : parseInt(graphs.comparatif_livret_a?.data[0]?.y[1])}

  const chartData4Livret = { z : parseInt(graphs.comparatif_livret_a?.data[0]?.y[0]) }

  const chartData5 = [{ 
    state: "",
    type1: graphs.levier_bancaire?.data[0]?.y[1],
    type2: mise_depart, 
  }];
  const chartData6 = [{
    state: 'Revenus',
    type1: parseInt(mise_vente_fai?.toFixed(1)),
  }, {
    state: 'Depenses',
    type2: parseInt(mise_depart?.toFixed(1)),
    type3: parseInt(rente_nette?.toFixed(1)),
    type4: parseInt(montant_emprunte?.toFixed(1)),
    type5: "15000",
  }];
  const chartData6PlusValue = [{
    state: 'Revenus',
    type1: parseInt(graphs.plus_value_revente?.gains_nets_mensuels),
    type2: parseInt(graphs.plus_value_revente?.prix_revente),
  }, {
    state: 'Depenses',
    type3: parseInt(graphs.plus_value_revente?.effort_epargne_place),
    type4: parseInt(graphs.plus_value_revente?.capital_du_restant),
    type5: parseInt(graphs.plus_value_revente?.mise_de_depart),
    type6: parseInt(graphs.plus_value_revente?.gain_net_revente),
  }];
  const chartData7 = [{
    state: 'Revenus',
    type1: new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    }).format(graphs.levier_bancaire?.data[0]?.y[1]),
  }, {
    state: 'Depenses',
    type2: new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    }).format(mise_depart),
    type3: new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    }).format(rente_nette),
    type4: new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    }).format(montant_emprunte),
    type5: "15000",
  }];
  
  const chartData8 = [{
    state: 'Revenus',
    type1: parseInt(graphs.levier_bancaire?.data[0]?.y[1]?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"}),
  }, {
    state: 'Depenses',
    type2: parseInt(mise_depart?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"}),
    type3: parseInt(rente_nette?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"}),
    type4: parseInt(montant_emprunte?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"}),
    type5: "15000",
  }];
  
  const toggleOpenSimulator = () => {

		setIsOpen(!isOpen);
	};

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} arrow classes={{ popper: className }} data-html="true"/>
	  ))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
		  color: theme.palette.common.black,
		},
		[`& .${tooltipClasses.tooltip}`]: {
		  backgroundColor: theme.palette.common.black,
		},
	  }));

  return (
    

    <StyledEngineProvider injectFirst>
      <Grid container id="margeTitle">
        <Divider text="Acquisition et financement"  />

      </Grid>
      <Grid  container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <div className="ffont">
          L’investissement présente des risques. Nos calculs sont des prévisions données exclusivement à titre indicatif, basées sur des hypothèses définies par l'analyse du marché immobilier local et conditions de financement moyennes. Par conséquent, aucune garantie ne peut être donnée quant à la performance future de l’investissement.
          </div>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      {/* Total Acquisition*/}
      <Grid  container spacing={0} className="container-pad2" id="bilan_comptable">
        <Grid  item container xs={3} ></Grid>
        <Grid  item container xs={6} spacing={0} className="container-pad-left" id="acquisition">
          <Grid item xs={12} >
            <Paper
              variant="outlined"
            >
                <div class="panel1a-content">
                  <Accordion elevation={0}>
                    <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                        >
                          <Typography>
                            <div className="madivtitle svgacquisition lazyloaded" loading="lazy">
                              Total acquisition
                            </div>
                          </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left marge-top">Négociation</h3>
                      <div className="panel1a-border">
                          {negociation_details.map((row) => (
                            <Grid container className="panel1a-calcul">
                            {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                              <Grid item xs={4} className="inside-section2">
                                {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                              </Grid>
                            :
                              <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                <Grid item xs={4} className="inside-section2 label">
                                    {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                  </Grid>
                              </BootstrapTooltip>
                              }

                              <Grid item xs={8} className="inside-section">
                                {row.poste}
                              </Grid>
                          </Grid>
                          ))}
                      </div>
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left">Acquisition</h3>
                      <div className="panel1a-border">
                          {acquisition_details1.map((row) => (
                            <Grid container className="panel1a-calcul">
                            {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                              <Grid item xs={4} className="inside-section2">
                                {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                              </Grid>
                            :
                              <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                <Grid item xs={4} className="inside-section2 label">
                                    {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                  </Grid>
                              </BootstrapTooltip>
                              }

                              <Grid item xs={8} className="inside-section">
                                {row.poste}
                              </Grid>
                          </Grid>
                          ))}
                          {/*frais de courtage*/}
                          <Grid style={cgp === "cgp" ? { display: "none" } : { display: "flex" }} container className={`toggle-section toggle-section-${valuesAPI.frais_courtage}`}>
                            
                            <Grid item xs={1} style={{ paddingRight: "6%", paddingTop: "1%", paddingBottom: "1%", paddingLeft: "0" }}>
                            <Switch
                                      isActive={fraisCourtage} 
                                      aria-checked="true"
                                      aria-label="Toggle blue light"
                                      onClick={(e) => handleChangeSwitch(e, "fraisCourtage")}
                                      loader={loader}
                                    />
                            </Grid>
                            <Grid item xs={3} className="inside-section2">
                                {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR", maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(valuesAPI.frais_courtage)}
                            </Grid>
                            <Grid item xs={8} className="inside-section">
                                Frais de courtage
                            </Grid>
                        </Grid>
                        {/*frais_ingenierie*/}
                        <Grid container style={cgp === "cgp" ? { display: "flex" } : { display: "none" }}>
                            <Grid item xs={4} className="inside-section2">
                              {parseInt(frais_ingenierie?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                            </Grid>
                            <Grid item xs={8} className="inside-section">
                                Frais d'ingénierie
                            </Grid>
                        </Grid>
                        {acquisition_details2.map((row) => (
                            <Grid container className="panel1a-calcul">
                              {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                                <Grid item xs={4} className="inside-section2">
                                  {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                </Grid>
                              :
                                <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                  <Grid item xs={4} className="inside-section2 label">
                                      {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                    </Grid>
                                </BootstrapTooltip>
                                }

                                <Grid item xs={8} className="inside-section">
                                  {row.poste}
                                </Grid>
                            </Grid>
                          ))}
                      </div>
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left">Financement</h3>
                      <div>
                          {financement_details.map((row) => (
                            <Grid container className="panel1a-calcul">
                              {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                                <Grid item xs={4} className="inside-section2">
                                  {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                </Grid>
                              :
                                <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                  <Grid item xs={4} className="inside-section2 label">
                                      {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                    </Grid>
                                </BootstrapTooltip>
                                }

                                <Grid item xs={8} className="inside-section">
                                  {row.poste}
                                </Grid>
                            </Grid>
                          ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
              </div>
            </Paper>
          </Grid>

      

      </Grid>
      <Grid  item container xs={3} ></Grid>
    </Grid>

    {/* Bilan Financier */}
    <Grid  container spacing={0} className="container-pad2">
        <Grid  item container xs={3} ></Grid>
        <Grid  item container xs={6} spacing={0} className="container-pad-left" id="acquisition">
          <Grid item xs={12} >
            <Paper
              variant="outlined"
            >
                <div class="panel1a-content">
                  <Accordion elevation={0}>
                  <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                        >
                          <Typography>
                            <div className="madivtitle svgbilan lazyloaded" loading="lazy">
                              Bilan financier
                            </div>
                          </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left marge-top">Bilan comptable mensuel</h3>
                      <div className="panel1a-border">
                          {bilan_comptable1.map((row) => (
                            <Grid container className="panel1a-calcul">
                              {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                                <Grid item xs={4} className="inside-section2">
                                  {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                </Grid>
                              :
                                <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                  <Grid item xs={4} className="inside-section2 label">
                                      {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                    </Grid>
                                </BootstrapTooltip>
                                }

                                <Grid item xs={8} className="inside-section">
                                  {row.poste}
                                </Grid>
                            </Grid>
                          ))}
                      </div>
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left">Détail des charges récurrentes (i)</h3>
                      <div className="panel1a-border">
                          {bilan_comptable2.map((row) => (
                            <Grid container className="panel1a-calcul">
                              {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                                <Grid item xs={4} className="inside-section2">
                                  {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                </Grid>
                              :
                                <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                  <Grid item xs={4} className="inside-section2 label">
                                      {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                    </Grid>
                                </BootstrapTooltip>
                                }

                                <Grid item xs={8} className="inside-section">
                                  {row.poste}
                                </Grid>
                            </Grid>
                          ))}
                      </div>
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left">Détail des charges de gestion locative (ii)</h3>
                      <div>
                      {hazards_expenses_details.map((row) => (
                            <Grid container className="panel1a-calcul">
                              {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                                <Grid item xs={4} className="inside-section2">
                                  {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                </Grid>
                              :
                                <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                  <Grid item xs={4} className="inside-section2 label">
                                      {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                    </Grid>
                                </BootstrapTooltip>
                                }

                                <Grid item xs={8} className="inside-section">
                                  {row.poste}
                                </Grid>
                            </Grid>
                          ))}

                            

                          <Grid container className={`toggle-section toggle-section-${valuesAPI.charges_aleatoires_mois}`}>
                                <Grid item xs={1} style={{ paddingRight: "6%", paddingTop: "1%", paddingBottom: "1%", paddingLeft: "0" }}>
                                  <Switch
                                        isActive={fraisChargesAleatoires} 
                                        aria-checked="true"
                                        aria-label="Toggle blue light" 
                                        onClick={(e) => handleChangeSwitch2(e, "fraisChargesAleatoires")}
                                        loader={loader}
                                    />
                                </Grid>
                                <Grid item xs={3} className="inside-section2" style={{textAlign:"right"}}>
                                    <strong>= {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR", maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(parseInt(valuesAPI.charges_aleatoires_mois))}</strong>
                                </Grid>
                                <Grid item xs={8} className="inside-section" style={{paddingLeft:"6%",paddingTop:"1%"}}>
                                    <strong>Charges de gestion locative (ii)</strong>
                                </Grid>
                              </Grid>
                      </div>
                    </AccordionDetails>
                  </Accordion>
              </div>
              
            </Paper>
          </Grid>

      

      </Grid>
      <Grid  item container xs={3} ></Grid>
    </Grid>




{/* Projection de l'investissement */}
<Grid  container spacing={0} className="container-pad2">
        <Grid  item container xs={3} ></Grid>
        <Grid  item container xs={6} spacing={0} className="container-pad-left" id="acquisition">
          <Grid item xs={12} >
            <Paper
              variant="outlined"
            >
                <div class="panel1a-content">
                  <Accordion elevation={0}>
                  <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                        >
                          <Typography>
                            <div className="madivtitle svganalyses lazyloaded" loading="lazy">
                            Projection de l'investissement
                            </div>
                          </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <h3 class="MuiTypography-root jss13 MuiTypography-h6 left marge-top">Gain net dans {duree_revente} ans</h3>
                      <div className="panel1a-border">
                          {resale_details.map((row) => (
                            <Grid container className="panel1a-calcul">
                              {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                                    <Grid item xs={4} className="inside-section2">
                                      {row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                    </Grid>
                              :
                                <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                  <Grid item xs={4} className="inside-section2 label">
                                      {(row.poste === "Valorisation annuelle du bien") 
                                        ? (
                                          <span>{row.signe}{row.val?.toFixed(0).toLocaleString()}{" %"}</span>
                                        ) : <span>{row.signe}{parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}</span>}
                                        
                                    </Grid>
                                </BootstrapTooltip>
                                }

                                <Grid item xs={8} className="inside-section">
                                  {row.poste}
                                </Grid>
                            </Grid>
                          ))}
                      </div>
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left">Rente nette</h3>
                      <div className="panel1a-border">
                          {location_details.map((row) => (
                            <Grid container className="panel1a-calcul">
                              {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                                <Grid item xs={4} className="inside-section2">
                                  {row.signe} {parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                </Grid>
                              :
                                <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                  <Grid item xs={4} className="inside-section2 label">
                                      {row.signe} {parseInt(row.val?.toFixed(1)).toLocaleString("fr-FR" ,{maximumFractionDigits: 0, useGrouping: true, style: "currency", currency: "EUR"})}
                                    </Grid>
                                </BootstrapTooltip>
                                }

                                <Grid item xs={8} className="inside-section">
                                  {row.poste}
                                </Grid>
                            </Grid>
                          ))}
                      </div>
                      <h3 class="MuiTypography-root jss13 MuiTypography-h6 left">Rendement de l’investissement</h3>
                      <div>
                        <Grid container className="panel1a-calcul">
                              <Grid item xs={4} className="inside-section2">
                                  {rendement_brut_france} %
                              </Grid>
                              <Grid item xs={8} className="inside-section">
                                  Rendement brut moyen France
                              </Grid>
                          </Grid>
                          {return_details.map((row) => (
                            <Grid container className="panel1a-calcul">
                              {(row.tooltip === false) || (row.tooltip === "") || (row.tooltip === "default") ?
                                <Grid item xs={4} className="inside-section2">
                                  {row.signe} {row.val?.toFixed(2).toLocaleString()}{" %"}
                                </Grid>
                              :
                                <BootstrapTooltip title={row.tooltip} placement="left" data-html="true">
                                  <Grid item xs={4} className="inside-section2 label">
                                      {row.signe} {row.val?.toFixed(2).toLocaleString()}{" %"}
                                    </Grid>
                                </BootstrapTooltip>
                                }

                                <Grid item xs={8} className="inside-section">
                                  {row.poste}
                                </Grid>
                            </Grid>
                          ))}
                          <Grid container className="panel1a-calcul">
                              <Grid item xs={4} className="inside-section2">
                                  <strong>{rendement_brut} %</strong>
                              </Grid>
                              <Grid item xs={8} className="inside-section">
                                  <strong>Rendement brut de l’investissement</strong>
                              </Grid>
                          </Grid>
                      </div>
                    </AccordionDetails>
                  </Accordion>
              </div>
            </Paper>
          </Grid>

      

      </Grid>
      <Grid  item container xs={3} ></Grid>
    </Grid>


    </StyledEngineProvider>
  );
};

Results.propTypes = {
  data: PropTypes.object.isRequired,
  loader: PropTypes.bool.isRequired,
  valuesAPI: PropTypes.exact({
    frais_courtage: PropTypes.number,
    charges_aleatoires_mois: PropTypes.number,
    frais_mise_en_location_mois: PropTypes.number,
    provisions_pour_imprevus_mois: PropTypes.number,
    vacance_locative_mois: PropTypes.number,
    expert_comptable_mois: PropTypes.number,
    caution_frais_dossier: PropTypes.number,
    bilan_mensuel: PropTypes.number,
    gain_net_revente: PropTypes.number,
    taux_emprunt_recommande: PropTypes.number,
    mensualite: PropTypes.number,
    montant_emprunte: PropTypes.number,
    prix_revente: PropTypes.number,
    rente_nette: PropTypes.number,
    total_acquisition: PropTypes.number,
    total_depenses_mois: PropTypes.number,
    frais_ingenierie: PropTypes.number,
    graphs: PropTypes.exact({
      comparatif_livret_a: PropTypes.object,
      comparatif_mensualite_loyer: PropTypes.object,
      evolution_gain_net: PropTypes.object,
      levier_bancaire: PropTypes.object,
      plus_value_revente: PropTypes.object,
    }).isRequired,
  }),
  valuesSimulation: PropTypes.exact({
    mise_depart: PropTypes.number,
    duree_emprunt: PropTypes.number,
    taux_emprunt: PropTypes.number,
    taux_assurance: PropTypes.number,
    duree_revente: PropTypes.number,
  }).isRequired,
  hasDataChanged: PropTypes.exact({
    bilan_mensuel: PropTypes.bool,
    gain_net_revente: PropTypes.bool,
    mensualite: PropTypes.bool,
    montant_emprunte: PropTypes.bool,
    frais_mise_en_location_mois: PropTypes.bool,
    provisions_pour_imprevus_mois: PropTypes.bool,
    vacance_locative_mois: PropTypes.bool,
    expert_comptable_mois: PropTypes.bool,
    prix_revente: PropTypes.bool,
    rente_nette: PropTypes.bool,
    total_acquisition: PropTypes.bool,
    total_depenses_mois: PropTypes.bool,
    frais_courtage: PropTypes.bool,
    charges_aleatoires_mois: PropTypes.bool,
    mise_depart: PropTypes.bool,
    duree_emprunt: PropTypes.bool,
    taux_emprunt: PropTypes.bool,
    taux_emprunt_recommande: PropTypes.bool,
    taux_assurance: PropTypes.bool,
    duree_revente: PropTypes.bool,
    fraisCourtage: PropTypes.bool,
    fraisChargesAleatoires: PropTypes.bool,
    effortEpargne: PropTypes.bool,
    equilibre: PropTypes.bool,
    graphs: PropTypes.bool,
    caution_frais_dossier: PropTypes.bool,
    frais_ingenierie: PropTypes.bool,
  }).isRequired,
  fraisCourtageSwitch: PropTypes.bool.isRequired,
  fraisChargesAleatoiresSwitch: PropTypes.bool.isRequired,
	setStateSwitch: PropTypes.func.isRequired,
};

Results.defaultProps = {
  valuesAPI: {
    frais_courtage: 2500,
    charges_aleatoires_mois: 1000,
    caution_frais_dossier: 0,
    bilan_mensuel: 0,
    gain_net_revente: 0,
    taux_emprunt_recommande: 0,
    mensualite: 0,
    frais_mise_en_location_mois: 0,
    provisions_pour_imprevus_mois: 0,
    vacance_locative_mois: 0,
    expert_comptable_mois: 0,
    montant_emprunte: 0,
    prix_revente: 0,
    rente_nette: 0,
    total_acquisition: 0,
    total_depenses_mois: 0,
    graphs: {},
  },
};

export default Results;