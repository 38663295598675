/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

export default {
	// ANGERS
	Angers: [
		{
			label: "Angers - Belle-Beille",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/Angers+-+Belle-Beille/Belle_Beille_first.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/Angers+-+Belle-Beille/Belle_Beille_sec.png",
			],
		},
		{
			label: "Angers - Centre-Ville",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Centre-Ville/Angers%20centre%20ville%20-%20rue%20beaurepaire%20_%20@lucie.auguste.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Centre-Ville/Angers%20centre%20ville%20-%20rue%20Lionnaise%20_%20@lucie.auguste.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Centre-Ville/Angers%20centre%20ville%20-%20rue%20Saint%20Aignan%20_%20@lucie.auguste.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/Angers+-+Centre-Ville/Angers_centre_ville-rue_Saint_Aignan.png",
			],
		},
		{
			label: "Angers - Deux Croix, Banchais",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Deux%20Croix,%20Banchais/Angers%20-%20Deux%20Croix%20Banchais.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/Angers+-+Deux+Croix%2C+Banchais/Angers-Deux_Croix_Banchais.png",
			],
		},
		{
			label: "Angers - Doutre, St Jacques, Nazareth",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/Angers+-+Doutre%2C+St+Jacques%2C+Nazareth/Angers_-_Doutre%2C_St_Jacques%2C_Nazareth.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Doutre,%20St%20Jacques,%20Nazareth/Angers%20-%20Doutre,%20St%20Jacques,%20Nazareth%20-%20@mercier_i.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Doutre,%20St%20Jacques,%20Nazareth/Angers%20-%20Doutre,%20St%20Jacques,%20Nazareth%20-%20@tisaconseils.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Doutre,%20St%20Jacques,%20Nazareth/Angers%20-%20Doutre,%20St%20Jacques,%20Nazareth.png",
			],
		},
		{
			label: "Angers - Hauts-de-Saint-Aubin",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Hauts-de-Saint-Aubin/Angers%20-%20Haut%20de%20Saint%20Aubin.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Hauts-de-Saint-Aubin/Angers%20-%20Haut%20de%20St%20Aubin.png",
			],
		},
		{
			label: "Angers - Justices",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Justices/Angers%20-%20Justices.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/Angers+-+Justices/Angers_-_Justices.png",
			],
		},
		{
			label: "Angers - La Fayette, Eblé",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20La%20Fayette,%20Eblé/Angers%20-%20La%20Fayette,%20Eblé%20-%20parc%20du%20Pin%20-%20@tisaconseils.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20La%20Fayette,%20Eblé/Angers%20-%20La%20Fayette,%20Eblé%20-%20place%20de%20lafayette.png",
			],
		},
		{
			label: "Angers - Lac de Maine",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Lac%20de%20Maine/Angers%20-%20Lac%20de%20Maine.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/Angers+-+Lac+de+Maine/Angers_-_Lac_de_Maine.png",
			],
		},
		{
			label: "Angers - Madeleine, Saint Léonard",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Madeleine,%20Saint%20Léonard/Angers%20-%20Madeleine,%20Saint%20Léonard.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Madeleine,%20Saint%20Léonard/Angers%20-%20Madeleine,%20St%20Léonard.png",
			],
		},
		{
			label: "Angers - Monplaisir",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Monplaisir/Angers%20-%20Mon%20Plaisir.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/Angers+-+Monplaisir/Angers_-_Mon_Plaisir.png",
			],
		},
		{
			label: "Angers - Roseraie, Orgemont",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Roseraie,%20Orgemont/Angers%20-%20La%20Roseraie,%20Orgemont%20-%20@%20mili_gramme.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Roseraie,%20Orgemont/Angers%20-%20La%20Roseraie,%20Orgemont%20-%20@collectif_ladouceur.png",
			],
		},
		{
			label: "Angers - Saint-Serge, Ney, Chalouère",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/Angers+-+Saint-Serge%2C+Ney%2C+Chalou%C3%A8re/Angers_-_Saint_Serge.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Saint-Serge,%20Ney,%20Chalouère/Angers%20-%20Saint%20Serge.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Angers/Angers%20-%20Saint-Serge,%20Ney,%20Chalouère/Angers%20-%20Saint-Serge.png",
			],
		},
		{
			label: "default",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/default/Angers_1.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/default/Angers_2.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Angers/default/Angers_3.png",
			],
		},
	],
	// LE HAVRE
	Le_Havre: [
		{
			label: "Le Havre - Anatole France / Danton",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Anatole+France%2C+Danton/Le_Havre_AnatoleFrance1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Anatole+France%2C+Danton/Le_Havre_AnatoleFrance2.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Anatole+France%2C+Danton/Le_Havre_AnatoleFrance3.jpeg",
			],
		},
		{
			label: "Le Havre - Arcole Brindeau",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Arcole+Brindeau/Le_Havre_Arcole+Brindeau1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Arcole+Brindeau/Le_Havre-Arcole-Brindeau3.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Arcole+Brindeau/LeHavreArcoleBrindeau2.jpeg",
			],
		},
		{
			label: "Le Havre - Centre",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Centre/Le_Havre_Centre1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Centre/Le_Havre_Centre2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Centre/Le_Havre_Centre3.jpeg",
			],
		},
		{
			label: "Le Havre - Côte Est / Soquence",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+C%C3%B4te+Est%2C+Soquence/Le_Havre_Soquence2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+C%C3%B4te+Est%2C+Soquence/Le_Havre_Soquence3.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+C%C3%B4te+Est%2C+Soquence/Le_Havre_Soquence_1.jpeg",
			],
		},
		{
			label: "Le Havre - Côte Ouest / Ormeaux",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+C%C3%B4te+Ouest%2C+Ormeaux/Le_Havre_Ormeaux2.jpg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+C%C3%B4te+Ouest%2C+Ormeaux/Le_Havre_Ormeaux3.jpeg",
			],
		},
		{
			label: "Le Havre - Eure",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Eure/L_Havre_EUre1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Eure/Le_Havre_EUre2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Eure/Le_Havre_EUre3.jpeg",
			],
		},
		{
			label: "Le Havre - Graville",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Graville/Le_Havre_Graville1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Graville/Le_Havre_Graville2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Graville/Le_Havre_Graville3.jpeg",
			],
		},
		{
			label: "Le Havre - Saint Marie / Saint Léon",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Saint+Marie%2C+Saint+L%C3%A9on/Le_Havre_Saint_Marie1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Saint+Marie%2C+Saint+L%C3%A9on/Le_Havre_Saint_Marie2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Saint+Marie%2C+Saint+L%C3%A9on/Le_Havre_Saint_Marie3.jpeg",
			],
		},
		{
			label: "Le Havre - Saint-Vincent",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Saint-Vincent/Le_Havre_Saint_Vincent1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Saint-Vincent/Le_Havre_Saint_Vincent2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/Le+Havre+-+Saint-Vincent/Le_Havre_Saint_Vincent3.jpeg",
			],
		},
		{
			label: "default",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/default/LeHavre1.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/default/LeHavre2.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Le_Havre/default/LeHavre3.png",
			],
		},
	],
	// LILLE
	Lille: [
		{
			label: "La Madeleine",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/La+Madeleine/Lille_La_Madeleine1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/La+Madeleine/Lille_La_Madeleine2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/La+Madeleine/Lille_La_Madeleine3.png",
			],
		},
		{
			label: "Lille - Bois Blancs",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lille/Lille%20-%20Bois%20Blancs/Lille%20-%20Bois%20blanc%201.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lille/Lille%20-%20Bois%20Blancs/Lille%20-%20Bois%20blanc%202.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Bois+Blancs/Lille_Bois_Blancs3.jpeg",
			],
		},
		{
			label: "Lille - Centre",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lille/Lille%20-%20Centre/Lille%20-%20centre%201.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lille/Lille%20-%20Centre/Lille%20-%20centre%202.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lille/Lille%20-%20Centre/Lille%20-%20centre%203.png",
			],
		},
		{
			label: "Lille - Fives",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Fives/LIlle_FIves3.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Fives/Lille_Fives1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Fives/Lille_Fives2.jpeg",
			],
		},
		{
			label: "Lille - Hellemmes",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Hellemmes/Lille_Hellemes1.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Hellemmes/Lille_Hellemes2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Hellemmes/Lille_Hellemes3.jpeg",
			],
		},
		{
			label: "Lille - Moulins",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Moulins/Lille_Moulins1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Moulins/Lille_Moulins2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Moulins/Lille_Moulins3.png",
			],
		},
		{
			label: "Lille - Saint Maurice / Pellevoisin",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Saint+Maurice%2C+Pellevoisin/Lille_Saint_Maurice_1.jpg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Saint+Maurice%2C+Pellevoisin/Lille_Saint_Maurice_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Saint+Maurice%2C+Pellevoisin/Lille_Saint_Maurice_3.png",
			],
		},
		{
			label: "Lille - Sud",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Sud/Lille_Sud_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Sud/Lille_Sud_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Sud/Lille_Sud_3.jpeg.webp",
			],
		},
		{
			label: "Lille - Vauban",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Vauban/Lille_Vauban_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Vauban/Lille_Vauban_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Vauban/Lille_Vauban_3.jpeg",
			],
		},
		{
			label: "Lille - Vieux Lille",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lille/Lille%20-%20Vieux%20Lille/Lille%20-%20Vieux%20Lille%201.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lille/Lille%20-%20Vieux%20Lille/Lille%20-%20Vieux%20Lille%202.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lille/Lille%20-%20Vieux%20Lille/Lille%20-%20Vieux%20Lille%203.png",
			],
		},
		{
			label: "Lille - Wazemmes",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Wazemmes/Wazemmes_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Wazemmes/Wazemmes_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Lille+-+Wazemmes/Wazemmes_3.jpeg",
			],
		},
		{
			label: "Loos",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Loos/Loos_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Loos/Loos_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Loos/Loos_3.jpeg",
			],
		},
		{
			label: "Saint-André-Lez-Lille",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Saint-Andr%C3%A9-Lez-Lille/Saint_andre%CC%81_les_lille_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Saint-Andr%C3%A9-Lez-Lille/Saint_andre%CC%81_les_lille_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Saint-Andr%C3%A9-Lez-Lille/Saint_andre%CC%81_les_lille_3.jpeg",
			],
		},
		{
			label: "Villeneuve-d'Ascq - Cité scientifique / Triolo",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+Cit%C3%A9%C2%A0+scientifique%2C+Triolo/Villeneuve_d_Ascq_Cite%CC%81_scientifique_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+Cit%C3%A9%C2%A0+scientifique%2C+Triolo/Villeneuve_d_Ascq_Cite%CC%81_scientifique_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+Cit%C3%A9%C2%A0+scientifique%2C+Triolo/Villeneuve_d_Ascq_Cite%CC%81_scientifique_3.jpeg",
			],
		},
		{
			label: "Villeneuve-d'Ascq - Flers Breucq / Sart Babylone / Recueil",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+Flers+Breucq%2C+Sart+Babylone%2C+Recueil/Villeneuve_d_Ascq_Flers_Breucq_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+Flers+Breucq%2C+Sart+Babylone%2C+Recueil/Villeneuve_d_Ascq_Flers_Breucq_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+Flers+Breucq%2C+Sart+Babylone%2C+Recueil/Villeneuve_d_Ascq_Flers_Breucq_3.jpeg",
			],
		},
		{
			label: "Villeneuve-d'Ascq - Hôtel de Ville / Pont de boise",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+H%C3%B4tel+de+Ville%2C+Pont+de+bois/Villeneuve_d_Ascq_Ho%CC%82tel_de_Ville_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+H%C3%B4tel+de+Ville%2C+Pont+de+bois/Villeneuve_d_Ascq_Ho%CC%82tel_de_Ville_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+H%C3%B4tel+de+Ville%2C+Pont+de+bois/Villeneuve_d_Ascq_Ho%CC%82tel_de_Ville_3.jpeg",
			],
		},
		{
			label: "Villeneuve-d'Ascq - Prés / Flers Bourg / Château",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+Pr%C3%A9s%2C+Flers+Bourg%2C+Ch%C3%A2teau/Lille_Pre%CC%81s_Flers_Bourg_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+Pr%C3%A9s%2C+Flers+Bourg%2C+Ch%C3%A2teau/Lille_Pre%CC%81s_Flers_Bourg_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+Pr%C3%A9s%2C+Flers+Bourg%2C+Ch%C3%A2teau/Lille_Pre%CC%81s_Flers_Bourg_3.jpeg",
			],
		},
		{
			label: "Villeneuve-d'Ascq - Résidence / Poste / Annapes",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+R%C3%A9sidence%2C+Poste%2C+Annapes/Villeneuve_d_Ascq_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+R%C3%A9sidence%2C+Poste%2C+Annapes/Villeneuve_d_Ascq_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/Villeneuve-d+Ascq+-+R%C3%A9sidence%2C+Poste%2C+Annapes/Villeneuve_d_Ascq_3.jpeg",
			],
		},
		{
			label: "default",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/default/Lille1.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/default/Lille2.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lille/default/Lille3.png",
			],
		},
	],
	// LYON
	Lyon: [
		{
			label: "Lyon 1er",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%201er/Lyon%201.1.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%201er/Lyon%201.2.jpeg",
			],
		},
		{
			label: "Lyon 2nd",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%202nd/Lyon%202.1.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%202nd/Lyon%202.2.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%202nd/Lyon%202.3.jpeg",
			],
		},
		{
			label: "Lyon 3ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%203ème/Lyon%203.1.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%203ème/Lyon%203.2.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%203ème/Lyon%203.png",
			],
		},
		{
			label: "Lyon 4ème",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lyon/Lyon+4%C3%A8me/Lyon_4_1.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%204ème/Lyon%204.2.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%204ème/Lyon%204.3.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%204ème/Lyon%204.png",
			],
		},
		{
			label: "Lyon 5ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%205ème/Lyon%205.1.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%205ème/Lyon%205.2.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%205ème/Lyon%205.3.jpeg",
			],
		},
		{
			label: "Lyon 6ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%206ème/Lyon%206.1.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%206ème/Lyon%206.2.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%206ème/Lyon%206.3.jpeg",
			],
		},
		{
			label: "Lyon 7ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%207ème/Lyon%207.1.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%207ème/Lyon%207.2.jpeg",
			],
		},
		{
			label: "Lyon 8ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%208ème/Lyon%208.2.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%208ème/Lyon%208.3.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%208ème/Lyon%208.jpeg",
			],
		},
		{
			label: "Lyon 9ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%209ème/Lyon%209.2.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Lyon%209ème/Lyon%209.jpeg",
			],
		},
		{
			label: "Villeurbanne",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lyon/Villeurbanne/Villeurbanne_1.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Lyon/Villeurbanne/Villeurbanne.png",
			],
		},
		{
			label: "default",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lyon/default/Lyon1.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lyon/default/Lyon2.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Lyon/default/Lyon3.png",
			],
		},
	],
	// MARSEILLE
	Marseille: [
		{
			label: "Marseille 1er",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%201er/Marseille%20-%201°%20arrondissement.jpg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Marseille/Marseille+1er/Marseille+-+1%C2%B0+arrondissement_1.jpg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%201er/Marseille%20-%201°%20arrondissement_1.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%201er/Marseille%20-%201°%20arrondissement.png",
			],
		},
		{
			label: "Marseille 2nd",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%202ème/Marseille%20-%202°%20arrondissement.jpg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%202ème/Marseille%20-%202°%20arrondissement.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%202ème/Marseille%20-%202°%20arrondissement_1.png",
			],
		},
		{
			label: "Marseille 3ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%203ème/Marseille%20-%203°%20arrondissement.jpg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%203ème/Marseille%20-%203°%20arrondissement.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%203ème/Marseille%20-%203°%20arrondissement_1.png",
			],
		},
		{
			label: "Marseille 4ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%204ème/Marseille%20-%204°%20arrondissement.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%204ème/Marseille%20-%204°%20arrondissement.jpg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%204ème/Marseille%20-%204°%20arrondissement.png",
			],
		},
		{
			label: "Marseille 5ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%205ème/Marseille%20-%205°%20arrondissement%20.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%205ème/Marseille%20-%205°%20arrondissement.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%205ème/Marseille%20-%205°%20arrondissement_1.png",
			],
		},
		{
			label: "Marseille 6ème",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Marseille/Marseille+6%C3%A8me/Marseille+-+6_arrondissement_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Marseille/Marseille+6%C3%A8me/Marseille+-+6_arrondissement_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Marseille/Marseille+6%C3%A8me/Marseille+-+6_arrondissement_3.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Marseille/Marseille+6%C3%A8me/Marseille+-+6_arrondissement_4.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Marseille/Marseille+6%C3%A8me/Marseille+-+6_arrondissement_5.png",
			],
		},
		{
			label: "Marseille 7ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%207ème/Marseille%20-%207°%20arrondissement.jpg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%207ème/Marseille%20-%207°%20arrondissement.png",
			],
		},
		{
			label: "Marseille 8ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%208ème/Marseille%20-%208°%20arrondissement_2.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%208ème/Marseille%20-%208°%20arrondissement.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%208ème/Marseille%20-%208°%20arrondissement_1.png",
			],
		},
		{
			label: "Marseille 9ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%209ème/Marseille%20-%209°%20arrondissement.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%209ème/Marseille%20-%209°%20arrondissement_1.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%209ème/Marseille%20-%209°%20arrondissement.png",
			],
		},
		{
			label: "Marseille 10ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2010ème/Marseille%20-%2010°%20arrondissement.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2010ème/Marseille%20-%2010°%20arrondissement_1.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2010ème/Marseille%20-%2010°%20arrondissement_2.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2010ème/Marseille%20-%2010°%20arrondissement_3.png",
			],
		},
		{
			label: "Marseille 11ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2011ème/Marseille%20-%2011°%20arrondissement_1.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2011ème/Marseille%20-%2011°%20arrondissement_2.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2011ème/Marseille%20-%2011°%20arrondissement_3.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2011ème/Marseille%20-%2011°%20arrondissement.jpeg",
			],
		},
		{
			label: "Marseille 12ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2012ème/Marseille%20-%2012°%20arrondissement_1.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2012ème/Marseille%20-%2012°%20arrondissement.jpeg",
			],
		},
		{
			label: "Marseille 13ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2013ème/Marseille%20-%2013°%20arrondissement_1.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2013ème/Marseille%20-%2013°%20arrondissement.jpeg",
			],
		},
		{
			label: "Marseille 14ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2014ème/Marseille%20-%2014°%20arrondissement_1.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2014ème/Marseille%20-%2014°%20arrondissement.jpeg",
			],
		},
		{
			label: "Marseille 15ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2015ème/Marseille%20-%2015°%20arrondissement_1.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2015ème/Marseille%20-%2015°%20arrondissement.jpeg",
			],
		},
		{
			label: "Marseille 16ème",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2016ème/Marseille%20-%2016°%20arrondissement_1.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2016ème/Marseille%20-%2016°%20arrondissement_2.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Marseille/Marseille%2016ème/Marseille%20-%2016°%20arrondissement.jpeg",
			],
		},
		{
			label: "default",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Marseille/default/Marseille_1.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Marseille/default/Marseille_2.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Marseille/default/Marseille_3.png",
			],
		},
	],
	// NANCY
	Nancy: [
		{
			label: "Laxou",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Laxou/Nancy%20-%20Laxou%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Laxou/Nancy%20-%20Laxou.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Laxou/Nancy%20-%20Laxou_1.png",
			],
		},
		{
			label: "Nancy - Beauregard / Boufllers / Buthégnémont",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Beauregard%20-%20Boufllers%20-%20Buthégnémont/Nancy%20-%20Beauregard%20_%20Boufflers%20_%20Buthégnémont%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Beauregard%20-%20Boufllers%20-%20Buthégnémont/Nancy%20-%20Beauregard%20_%20Boufflers%20_%20Buthégnémont%202.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Beauregard%20-%20Boufllers%20-%20Buthégnémont/Nancy%20-%20Beauregard%20_%20Boufflers%20_%20Buthégnémont%203.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Beauregard%20-%20Boufllers%20-%20Buthégnémont/Nancy%20-%20Beauregard%20_%20Boufflers%20_%20Buthégnémont%204.png",
			],
		},
		{
			label: "Nancy - Bondoville / Scarpone / Libération",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Bondoville%20-%20Scarpone%20-%20Libération/Nancy%20-%20Bondonville,%20Scarpone,%20Libération%20-%201.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Bondoville%20-%20Scarpone%20-%20Libération/Nancy%20-%20Bondonville,%20Scarpone,%20Libération%20-%202.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Bondoville%20-%20Scarpone%20-%20Libération/Nancy%20-%20Bondonville,%20Scarpone,%20Libération%20-%203.png",
			],
		},
		{
			label: "Nancy - Centre Ville Charles III",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Centre%20Ville%20Charles%20III/Nancy%20-%20Centre%20ville,%20Charles%20III%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Centre%20Ville%20Charles%20III/Nancy%20-%20Centre%20ville,%20Charles%20III%202.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Centre%20Ville%20Charles%20III/Nancy%20-%20Centre%20ville,%20Charles%20III,%203.jpeg",
			],
		},
		{
			label: "Nancy - Haussonville / Artem",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Haussonville%20-%20Artem/Nancy%20-%20Haussonville,%20Artem%202.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Haussonville%20-%20Artem/Nancy%20-%20Haussonville,%20Artem,%201.jpeg",
			],
		},
		{
			label: "Nancy - Léopold Ville Vieille",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Léopold%20Ville%20Vieille/Nancy%20-%20Vieille%20ville,%20Léopold%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Léopold%20Ville%20Vieille/Nancy%20-%20Vieille%20ville,%20Léopold%202.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Léopold%20Ville%20Vieille/Nancy%20-%20Vieille%20ville,%20Léopold%203.png",
			],
		},
		{
			label: "Nancy - Mon Désert J. D'Arc / Saurupt Clémenceau",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Mon%20Désert%20J.%20D_Arc%20-%20Saurupt%20Clémenceau/Nancy%20-%20Mon%20Désert%20J.D_Arc,Saurupt%20Clémenceau%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Mon%20Désert%20J.%20D_Arc%20-%20Saurupt%20Clémenceau/Nancy%20-%20Mon%20Désert%20J.D_Arc,Saurupt%20Clémenceau%202.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Mon%20Désert%20J.%20D_Arc%20-%20Saurupt%20Clémenceau/Nancy%20-%20Mon%20Désert%20J.D_Arc,Saurupt%20Clémenceau%203.jpeg",
			],
		},
		{
			label: "Nancy - Poincaré Foch / Anatole France",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Poincaré%20Foch%20-%20Anatole%20France/Nancy%20-%20Poincaré%20Foch,%20Anatole%20France%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Poincaré%20Foch%20-%20Anatole%20France/Nancy%20-%20Poincaré%20Foch,%20Anatole%20France%202.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Poincaré%20Foch%20-%20Anatole%20France/Nancy%20-%20Poincaré%20Foch,%20Anatole%20France%203.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Poincaré%20Foch%20-%20Anatole%20France/Nancy%20-%20Poincaré%20Foch,%20Anatole%20France%204.png",
			],
		},
		{
			label: "Nancy - Saint Fiacre / III Maisons",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Saint%20Fiacre%20-%20III%20Maisons/Nancy%20-%20Saint%20Fiacre,%20III%20Maisons,%203.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Saint%20Fiacre%20-%20III%20Maisons/Nancy%20-%20Saint%20Fiacre,%20III%20maisons%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Saint%20Fiacre%20-%20III%20Maisons/Nancy%20-%20Saint%20Fiacre,%20III%20maisons%202.jpeg",
			],
		},
		{
			label: "Nancy - Saint Pierre René II Bonsecours",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Saint%20Pierre%20René%20II%20Bonsecours/Nancy%20-%20Saint%20Pierre%20René%20II%20Bonsecours%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Saint%20Pierre%20René%20II%20Bonsecours/Nancy%20-%20Saint%20Pierre%20René%20II%20Bonsecours%202.jpeg",
			],
		},
		{
			label: "Nancy - Stanislas Meurthe",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Stanislas%20Meurthe/Nancy%20-%20Stanislas,%20Meurthe%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Stanislas%20Meurthe/Nancy%20-%20Stanislas,%20Meurthe%202.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Stanislas%20Meurthe/Nancy%20-%20Stanislas,%20Meurthe%203.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Nancy%20-%20Stanislas%20Meurthe/Nancy%20-%20Stanislas,%20Meurthe%204.jpeg",
			],
		},
		{
			label: "Vandoeuvre-Lès-Nancy",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Vandoeuvre-Lès-Nancy/Vandoeuvre%20les%20Nancy%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Vandoeuvre-Lès-Nancy/Vandoeuvre-Lès-Nancy%202.png",
			],
		},
		{
			label: "Villiers-Lès-Nancy",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Villiers-Lès-Nancy/Nancy%20-%20Villiers%20les%20nancy%201.jpeg",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Villiers-Lès-Nancy/Nancy%20-%20Villiers%20les%20nancy%202.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Nancy/Villiers-Lès-Nancy/Nancy%20-%20Villiers%20les%20nancy%203.png",
			],
		},
		{
			label: "default",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Nancy/default/Nancy_1.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Nancy/default/Nancy_2.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Nancy/default/Nancy_3.png",
			],
		},
	],
	// RENNES
	Rennes: [
		{
			label: "Rennes - Beaulieu / Baud",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Beaulieu%2C+Baud/Rennes_Beaulie_Baud_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Beaulieu%2C+Baud/Rennes_Beaulie_Baud_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Beaulieu%2C+Baud/Rennes_Beaulie_Baud_3.jpeg",
			],
		},
		{
			label: "Rennes - Bréquigny",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Br%C3%A9quigny/Rennes_Bre%CC%81quigny_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Br%C3%A9quigny/Rennes_Bre%CC%81quigny_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Br%C3%A9quigny/Rennes_Bre%CC%81quigny_3.jpeg",
			],
		},
		{
			label: "Rennes - Centre",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Rennes/Rennes%20-%20Centre/Rennes%20-%20centre%201.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Rennes/Rennes%20-%20Centre/Rennes%20-%20centre%202.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Centre/Rennes_Centre.jpeg",
			],
		},
		{
			label: "Rennes - Cleunay / Arsenal / Redon",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Rennes/Rennes%20-%20Cleunay%20-%20Arsenal%20-%20Redon/Rennes%20-%20Cleunay%20_%20Arsenal%20_%20Redon%20-%201.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Cleunay+-+Arsenal+-+Redon/Rennes_Cleunay_Arsenal.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Cleunay+-+Arsenal+-+Redon/Rennes_Cleunay_Arsenal_1.jpeg",
			],
		},
		{
			label: "Rennes - Francisco Ferrer / Vern / Poterie",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Francisco+Ferrer%2C+Vern%2C+Poterie/Rennes_Francisco_Ferrer_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Francisco+Ferrer%2C+Vern%2C+Poterie/Rennes_Francisco_Ferrer_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Francisco+Ferrer%2C+Vern%2C+Poterie/Rennes_Francisco_Ferrer_3.jpeg",
			],
		},
		{
			label: "Rennes - Le Blosne",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Le+Blosne/Rennes_le_blosne_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Le+Blosne/Rennes_le_blosne_2.jpeg",
			],
		},
		{
			label: "Rennes - Maurepas / Patton / Les Gayeulles",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Maurepas%2C+Patton%2C+Les+Gayeulles/Rennes_Maurepas_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Maurepas%2C+Patton%2C+Les+Gayeulles/Rennes_Maurepas_2.jpeg",
			],
		},
		{
			label: "Rennes - Moulin Du Compte / La Touche",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Rennes/Rennes%20-%20Moulin%20Du%20Compte%20-%20La%20Touche/Rennes%20-%20Moulin%20Du%20Comte%20_%20La%20Touche%20-%201.png",
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Rennes/Rennes%20-%20Moulin%20Du%20Compte%20-%20La%20Touche/Rennes%20-%20Moulin%20Du%20Comte%20_%20La%20Touche%20-%202.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Moulin+Du+Compte+-+La+Touche/Rennes_Moulin_du_Compte.jpeg",
			],
		},
		{
			label: "Rennes - Saint Hélier / Thabor",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Saint+H%C3%A9lier%2C+Thabor/Rennes_Saint-He%CC%81lier_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Saint+H%C3%A9lier%2C+Thabor/Rennes_Saint-He%CC%81lier_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Saint+H%C3%A9lier%2C+Thabor/Rennes_Saint-He%CC%81lier_3.jpeg",
			],
		},
		{
			label: "Rennes - Saint Martin",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Saint+Martin/Rennes_Saint_martin_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Saint+Martin/Rennes_Saint_martin_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Saint+Martin/Rennes_Saint_martin_3.jpeg",
			],
		},
		{
			label: "Rennes - Sud Gare",
			images: [
				"https://bibliothequeimages.s3.amazonaws.com/photos_quartier/Rennes/Rennes%20-%20Sud%20Gare/Rennes%20-%20Sud%20Gare%201.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Sud+Gare/Rennes_Sud_Gare_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes+-+Sud+Gare/Rennes_Sud_Gare_3.jpeg",
			],
		},
		{
			label: "Rennes - Villejean / Beauregard",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes_Villejean_Beauregard/Rennes_Villejean_1.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes_Villejean_Beauregard/Rennes_Villejean_2.jpeg",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/Rennes_Villejean_Beauregard/Rennes_Villejean_3.jpeg",
			],
		},
		{
			label: "default",
			images: [
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/default/Rennes1.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/default/Rennes2.png",
				"https://bibliothequeimages.s3.eu-west-3.amazonaws.com/photos_quartier/Rennes/default/Rennes3.png",
			],
		},
	],
};