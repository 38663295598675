// Modules
import React from "react";
import PropTypes from "prop-types";

const CloseIcon = (props) => {
	const {
		clssName,
		onClick,
	} = props;

	return (
		<div className={`close-icon-container ${clssName}`} onClick={onClick}>
			<svg 
			width="15" 
			height="14" 
			className="closed"
			viewBox="0 0 15 14" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg">
				<path 
					d="M14.096 1.87475L12.7532 0.582489L7.42936 5.70571L2.10555 0.582489L0.762695 1.87475L6.08651 6.99797L0.762695 12.1212L2.10555 13.4134L7.42936 8.29023L12.7532 13.4134L14.096 12.1212L8.77222 6.99797L14.096 1.87475Z" fill="white"/>
		</svg>
		 Fermer</div>
	);
};

CloseIcon.propTypes = {
	clssName: PropTypes.string,
	onClick: PropTypes.func,
};

CloseIcon.defaultProps = {
	clssName: "",
	onClick: () => {},
};

export default CloseIcon;
