import { useState, useLayoutEffect } from "react";

/**
 * On resize of window, update width and height of the window
 * for a full responsive website.
 */
const useWindowSize = () => {
	const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

	useLayoutEffect(() => {
		// const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
		const updateSize = () => {
			setSize([
				/*window.screen.width || */document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth,
				/*window.screen.height || */document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight,
			]);
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}

		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
	
};

export default useWindowSize;
