// Modules
import React, { useContext } from "react";

// Context
import ApiContext from "../../contexts/ApiContext";

// Assets
import "./page-not-found.scss";
import Erreur404 from "../../assets/img/svg/Erreur404";

const Page404 = () => {
	const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);

	return (
		<div className={`not-found-container ${isMobile === true || isTablet === true || isSplitScreen === true ? "mobile" : ""}`}>
			<div className="svg-404-container">
				<Erreur404 />
			</div>
			<div className="text-404-container">
				<div className="text-wrapper">
					<h1 className="error-404">ERROR 404</h1>
					<h3 className="half-turn">
						C'est une impasse ici !
						<br />
						Faites demi-tour.
					</h3>
					<a
						rel="noreferrer noopener"
						href="https://www.bevouac.com/"
						className="link-redirect-to-bevouac"
					>
						Retourner sur le site de Bevouac
						<span className="underline" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default Page404;
