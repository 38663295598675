/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
const branchState = window.location.href.includes("fiche.bevouac.com") === true || window.location.href.includes("prod-front-biens.vercel.app") === true
	? "production"
	: window.location.href.includes("front-biens.vercel.app") === true
		? "staging"
		: window.location.href.includes("front-biens-no-cta.vercel.app") === true
			? "beta-testing"
			: "dev";

const currentUrl = branchState === "production"
	? "https://fiche.bevouac.com/"
	: branchState === "staging"
		? "https://front-biens.vercel.app/"
		: branchState === "beta-testing"
			? "https://front-biens-no-cta.vercel.app/"
			: "http://localhost:3000";



export default {
	NODE_ENV: process.env.NODE_ENV,
	BRANCH_STATE: branchState,
	CURRENT_URL: currentUrl,
	API_URL: "https://of07s5a98h.execute-api.eu-west-3.amazonaws.com/test-stage/informationsbien?",
	API_SIMULATION_URL: "https://of07s5a98h.execute-api.eu-west-3.amazonaws.com/test-stage/simulateur",
	REACT_APP_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	// API Générale avant jetons personnels - AIRTABLE_API_KEY: process.env.AIRTABLE_API_KEY || "keyqAwNwCawppf2Bm",
	//Personnel access token : keyJGIlbSb4y6o9Zn ou patEEMJIQYDvda7Kx.e36a8348411db5b67400ce9051d6f531f4b0dc82d074714a38e45235e91e1dbb
	//AIRTABLE_API_KEY: process.env.AIRTABLE_API_KEY || "keyQ0adJHVAknjg75", // API compte admin
	AIRTABLE_API_KEY: process.env.AIRTABLE_API_KEY || "patEEMJIQYDvda7Kx.e36a8348411db5b67400ce9051d6f531f4b0dc82d074714a38e45235e91e1dbb", // ACCES Token
	AIRTABLE_BASE: process.env.AIRTABLE_BASE || "appC3aVfQAATfSQUy",
	SIMULATION: {
		mise_depart_percent: 15, // 15% de la mise de départ
		taux_emprunt: 2.30,
		taux_assurance: 0.10,
	},
	SOCIAL_NETWORKS: {
		facebook: "https://www.facebook.com/bevouac/",
		linkedin: "https://www.linkedin.com/company/bevouac/",
		instagram: "https://www.instagram.com/bevouac.france/?hl=fr",
		youtube: "https://www.youtube.com/channel/UCr_c6XBC6hhu_Q0h0-U5EQg",
	},
}
