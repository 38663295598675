// Modules
import React from "react";
import PropTypes from "prop-types";

const StrategyOne = (props) => {
	const { clssName } = props;

	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 310 71"
			className={clssName}
			style={{ enableBackground: "new 0 0 310 71" }}
		>
			<g>
				<text
					transform="matrix(1 0 0 1 24.9697 32.8937)"
					style={{ fill: "var(--primary)", fontFamily: "var(--PlayfairMedium)", fontSize: "9.5283px" }}
				>
					Placement
				</text>
				<g>
					<path
						style={{ fill: "none", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
						d="M35.3,42.3c0,0-0.1-4.5,4.2-4.3s7.9-0.1,9.9-0.7c3.4-0.9,7.3-0.2,9.3,3.9c2.3,4.7,1.6,7.3-3.1,8.7 c-3.7,1-8,1.3-14.1-0.6C35.4,47.5,35.3,42.3,35.3,42.3z"
					/>
					<path
						style={{ fill: "none", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
						d="M39.4,42.8c0,0-0.1-3,2.8-2.8c2.8,0.1,5.3-0.1,6.6-0.4c2.2-0.6,4.9-0.1,6.2,2.6c1.5,3.1,1.1,4.9-2.1,5.8 c-2.5,0.7-5.3,0.9-9.4-0.4S39.4,42.8,39.4,42.8z"
					/>
					<path
						style={{ fill: "var(--primary)" }}
						d="M43.1,43.3c0,0,0-1.6,1.5-1.6c1.6,0.1,2.9-0.1,3.6-0.2c1.2-0.3,2.7-0.1,3.4,1.4c0.8,1.7,0.6,2.7-1.2,3.2 c-1.4,0.4-2.9,0.5-5.1-0.2S43.1,43.3,43.1,43.3z"
					/>
				</g>
			</g>
			<text
				transform="matrix(1 0 0 1 123.942 31.4172)"
				style={{ fill: "var(--primary)", fontFamily: "var(--PlayfairMedium)", fontSize: "13.2613px" }}
			>
				Équilibrée
			</text>
			<g>
				<path
					style={{ fill: "none", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
					d="M137.5,46.5c0,0-0.2-6.4,5.9-6.1c6.1,0.3,11.2-0.2,14.1-1c4.8-1.3,10.4-0.3,13.3,5.6 c3.3,6.7,2.3,10.4-4.5,12.3c-5.3,1.4-11.4,1.9-20-0.8C137.6,53.8,137.5,46.5,137.5,46.5z"
				/>
				<path
					style={{ fill: "var(--primary)" }}
					d="M143.4,46.9c0,0,0-4.3,4-3.9c4,0.3,7.5,0.1,9.4-0.4c3.2-0.8,6.9,0,8.7,4c2.1,4.5,1.4,7-3.2,8.1 c-3.5,0.9-7.6,1.1-13.3-0.9S143.4,46.9,143.4,46.9z"
				/>
				<path
					style={{ fill: "var(--white)" }}
					d="M148.6,47.9c0,0-0.1-2.3,2.2-2.2c2.2,0.1,4.1-0.1,5.1-0.4c1.8-0.5,3.8-0.1,4.9,2c1.2,2.4,0.9,3.8-1.6,4.5 c-1.9,0.5-4.2,0.7-7.3-0.3S148.6,47.9,148.6,47.9z"
				/>
			</g>
			<text
				transform="matrix(1 0 0 1 228.5666 32.7189)"
				style={{ fill: "var(--primary)", fontFamily: "var(--PlayfairMedium)", fontSize: "9.5283px" }}
			>
				Développement
			</text>
			<path
				style={{ fill: "var(--primary)" }}
				d="M249.8,43.5c0,0-0.1-4.6,4.3-4.4c4.4,0.2,8.1-0.1,10.1-0.7c3.4-0.9,7.5-0.2,9.5,4c2.3,4.8,1.7,7.5-3.2,8.8 c-3.8,1-8.2,1.4-14.4-0.6C249.9,48.8,249.8,43.5,249.8,43.5z"
			/>
			<path
				style={{ fill: "var(--white)", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
				d="M254,43.8c0,0,0-3.1,2.9-2.8s5.4,0,6.7-0.3c2.3-0.6,5,0,6.3,2.8c1.5,3.2,1,5-2.3,5.8c-2.5,0.6-5.5,0.8-9.6-0.6 C254,47.4,254,43.8,254,43.8z"
			/>
			<path
				style={{ fill: "var(--white)", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
				d="M257.8,44.5c0,0,0-1.7,1.6-1.6s2.9-0.1,3.7-0.3c1.3-0.3,2.7-0.1,3.5,1.5c0.9,1.8,0.6,2.7-1.2,3.2 c-1.4,0.4-3,0.5-5.3-0.2C257.8,46.5,257.8,44.5,257.8,44.5z"
			/>
			<rect
				x="2.5"
				y="16.6"
				style={{ fill: "none", stroke: "var(--primary)", strokeMiterlimit: "10" }}
				width="90.3"
				height="42.3" 
			/>
			<rect
				x="217.3"
				y="17.2"
				style={{ fill: "none", stroke: "var(--primary)", strokeMiterlimit: "10" }}
				width="90.3"
				height="42.3" 
			/>
			<rect
				x="92.7"
				y="7.1"
				style={{ fill: "none", stroke: "var(--orange)", strokeMiterlimit: "10" }}
				width="124.6"
				height="59.8" 
			/>
		</svg>
	);
};

StrategyOne.propTypes = {
	clssName: PropTypes.string,
};

StrategyOne.defaultProps = {
	clssName: "",
};

export default StrategyOne;
