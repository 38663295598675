// Modules
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// Local
import config from "../../config";

const MetaTags = ({
	route,
	component: Component,
	data,
	loading,
	id,
	proposeur,
	...props
}) => {

	const {
		descriptif_bien_2,
		m2_carrez,
		descriptif_bien_3,
		ville,
		resume,
	} = data;
	const [imageSEO, setImageSEO] = useState(`${config.env.CURRENT_URL}/static/media/logo_primary.png`);
	const currentUrl = window.location.href.includes("localhost") === true
		? `https://front-biens.vercel.app//${id}`
		: window.location.href.split("#")[0];
	const title = `${descriptif_bien_2} de ${m2_carrez}m², ${descriptif_bien_3} à ${ville}`;

	/**
	 * If we have a nice photo sent by the endpoint of the city,
	 * use it as meta data.
	 */
	useEffect(() => {
		if (data && data?.photo_agglo)
			setImageSEO(data?.photo_agglo);
	}, [data]);

	return (
		<Fragment>
			{config.seo[route] ? (
				<Helmet>
					{/* General */}
					<title>{config.seo[route].title}</title>
					<meta
						name="title"
						content={title}
					/>
					<meta
						name="description"
						content={resume}
					/>
					<meta
						name="robots"
						content={config.seo[route].robots.join(", ")}
					/>

					{/* Open Graph / Facebook */}
					<meta
						property="og:type"
						content={config.seo[route].type}
					/>
					<meta
						property="og:url"
						content={currentUrl}
					/>
					<meta
						property="og:title"
						content={title}
					/>
					<meta
						name="og:description"
						content={resume}
					/>
					<meta
						name="og:site_name"
						content="Bevouac"
					/>
					<meta
						property="og:image"
						itemprop="image"
						content={imageSEO}
					/>
					<meta
						property="og:image:secure_url"
						itemprop="image"
						content={imageSEO}
					/>
					<meta
						property="og:image:width"
						content="300"
					/>
					<meta
						property="og:image:height"
						content="300"
					/>
					<meta
						property="og:locale"
						content="fr_FR"
					/>
					{/* <meta
						property="fb:app_id"
						content={config.env.FACEBOOK_ID}
					/> */}

					{/* Twitter */}
					<meta
						name="twitter:card"
						content="summary_large_image"
					/>
					<meta
						name="twitter:site"
						content="@bevouac"
					/>
					<meta
						property="twitter:url"
						content={currentUrl}
					/>
					<meta
						property="twitter:title"
						content={title}
					/>
					<meta
						property="twitter:description"s
						content={resume}
					/>
					<meta
						name="twitter:image"
						content={imageSEO}
					/>
					<meta
						name="twitter:image:alt"
						content={ville}
					/>
				</Helmet>
			) : null}
			<Component
				data={data}
				loading={loading}
				idFiche={id}
				proposeur={proposeur}
				{...props}
			/>
		</Fragment>
	);
};

MetaTags.propTypes = {
	component: PropTypes.any.isRequired,
	route: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	proposeur: PropTypes.string,
};

MetaTags.defaultProps = {
	proposeur: null,
};

export default withRouter(MetaTags);
