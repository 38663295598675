// Modules
import React from "react";
import ReactDOM from "react-dom";
import { I18n } from "aws-amplify";

// Local
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";

/**
 * Translation of the AWS interface.
 */

I18n.setLanguage("fr");
const dict = {
  "fr": {
      "Sign In": "Se connecter",
      "Sign in": "Se connecter",
      "Sign Up": "S'inscrire",
      "Sign up": "S'inscrire",
      "Sign out": "Se déconnecter",
      "Sign Out": "Se déconnecter",
      "CREATE ACCOUNT": "S'inscrire",
      "create account": "S'inscrire",
      "Create account": "S'inscrire",
      "No account?":"Pas encore de compte ?",
      "Forgot your password?":"Mot de passe oublié ?",
      "Reset password":"Réinitialisation du mot de passe",
      "Reset your password":"Réinitialisation du mot de passe",
      "Enter your password":"Renseignez votre mot de passe",
      "Enter your username":"Renseignez votre email ou nom d'utilisateur",
      "Username *":"Nom d'utilisateur / mail",
      "Username":"Email",
      "Send Code": "Recevoir mon code",
      "Back to Sign In": "Retour à la connexion",
      "Sign in to your account": "Identifiez-vous",
      "Password *": "Mot de passe",
      "Email Address *":"Adresse email",
      "Create Account": "Créer mon compte",
      "Create a new account": "Créer un compte",
      "Password":"Mot de passe",
      "Phone Number *":"Numéro de téléphone",
      "Have an account?":"Déja inscrit ?",
      "Confirmation Code": "Code de vérification",
      "Enter your code":"Renseignez votre code",
      "Lost your code?":"Code perdu ?",
      "Resend code": "Me renvoyer le code",
      "Back to sign in":"Me connecter",
      "Confirm":"Valider",
      "Confirm Sign up":"Vérification de votre inscription",
      "Resend Code":"Renvoyer le code",
      "Custom auth lambda trigger is not configured for the user pool.": "Veuillez renseigner le mot de passe",
  },
};

I18n.putVocabularies(dict);
/* /!\ */
// console.log(I18n.get("Sign Up"));

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
