// Modules
import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

// Context
import ApiContext from "../../../contexts/ApiContext";

const Pills = (props) => {
	const {
		label,
		value,
		loader,
		clssName,
		def_info,
		isScrolling
	} = props;
	
	const [isHovering, setIsHovering] = useState(false);
	const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);

	const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	  ))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
		  color: theme.palette.common.black,
		},
		[`& .${tooltipClasses.tooltip}`]: {
		  backgroundColor: theme.palette.common.black,
		},
	  }));

	useEffect(() => {
		if (isScrolling === true && isHovering === true)
			setIsHovering(false);
	}, [isScrolling]); // eslint-disable-line

	
	return (
		<React.Fragment>
			<div className={`pills-container ${clssName}`}>
						{loader === true
					? (
						<Skeleton
							animation="wave"
							variant="text"
							width="10vh"
							className="label"
						/>
					) : (<div>
					{def_info === "" ? (
						<h6 className="label">{label}</h6>
						) : (
							<div className={`visible-text tooltip-header-elem`}>
								<h6 className="label">{label}</h6>
								
								<div
									className="bubble-info"
									onMouseEnter={() => {
										if (isMobile === true || isTablet === true || isSplitScreen === true)
											return;
										setIsHovering(true);
									}}
									onMouseLeave={() => {
										if (isMobile === true || isTablet === true || isSplitScreen === true)
											return;
										setIsHovering(false);
									}}
									onClick={() => {
										if (isMobile === false && isTablet === false)
											return;
										setIsHovering(!isHovering);
									}}
								>
									<span />
									?
									<div className={`tooltip left ${isHovering === true ? "hovered" : ""}`}>
										<div 
										onClick={() => {
											if (isMobile === false && isTablet === false)
												return;
											setIsHovering(false);
										}} 
										className="closeTooltip">x</div>
										<p>{def_info}</p>
									</div>
								</div>
							</div>
						)}
						</div>
						)
						}
				{loader === true
					? (
						<Skeleton
							animation="wave"
							variant="text"
							width="10vh"
							className="label"
						/>
					) : (
				<div className="frame">
					<p className="value">{value}</p>
				</div>)}
				</div>
		</React.Fragment>
		
	);
};
Pills.propTypes = {
	visibleText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltipText: PropTypes.string.isRequired,
	className: PropTypes.string,
	loader: PropTypes.bool,
	keyProp: PropTypes.string.isRequired,
	isScrolling: PropTypes.bool,
};

Pills.defaultProps = {
	visibleText: null,
	className: "",
	loader: false,
	isScrolling: false,
};

export default Pills;