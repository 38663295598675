// Modules
import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

// Assets
import "./checkbox.scss";
import CheckSvg from "../../../assets/img/svg/Checked.jsx";

const CustomCheckbox = (props) => {
	const {
		label,
		checked,
		name,
		onChange,
		// Can be either "transparent" or "colored"
		color,
		loader,
		clssName,
	} = props;

	return (
		<label className={`custom-checkbox ${color} ${clssName}`}>
			<input
				type="checkbox"
				checked={checked}
				onChange={onChange}
				name={name}
			/>
			<span className="box">
				<CheckSvg clssName="check" />
			</span>
			{label !== undefined && label === null && loader === true
				? (
					<Skeleton
						animation="wave"
						variant="text"
						width="5vh"
						className="custom-checkbox"
					/>
				) : label}
		</label>
	);
};

CustomCheckbox.propTypes = {
	label: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	color: PropTypes.oneOf(["transparent", "colored"]),
	clssName: PropTypes.string,
};

CustomCheckbox.defaultProps = {
	color: "transparent",
	name: "checkbox",
	clssName: "",
};

export default CustomCheckbox;