// Modules
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import 'react-slideshow-image/dist/styles.css'

// Context
import ApiContext from "../../contexts/ApiContext";

// Local
import Divider from "../utils/Divider";
import Chip from '@mui/material/Chip';
import { Grid } from "@material-ui/core";
import Tooltip from "../utils/Tooltip";

const CoProperty = (props) => {
	const { data, loader } = props;
	let {
		annee_construction,
		ascenseur,
		balcon,
		cave,
		chauffage,
		double_vitrage,
		lots_copro,
		terrasse,
		etage_bien_bati,
		stationnement,
		dpe,
		idFiche,
		num_fiche,
	} = data;

	const contentIframe = (
		<iframe
		  title="view-3d"
		  width="100%"
		  height="100%"
		  className="iframe-matterport"
		  src={data["visite virtuelle"]}
		  frameBorder="0"
		  allowFullScreen
		/>
		);

	ascenseur = ascenseur === true ? "Oui" : "Non";
	balcon = balcon === true ? "Oui" : "Non";
	cave = cave === true ? "Oui" : "Non";
	terrasse = terrasse === true ? "Oui" : "Non";
	dpe = dpe != null ? dpe : "Non";
	const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);

	const coproRows = [
		{
			name: "Copropriété",
			value: `${lots_copro} lots`,
			tooltip: "default",
		},
		{
			name: "Étage",
			value: etage_bien_bati,
			tooltip: "default",
		},
		{
			name: "Année de construction",
			value: annee_construction,
			tooltip: "default",
		},
		{
			name: "Double vitrage",
			value: double_vitrage,
			tooltip: "default",
		},
		{
			name: "Chauffage",
			value: chauffage,
			tooltip: "default",
		},

		{
			name: "Balcon",
			value: balcon,
			tooltip: "default",
		},
		{
			name: "Terrasse",
			value: terrasse,
			tooltip: "default",
		},
		{
			name: "Ascenseur",
			value: ascenseur,
			tooltip: "default",
		},
		{
			name: "Cave",
			value: cave,
			tooltip: "default",
		},
		{
			name: "Stationnement",
			value: stationnement,
			tooltip: "default",
		},
		{
			name: "DPE du bien",
			value: dpe,
			tooltip: "Pour les diagnostics énergétiques notés F ou G, Bevouac vous conseille et vous accompagne dans vos travaux de rénovation afin d'atteindre un DPE E minimum.",
		},
	];

	const StyledChip = withStyles({
		root: {
		  backgroundColor: "rgb(213,228,255)",// here you can do anything actually 
		  marginLeft:"1vh",

		  marginBottom:"1vh",
	

		},
		label: {
		  color: "rgb(28,54,157)",
		  fontSize:"14px",
		  margin:"1vh",
		  fontFamily: "OpenSans Regular",
		},
	  })(Chip);

	  const StyledChipNon = withStyles({
		root: {
			backgroundColor:"rgb(230,230,230)",
	
		  marginLeft:"1vh",
	
		  marginBottom:"1vh",
		  

		  
	
		},
		label: {
		  color: "gray",
		  fontSize:"2vh",
		  margin:"1vw",
		  fontFamily: "OpenSans Regular",
		},
	  })(Chip);

	return (
		<React.Fragment>
			<Grid container id="terrainanalysis">
			{data["visite virtuelle"] === null
				? (
					null
				) : 
				<Grid item xs={12}>
					<Divider text="Visite Virtuelle" loader={loader} />
				</Grid>
			}
			{data["visite virtuelle"] === null
				? (
					null
				) : 
				<Grid id="frame-3d" container alignItems="center" justifyContent="center" direction="column" style={{backgroundColor: "white" , width:"100%",}}>
					<Grid item xs={12} style={{ minHeight:"60vh",height:"60vh", width:"50%"}}>{contentIframe}</Grid>
				</Grid>
			}
			<div class="divider-container" id="apropos">
				<div class="text-wrapper">
					<h2 class="name">A propos du bien</h2>
				</div>
			</div>

			<Grid container style={(num_fiche === "Le-32-2226-22-050409") ? { display: "flex" } : { display: "none" }}>
				
					<Grid item xs={3}></Grid>
					<Grid item xs={6}>
					<span>
					La ville de Lille est soumise à une régulation des loyers et cet appartement est actuellement occupé par un locataire qui paie un loyer supérieur au plafond imposé.
					</span>
					</Grid>
					<Grid item xs={3}></Grid>
				</Grid>
				<Grid container>
				
					<Grid item xs={3}></Grid>
					<Grid item xs={6} className="grid-50" >
					{coproRows.map((equipement, index) => (
								equipement.value=="Non" ?
								null :
								<span className={`copro copro-${index}`}>
									{equipement.name} : <strong className="copro-value">{equipement.value}</strong>
									<strong style={(dpe === "G") || (dpe === "F" ) ? { display: "inline-block" } : { display: "none" }}> 
									<span style={(equipement.tooltip === "default") ? { display: "none" } : { display: "inline-block" }}>
									<Tooltip 
											tooltipText={equipement.tooltip}
											overlay={equipement.tooltip}
											loader={loader}
											keyProp={equipement.tooltip}
											className="tooltip-header-elem"
										/>
									</span>
									</strong>
								</span>
								))}
					{coproRows.map((equipement, index) => (
								equipement.value=="Non" ?
								<span className={`copro-non copro-${index}`}>{equipement.name}</span> :
								null
								))}
					</Grid>
					<Grid item xs={3}></Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

CoProperty.propTypes = {
	data: PropTypes.object,
	loader: PropTypes.bool.isRequired,
};

CoProperty.defaultProps = {
	data: {},
};

export default CoProperty;