// Modules
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Slider from "@material-ui/core/Slider";
import NumberFormat from "react-number-format";

// Context
import ApiContext from "../../../contexts/ApiContext";

// Local
import config from "../../../config/environment";

// Assets
import Grid from "@mui/material/Grid";


/**
 * Computes a thousands rounded value,
 * used for the "mise de depart" field
 * @param {Number} : x (value to round)
 * @param {Number} : y (how to round 10/100/1000 etc)
 * @returns {String} : relative time from now
 */
function numRoundMultiple(x, y) {
	return Math.round(x / y) * y;
};

const Simulator = (props) => {
	const {
		valuesSimulation,
		setValuesSimulation,
		stateSwitch,
		setStateSwitch,
		total_acquisition,
		setValuesAPI,
		valuesAPI,
	} = props;
	const {
		// frais_courtage,
		mise_depart,
		duree_emprunt,
		duree_revente,
		taux_emprunt,
	} = valuesSimulation;
	const {
		//fraisCourtage,
		/* V2 or V3 */
		// effortEpargne,
		// equilibre
	} = stateSwitch;
	const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);
	const [isOpen, setIsOpen] = useState(false);

	/* Switch states */
	const [minMaxTE, setMinMaxTE] = useState({ min: 1.00, max: 5.00 }); // Taux d'emprunt
	const [minMD, setMinMD] = useState(0); // Mise de départ
	const [inputState, setInputState] = useState({
		error: false,
		message: null,
	});

	/**
	 * Changes the value minimum & maximum of the slider
	 * "Taux d'emprunt" when "Duree d'emprunt" is changed.
	 */
	 const [state, setState] = useState({
		taux_emprunt: 0
	  });
	 

	useEffect(() => {
		switch (taux_emprunt) {
			default:
				setState({ ...state, taux_emprunt: 0 });
				break;
		}
	}, [taux_emprunt]);

	/**
	 * Changes the minimum value of the slider "Mise de départ"
	 * when "total_acquisition" is defined.
	 */
	useEffect(() => {
		if (total_acquisition) {
			setMinMD(numRoundMultiple(0.75 * ((total_acquisition / 100) * config.SIMULATION.mise_depart_percent), 1000));

			// In case the user sets the slider "Mise de départ" equal to "Budget total du projet", the "frais de courtage" 
			// becomes null. So we have to update the value entered to match the new total.
			if (mise_depart > total_acquisition)
				handleChangeInputMD({ value: total_acquisition });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [total_acquisition]);

	/**
	* Callback function from Material UI that is fired when the slider's value changed.
	* @param {Event} event
	* @param {String} name : name of the key that changed in the "valuesSimulation" object.
	* @param {String} newValue : new value to change in the "valuesSimulation" object.
	*/
	const handleChangeSliders = (name) => (event, newValue) => {
		setValuesSimulation({
			...valuesSimulation,
			[name]: newValue,
		});
	};

	/**
	 * Handles the change of state for switch
	 * @param {Event} event
	 * @param {String} name : name of the key to change from the "stateSwitch" object
	 */
	const handleChangeSwitch = (event, name) => {
		// If the switch is off for "frais_courtage", we change its value to 0 for an impact in the results.
		if (name === "fraisCourtage") {
			if (stateSwitch.fraisCourtage === true) {
				// setValuesSimulation({ ...valuesSimulation, frais_courtage: 0 });
				setValuesAPI({ ...valuesAPI, frais_courtage: 0 });
			}
			else if (stateSwitch.fraisCourtage === false) {
				// setValuesSimulation({ ...valuesSimulation, frais_courtage: 2000 });
				setValuesAPI({ ...valuesAPI, frais_courtage: valuesAPI.frais_courtage });
			}
		}
		setStateSwitch({
			...stateSwitch,
			[name]: !stateSwitch[name],
		});
	};

	/**
	* Function fired by Material UI which returns a string value that provides
	* a user-friendly name for the current value of the slider.
	* @param {Number} value : The thumb label's value to format.
	* @param {Number} index : The thumb label's index to format.
	* @returns {String} : value formatted with €
	*/
	const parseValue = (value, index) => {
		return `${new Intl.NumberFormat("fr-FR", {
			style: "currency",
			currency: "EUR",
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		}).format(value)}`;
	};

	/**
	 * Handle the change of value for the "Mise de départ" input, next to the slider.
	 * @param {Object} valueObj : value object sent by NumberFormat
	 */

	const handleChangeInputMD = (valueObj) => {
		const newValue = parseInt(valueObj.value);

		if (newValue < minMD)
			setInputState({ error: true, message: "Mise de départ faible, moins susceptible d’être acceptée par les banques" });
		else
			setInputState({ error: false, message: "Mise de départ dans la norme du marché" });

		setValuesSimulation({ ...valuesSimulation, mise_depart: newValue});
	};



	/**
	 * Handle the change of value for the "Durée de revente" input, next to the slider.
	 * @param {Object} valueObj : value object sent by NumberFormat
	 */
	

	const handleChangeInputDR = (name) => (event, newValue) => {

		setValuesSimulation({ ...valuesSimulation, [name]: newValue });
		//setValuesAPI({ ...valuesAPI, [name]: newValue });
	};

	const toggleOpenSimulator = () => {

		setIsOpen(!isOpen);
	};

	const mise_depart_percent2 = parseInt(mise_depart*100/total_acquisition) + 1;

	return (
		
		<div className={`balance-sheet-simulation-container1 ${isMobile === true || isTablet === true || isSplitScreen === true ? "mobile" : ""} ${isTablet === true || isSplitScreen === true ? "tablet" : ""}`}>
		{/* Sliders */}
		<Grid container spacing={3} className="sliders-container1" >
						<Grid item xs={4} className="slider-container1"   >
							<h6><label for="mise-depart">Mise de départ</label> <NumberFormat
									className="input-number"
									value={mise_depart}
									type="text" 
									id="mise-depart" 
									onValueChange={handleChangeInputMD}
									allowNegative={false}
									thousandSeparator=" "
									allowedDecimalSeparators={[".", ","]}
									suffix={" €"}
								/></h6>
							<div className="slider-wrapper">
								{/* Input number */}
								
								<Slider
									value={mise_depart}
									name="mise_depart"
									valueLabelDisplay="auto"
									valueLabelFormat={parseValue}
									min={minMD}
									max={total_acquisition ?? 0}
									step={1000}
									onChange={handleChangeSliders("mise_depart")}
									aria-label="Mise de départ"
									aria-labelledby="continuous-slider"
									className="slider mise-de-depart"
								/>
								<Grid container direction="row" alignItems="center">
									<Grid item container xs={11}>
										<Grid item xs={7} className="inside-section" style={{fontSize:"1.5vh"}}>
										<span class="percent">({mise_depart_percent2} %)</span>
										<NumberFormat
												className="input-number"
												value={mise_depart}
												type="text"
												onValueChange={handleChangeInputMD}
												allowNegative={false}
												thousandSeparator=" "
												allowedDecimalSeparators={[".", ","]}
												suffix={" €"}
											/>
										</Grid>
										<Grid item xs={4} className="inside-section" style={{paddingTop:"1%",fontSize:"1.5vh",textAlign:"right"}}>
											{new Intl.NumberFormat("fr-FR", {
												style: "currency",
												currency: "EUR",
												maximumFractionDigits: 0,
												minimumFractionDigits: 0,
											}).format(total_acquisition ?? 0)} 
										</Grid>
									</Grid>
								</Grid>
							</div>
						</Grid>

						<Grid item xs={4} className="slider-container1">
							<h6><label for="duree-emprunt">Durée d'emprunt</label> <NumberFormat
									className="input-number"
									value={duree_emprunt}
									type="text" 
									id="duree-emprunt" 
									onValueChange={handleChangeInputDR("duree_emprunt")}
									allowNegative={false}
									thousandSeparator=" "
									allowedDecimalSeparators={[".", ","]}
									suffix={" ans"}
								/></h6>
							<div className="slider-wrapper">
								<Slider
									value={duree_emprunt}
									name="duree_emprunt"
									getAriaValueText={parseValue}
									valueLabelFormat={`${duree_emprunt} ans`}
									valueLabelDisplay="auto"
									min={5}
									max={25}
									marks
									step={5}
									onChange={handleChangeSliders("duree_emprunt")}
									aria-label="Durée emprunt"
									aria-labelledby="continuous-slider"
									className="slider mise-de-depart"
								/>
								<Grid container direction="row" alignItems="center">
									<Grid item container xs={11}>
										<Grid item xs={7} className="inside-section" style={{fontSize:"1.5vh"}}>
										5 ans
										</Grid>
										<Grid item xs={4} className="inside-section" style={{paddingTop:"1%",fontSize:"1.5vh",textAlign:"right"}}>
											25 ans
										</Grid>
									</Grid>
								</Grid>
							</div>
						</Grid>
						
						<Grid item xs={4} className="slider-container1" >
							<h6>
								<label for="taux-emprunt">Taux d'emprunt<span style={{display: 'none'}}>{taux_emprunt}</span></label>
								
									<NumberFormat
									className="input-number"
									value={taux_emprunt}
									type="text" 
									id="taux-emprunt" 
									onValueChange={handleChangeInputDR("taux_emprunt")}
									allowNegative={false}
									thousandSeparator=" "
									allowedDecimalSeparators={[".", ","]}
									suffix={" %"}
								/>
								
							</h6>
							<div className="slider-wrapper">
									<Slider
									value={`${taux_emprunt}`}
									defaultValue={2.30}
									getAriaValueText={`${taux_emprunt} %`}
									valueLabelFormat={`${taux_emprunt} %`}
									name="taux_emprunt"
									getAriaValueText={parseValue}
									valueLabelDisplay="auto"
									min={minMaxTE.min}
									step={0.10}
									max={5}
									onChange={handleChangeSliders("taux_emprunt")}
									aria-label="Taux emprunt"
									aria-labelledby="continuous-slider"
									className="slider mise-de-depart"
								/>
									

								
								<Grid container direction="row" alignItems="center">
									<Grid item container xs={11}>
										<Grid item xs={7} className="inside-section" style={{fontSize:"1.5vh"}}>
											1%
										</Grid>
										<Grid item xs={4} className="inside-section" style={{paddingTop:"1%",fontSize:"1.5vh",textAlign:"right"}}>
											5%
										</Grid>
									</Grid>
								</Grid>
							</div>
						</Grid>

						

						

						<Grid item xs={4} className="slider-container1" >
							<h6><label for="revente-prevue">Revente prévue dans :</label> <NumberFormat
									className="input-number"
									value={duree_revente}
									type="text" 
									id="revente-prevue" 
									onValueChange={handleChangeInputDR}
									allowNegative={false}
									thousandSeparator=" "
									allowedDecimalSeparators={[".", ","]}
									suffix={" ans"}
								/></h6>
							<div className="slider-wrapper">
							
								<Slider
									value={duree_revente}
									name="duree_revente"
									getAriaValueText={parseValue}
									valueLabelFormat={`${duree_revente} ans`}
									valueLabelDisplay="auto"
									min={0}
									max={50}
									onChange={handleChangeSliders("duree_revente")}
									aria-label="Revente dans"
									aria-labelledby="continuous-slider"
									className="slider mise-de-depart"
								/>
								<Grid container direction="row" alignItems="center">
									<Grid item container xs={11}>
										<Grid item xs={7} className="inside-section" style={{fontSize:"1.5vh"}}>
											<NumberFormat
												className="input-number"
												value={duree_revente}
												type="text"
												onValueChange={handleChangeInputDR}
												allowNegative={false}
												thousandSeparator=" "
												allowedDecimalSeparators={[".", ","]}
												suffix={" ans"}
											/>
										</Grid>
										<Grid item xs={4} className="inside-section" style={{paddingTop:"1%",fontSize:"1.5vh",textAlign:"right"}}>
											50 ans
										</Grid>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>
				</div>
			
	);
};

Simulator.propTypes = {
	loader: PropTypes.bool.isRequired,
	valuesSimulation: PropTypes.exact({
		mise_depart: PropTypes.number.isRequired,
		duree_emprunt: PropTypes.number.isRequired,
		taux_emprunt: PropTypes.number.isRequired,
		taux_emprunt_recommande: PropTypes.number.isRequired,
		taux_assurance: PropTypes.number.isRequired,
		duree_revente: PropTypes.number.isRequired,
	}),
	setValuesSimulation: PropTypes.func.isRequired,
	stateSwitch: PropTypes.exact({
		fraisCourtage: PropTypes.bool.isRequired,
		effortEpargne: PropTypes.bool.isRequired,
		equilibre: PropTypes.bool.isRequired,
	}),
	setStateSwitch: PropTypes.func.isRequired,
};

export default Simulator;