/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

import env from "./environment";
import seo from "./seo.jsx";
import galleryNeighborhood from "./neighborhood_images";
import galleryHomeImprovments from "./construction_images_V1";

export default {
	env,
	seo,
	galleryNeighborhood,
	galleryHomeImprovments,
};
