import React from "react";
import PropTypes from "prop-types";

const Erreur404 = (props) => {
	const {
		clssName,
	} = props;

	return (
	<svg 
		version="1.1"
		xmlns="http://www.w3.org/2000/svg" 
		x="0px" 
		y="0px"
		viewBox="0 0 200 98" 
		style={{enableBackground: "new 0 0 200 98"}}
		className={clssName}
	>
		<path 
			fill="none" 
			d="M107.9,26.6c-2.6-2.1-5.4-3.2-8.5-3.2c-4.7,0-8.6,3-11.6,9c-3,6-4.5,14.4-4.5,25c0,7.9,0.8,14.2,2.4,19.1
			c1.6,4.8,3.7,8.3,6.2,10.5c2.6,2.2,5.4,3.3,8.5,3.3c4.7,0,8.6-3,11.6-9.1c3-6,4.5-14.4,4.5-25.1c0-7.9-0.8-14.2-2.4-19.1
			C112.5,32.3,110.4,28.8,107.9,26.6z"
		/>
		<path 
			fill="var(--primary)" 
			d="M62.2,50L62.2,50c-0.4,2.2-1,3.7-2,4.6c-1,0.9-3.2,1.3-6.8,1.3h-1.1V4.4h-2.1l-49,62.3v2.8h35.9v23.3h15.3V69.5
			c2.9,0,5.6,0.1,8,0.2c1.1,0,2.1,0.1,2.9,0.1c-1.2-3.6-1.8-7.6-1.8-12C61.5,55.1,61.8,52.5,62.2,50z M37.1,55.9H13.3l23.9-30.6V55.9z
			"
		/>
		<path 
			fill="var(--primary)"
			d="M149.7,4.4h-2.1v51.5h-1.1c-3.6,0-5.9-0.4-6.8-1.3c-1-0.9-1.6-2.4-2-4.6h0c0.3,1.9,0.4,3.8,0.4,5.8
			c0,5.1-0.8,9.8-2.5,14.2c0.9-0.1,2.2-0.2,3.9-0.2c2.4-0.1,5.1-0.1,8-0.2v23.2h15.3V69.4h35.9v-2.8L149.7,4.4z M162.9,55.9V25.3
			l23.9,30.6H162.9z"
		/>
		<path 
			fill="var(--primary)" 
			d="M135.9,50c-0.6-4.6-2-8.8-4.2-12.6c-3.1-5.2-7.3-9.2-12.6-12.1c-5.3-2.8-11.5-4.3-18.5-4.3
			c-7.4,0-14,1.6-19.6,4.9c-5.6,3.2-9.9,7.6-13,13.2c-1.9,3.4-3.1,7-3.9,10.8c-0.5,2.5-0.7,5.2-0.7,7.9c0,4.3,0.6,8.3,1.8,12
			c0.7,2.3,1.7,4.4,2.9,6.4c3.1,5.2,7.4,9.3,12.8,12.2c5.4,2.9,11.5,4.3,18.3,4.3c7.4,0,14-1.6,19.5-4.9c5.6-3.2,9.9-7.6,13-13.2
			c0.8-1.5,1.6-3.1,2.2-4.7c1.6-4.3,2.5-9.1,2.5-14.2C136.3,53.8,136.2,51.8,135.9,50z M111.9,81.3c-3,6-6.9,9.1-11.6,9.1
			c-3.1,0-5.9-1.1-8.5-3.3c-2.6-2.2-4.7-5.7-6.2-10.5c-1.6-4.8-2.4-11.2-2.4-19.1c0-10.7,1.5-19,4.5-25c3-6,6.9-9,11.6-9
			c3.1,0,5.9,1.1,8.5,3.2c2.6,2.1,4.7,5.6,6.2,10.5c1.6,4.8,2.4,11.2,2.4,19.1C116.5,66.8,115,75.2,111.9,81.3z"
		/>
	</svg>
	);
};

Erreur404.propTypes = {
	clssName: PropTypes.string,
};

Erreur404.defaultProps = {
	clssName: "",
};

export default Erreur404;
