/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

export default {
	// Listes des pièces : Salon, Chambres, Salle de bain, Cuisine
	// Liste des types : Studio, T1, T2, T3, T4, T5

	// SALON
	salon: {
		Studio: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salon/Nancy%20studio%20-%20salon%20_%20chambre.jpg",
		T1: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salon/Nancy%20studio%20-%20salon%20_%20chambre.jpg",
		T2: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salon/Nancy%20-%20T2%20-%20salon%20_%20cuisine.jpg",
		T3: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salon/Nancy%20-%20T2%20-%20salon%20_%20cuisine.jpg",
		T4: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salon/Nancy%20-%20T5%20-%20salon_1.jpg",
		T5: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salon/Nancy%20-%20T5%20-%20salon_1.jpg",
	},

	// C°UISINE
	cuisine: {
		Studio: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Cuisine/Lille%20T2.jpg",
		T1: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Cuisine/Lille%20T2.jpg",
		T2: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Cuisine/Lille%20T2.jpg",
		T3: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Cuisine/Nancy%20-%20T4.jpg",
		T4: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Cuisine/Nancy%20-%20T4.jpg",
		T5: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Cuisine/Nancy%20-%20T4.jpg",
	},

	// SALLE DE BAIN
	salle_de_bain: {
		Studio: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salle%20de%20bain/Lille%20-%20T2.jpg",
		T1: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salle%20de%20bain/Lille%20-%20T2.jpg",
		T2: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salle%20de%20bain/Lille%20-%20T2.jpg",
		T3: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salle%20de%20bain/Marseille%20-%20T4.jpg",
		T4: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salle%20de%20bain/Marseille%20-%20T4.jpg",
		T5: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salle%20de%20bain/Marseille%20-%20T4.jpg",
	},

	// CHAMBRE
	chambre: {
		Studio: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salon/Nancy%20studio%20-%20salon%20_%20chambre.jpg",
		T1: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Salon/Nancy%20studio%20-%20salon%20_%20chambre.jpg",
		T2: "https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Lille%20-%20T2.jpg",
		T3: [
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Lille%20-%20T2.jpg",
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Nancy%20-%20T2.jpg",
		],
		T4: [
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Lille%20-%20T2.jpg",
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Nancy%20-%20T2.jpg",
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Marseille%20-%20T4%20-%203%20chambres/Marseille%20-%20T4_1.jpg",
		],
		T5: [
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Lille%20-%20T2.jpg",
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Nancy%20-%20T2.jpg",
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Marseille%20-%20T4%20-%203%20chambres/Marseille%20-%20T4_1.jpg",
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Nancy%20-%20T5%20-%202%20chambres/Nancy%20-%20T5_1.jpg",
			"https://bevouac-ressources-visuelles.s3.amazonaws.com/Chambres/Nancy%20-%20T4%20-%202%20chambres/Nancy%20-%20T4.jpg",
		],
	}
}