// Modules
import React, { useEffect, useState, useContext } from "react";
import { Auth } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "react-modal";
import LogRocket from "logrocket";

// Context
import ApiContext from "../../contexts/ApiContext";

// Local
import UserLogged from "./UserLogged";
import CloseIcon from "../../assets/img/svg/Close_icon.jsx";
import config from "../../config/";


/* Amplify error message mapping for users*/
const errorMap = (message) => {
  if (/detected.*username.*constraint/i.test(message)) {
    return "Nom d'utilisateur mal formé : celui ci ne doit pas contenir d'espace";
  }
  else if (/incorrect.*username.*password/i.test(message)) {
    return 'Mot de passe ou email incorrect';
  }
  else if (/invalid.*address.*format/i.test(message)) {
    return 'Veillez saisir un email au bon format';
  }
  else if (/Username.*pool.*alias/i.test(message)) {
    return "Votre nom d'utilisateur ne peut être un email, utilisez votre prénom ou un pseudonyme";
  }
  else if (/Password.*conform.*policy/i.test(message)) {
    return "Votre mot de passe doit contenir au minimum 8 symboles dont: 1 chiffre, 1 caractère spécial, une minuscule, une majuscule";
  }
  else if (/validation.*error.*constraint/i.test(message)) {
    return "Votre mot de passe doit contenir au minimum 8 symboles dont: 1 chiffre, 1 caractère spécial, une minuscule, une majuscule";
  }
  else if (/validation.*username.*constraint/i.test(message)) {
    return "Votre nom d'utilisateur doit contenir plus de 6 caractères";
  }

  return message;
}


const propsCtx = React.createContext();

const LoginHome = () => {
  const props = React.useContext(propsCtx);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggued, setIsLoggued] = useState(false);

  

  return (
    <div className="logged-in-container" style={isLoggued === false ? { display: "none" } : { display: "flex" }}>
      {isLoading === true
        ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : <UserLogged id_fiche={props.id_fiche} awsUserMail={props.aws_user_mail} />}
    </div>
  );
};

// const AuthenticatedPage = withAuthenticator(LoginHome);

const MyPage = (props) => {
  const {
    showLogin,
    handleShowLogin,
    fiche,
    id_fiche,
  } = props;
  const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);
  const [aws_user_mail, setaws_user_mail] = useState("");
  const [allProps, setAllProps] = useState({ fiche, id_fiche, aws_user_mail, handleShowLogin });
  const [error, setError] = useState(null); // eslint-disable-line
  const [message, setMessage] = useState(null);


  const customSignUpFields = {
    header: "Créer un compte",
    signUpFields: [
      {
        key: "username",
        displayOrder: 1,
        type: "string",
        label: "Nom d'utilisateur",
        placeholder: "Nom d'utilisateur",
        required: true,
        custom: false,
      },
      {
        key: "email",
        displayOrder: 2,
        type: "string",
        label: "Email",
        placeholder: "Email",
        required: true,
        custom: false,
      },
      {
        key: "password",
        displayOrder: 3,
        type: "password",
        label: "Mot de passe",
        placeholder: "Mot de passe",
        required: true,
        custom: false,
      }
    ],
    defaultCountryCode: "33",
    hideAllDefaults: true,
  };
  Modal.setAppElement("#root");

  const getUserInfos = async () => {

    Auth.currentUserInfo()
      .then((result) => {

        // console.log(result);
        if (result !== null && result?.attributes?.email) {
          setaws_user_mail(result.attributes.email);

          if (config.env.BRANCH_STATE === "production")
            LogRocket.identify(result.attributes.email, {
              name: result.username || result.attributes.email,
              email: result.attributes.email,
            });
        }
      })
      .catch((err) => {
        console.error("Couldn't retrived user informations", err);

        if (err.message.includes("Cannot read property '_run' of undefined") === false)
          setaws_user_mail("none");
      });
  };

  const onAuthEvent = (data) => {
    getUserInfos();

    switch (data) {
      case "signIn":
        setError(null);
        setMessage(null);
        break;
      case "signIn_failure":
        setMessage(null);
        setError("Nom d'utilisateur ou mot de passe incorrect.");
        break;
      case "forgotPassword":
        setError(null);
        setMessage(null);
        break;
      case "forgotPassword_failure":
        setError("");
        setMessage(null);
        break;
      case "forgotPasswordSubmit_failure":
        setError("Code de vérification non valide ou mot de passe trop faible. Prérequis: minimum 8 charactères, 1 majuscule, 1 minuscule, 1 charactère spécial, 1 chiffre.");
        setMessage(null);
        break;
      case "forgotPasswordSubmit":
        setError(null);
        setMessage(null);
        break;
      case "signUp":
        setError(null);
        setMessage(null);
        break;
      case "confirmSignUp":
        setMessage("Votre code de vérification vient de vous être envoyé par mail.");
        break;
      case "signOut":
        setError(null);
        setMessage(null);
        break;
      default:
        setError(null);
        setMessage(null);
        break;
    }
  };

  useEffect(() => {
    setAllProps({ fiche, id_fiche, aws_user_mail, handleShowLogin });
  }, [aws_user_mail, fiche, id_fiche, handleShowLogin]);

  return (
    <Modal
      isOpen={showLogin}
      onRequestClose={handleShowLogin}
      className={`
        amplify-auth-modal 
        ${aws_user_mail !== "" && aws_user_mail !== "none" ? "loggued" : ""} 
        ${isMobile === true || isTablet === true || isSplitScreen === true ? "mobile" : ""}
        ${isTablet === true || isSplitScreen === true ? "tablet" : ""}
      `}
    >
      <div className="close-wrapper">
        <CloseIcon
          clssName="close-icon"
          onClick={handleShowLogin}
        />
      </div>
      {/*error !== null ||*/ message !== null
        ? (
          <div className={`toast-error-wrapper ${message !== null ? "message" : ""}`}>
            <p>{/*error*/message}</p>
          </div>
        ) : null}
     
    </Modal>
  );
};

export default MyPage;