// Modules
import React from "react";
import PropTypes from "prop-types";

const StrategyOne = (props) => {
	const { clssName } = props;

	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 310 71"
			className={clssName}
			style={{ enableBackground: "new 0 0 310 71" }}
		>
			<g>
				<text
					transform="matrix(1 0 0 1 25.9158 32.7571)"
					style={{ fill: "var(--primary)", fontFamily: "var(--PlayfairMedium)", fontSize: "9.5283px" }}
				>
					Placement
				</text>
				<g>
					<path
						style={{ fill: "none", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
						d="M36.2,42.2c0,0-0.1-4.5,4.2-4.3s7.9-0.1,9.9-0.7c3.4-0.9,7.3-0.2,9.3,3.9c2.3,4.7,1.6,7.3-3.1,8.7 c-3.7,1-8,1.3-14.1-0.6C36.3,47.3,36.2,42.2,36.2,42.2z"
					/>
					<path
						style={{ fill: "none", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
						d="M40.3,42.7c0,0-0.1-3,2.8-2.8c2.8,0.1,5.3-0.1,6.6-0.4c2.2-0.6,4.9-0.1,6.2,2.6c1.5,3.1,1.1,4.9-2.1,5.8 c-2.5,0.7-5.3,0.9-9.4-0.4S40.3,42.7,40.3,42.7z"
					/>
					<path
						style={{ fill: "var(--primary)" }}
						d="M44,43.2c0,0,0-1.6,1.5-1.6s2.9-0.1,3.6-0.2c1.2-0.3,2.7-0.1,3.4,1.4c0.8,1.7,0.6,2.7-1.2,3.2 c-1.4,0.4-2.9,0.5-5.1-0.2C44.1,45.1,44,43.2,44,43.2z"
					/>
				</g>
			</g>
			<text
				transform="matrix(1 0 0 1 199.0954 31.9508)"
				style={{ fill: "var(--primary)", fontFamily: "var(--PlayfairMedium)", fontSize: "13.2613px" }}
			>
				Développement
			</text>
			<g>
				<path
					style={{ fill: "var(--primary)" }}
					d="M229.4,46.8c0,0-0.2-6.4,5.9-6.1c6.1,0.3,11.2-0.2,14.1-1c4.8-1.3,10.4-0.3,13.3,5.6 c3.3,6.7,2.3,10.4-4.5,12.3c-5.3,1.4-11.4,1.9-20-0.8S229.4,46.8,229.4,46.8z"
				/>
				<path
					style={{ fill: "var(--white)", stroke: "var(--primary)", strokeWidth:"0.5", strokeMiterlimit: "10" }}
					d="M235.3,47.3c0,0,0-4.3,4-3.9c4,0.3,7.5,0.1,9.4-0.4c3.2-0.8,6.9,0,8.7,4c2.1,4.5,1.4,7-3.2,8.1 c-3.5,0.9-7.6,1.1-13.3-0.9C235.3,52.2,235.3,47.3,235.3,47.3z"
				/>
				<path
					style={{ fill: "var(--white)", stroke: "var(--primary)", strokeWidth:"0.5", strokeMiterlimit: "10" }}
					d="M240.6,48.3c0,0-0.1-2.3,2.2-2.2s4.1-0.1,5.1-0.4c1.8-0.5,3.8-0.1,4.9,2c1.2,2.4,0.9,3.8-1.6,4.5 c-1.9,0.5-4.2,0.7-7.3-0.3C240.6,50.9,240.6,48.3,240.6,48.3z"
				/>
			</g>
			<g>
				<text
					transform="matrix(1 0 0 1 118.2385 32.8906)"
					style={{ fill: "var(--primary)", fontFamily: "var(--PlayfairMedium)", fontSize: "9.5283px" }}
				>
					Équilibrée
				</text>
				<g>
					<path
						style={{ fill: "none", stroke: "var(--primary)", strokeWidth: "0.5", strokeMiterlimit: "10" }}
						d="M126.5,42.8c0,0-0.1-4.6,4.3-4.4c4.4,0.2,8.1-0.1,10.1-0.7c3.4-0.9,7.5-0.2,9.5,4c2.3,4.8,1.7,7.5-3.2,8.8 c-3.8,1-8.2,1.4-14.4-0.6C126.6,48.1,126.5,42.8,126.5,42.8z"
					/>
					<path
						style={{ fill: "var(--primary)" }}
						d="M130.7,43.1c0,0,0-3.1,2.9-2.8c2.9,0.2,5.4,0,6.7-0.3c2.3-0.6,5,0,6.3,2.8c1.5,3.2,1,5-2.3,5.8 c-2.5,0.6-5.5,0.8-9.6-0.6S130.7,43.1,130.7,43.1z"
					/>
					<path
						style={{ fill: "var(--white)", stroke: "var(--primary)", strokeWidth:"0.5", strokeMiterlimit: "10" }}
						d="M134.5,43.8c0,0,0-1.7,1.6-1.6s2.9-0.1,3.7-0.3c1.3-0.3,2.7-0.1,3.5,1.5c0.9,1.8,0.6,2.7-1.2,3.2 c-1.4,0.4-3,0.5-5.3-0.2S134.5,43.8,134.5,43.8z"
					/>
				</g>
			</g>
			<rect
				x="3.7"
				y="16.5"
				style={{ fill: "none", stroke: "var(--primary)", strokeMiterlimit: "10" }}
				width="90.3"
				height="42.3"
			/>
			<rect
				x="93.9"
				y="16.5"
				style={{ fill: "none", stroke: "var(--primary)", strokeMiterlimit: "10" }}
				width="90.3"
				height="42.3"
			/>
			<rect
				x="184.2"
				y="7.5"
				style={{ fill: "none", stroke: "var(--orange)", strokeMiterlimit: "10" }}
				width="124.6"
				height="59.8"
			/>
		</svg>
	);
};

StrategyOne.propTypes = {
	clssName: PropTypes.string,
};

StrategyOne.defaultProps = {
	clssName: "",
};

export default StrategyOne;
