// Modules
import React, { useEffect, useRef, useContext, useState } from "react";
import Modal from 'react-modal';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Lazy,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";


// Context
import ApiContext from "../../contexts/ApiContext";

// Local
import Divider from "../utils/Divider";
import Chip from '@mui/material/Chip';
import CloseIcon from "../../assets/img/svg/Close_icon.jsx";

// Assets
import { Grid } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import LaunchIcon from '@mui/icons-material/Launch';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

const am_level5 = "Le bien est déjà équipé de l'ensemble des éléments nécessaires pour sa mise en location.";

const am_level50 = "Le bien est déjà partiellement meublé, nous complèterons pour le rendre agréable à la location dans le respect des standards de qualité de Bevouac.";

const am_level100 = "Le bien sera meublé entièrement pour être agréable à la location et correspondre aux standards de qualité de Bevouac.";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)} %`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};


const HomeImprovmentsMobile = (props) => {
  const { data, loader, rooms } = props;
  const {
    travaux_a_prevoir,
    mobilier_a_prevoir,
    details_travaux,
    plan_bien,
    plan_bien_2,
    plan_bien_3,
    est_termine,
    vieille_fiche
  } = data;

  const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);

  // We initialize the variables to avoid having some undefined values displayed on screen.
  const travauxAPrevoir = travaux_a_prevoir
    ? String(travaux_a_prevoir)
    : "Calcul en cours...";
  const mobilierAPrevoir = mobilier_a_prevoir
    ? String(mobilier_a_prevoir)
    : "Calcul en cours...";

  const jauge_trav = useRef(50);
  const jauge_mobilier = useRef(50);

  /**
   * Sets the value of the angle of the needle on the gauges.
   */
  useEffect(() => {
    if (travauxAPrevoir !== undefined && mobilierAPrevoir !== undefined) {
      switch (travauxAPrevoir) {
        case "Aucuns travaux à prévoir":
          jauge_trav.current = 5;
          break;
        case "Léger rafraîchissement":
          jauge_trav.current = 50;
          break;
        case "À rénover":
          jauge_trav.current = 100;
          break;
        default:
          jauge_trav.current = 50;
      }

      switch (mobilierAPrevoir) {
        case "Aucun ameublement":
          jauge_mobilier.current = 0;
          break;
        case "Déjà meublé":
          jauge_mobilier.current = 5;
          break;
        case "Compléter mobilier déjà présent":
          jauge_mobilier.current = 50;
          break;
        case "À meubler entièrement":
          jauge_mobilier.current = 100;
          break;
        default:
          jauge_mobilier.current = 50;
      }
    }
  }, [travauxAPrevoir, mobilierAPrevoir]);


  const StyledChip = withStyles({
    root: {
      position:"center",
      backgroundColor: "white",
      background:"#ffffff",

      borderRadius:"7px",
      boxShadow: "5px 5px 24px #e3e3e3,-5px -5px 24px #ffffff;",
      marginBottom:"5vh",
      marginTop:"5vh",
    },
    label: {
      color: "rgb(28,54,157)",
      fontSize:"1rem",
      margin:"1vw",
      
      
    },
  })(Chip);

  const customStyles = {
    content: {
      top: '50%',
      left: '60%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  
  Modal.setAppElement('#root');
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function showModal2() {
    setIsOpen2(true);
  }

  function showModal3() {
    setIsOpen3(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function closeModal2() {
    setIsOpen2(false);
  }
  function closeModal3() {
    setIsOpen3(false);
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#308fe8',
    },
  }));

  function LinearProgressWithLabel(props) {
    return (
      <Box 
        spacing={2} 
        direction="row" 
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          width: '100%', 
          height: '100%', 
          background:'#000',
          color: 'grey.500'}}>
        <Box
          sx={{
            width: '20%', 
            height:'50px',
          }}
        >
            <Typography variant="caption" component="div" color="#FFFFFF" align="center">
                Chargement de la fiche {`${Math.round(props.value)} %`}
            </Typography>
            <BorderLinearProgress color="inherit" variant="determinate" {...props} />
        </Box>
      </Box>
    );
  }

  const [progress, setProgress] = React.useState(10);
  const [isOpenFullscreen, setIsOpenFullscreen] = useState(false);

  function NewlineCommentaire(props) {
    const text = props.text;
    return text.split('\n').map(str => <p>{str}</p>);
  }
  
  return (

    
    <Grid container id="amenagements">
      
      <Divider text="Aménagements" loader={loader} />
      
      {details_travaux === null
      ? null
      : (<Grid item container className="rightContainerMobile" style={{backgroundColor:'white'}} >
            <Grid container xs={3}></Grid>
            <Grid container xs={6} alignItems="left" justifyContent="left" direction="column">
              
                <h3 className="marge-top"><strong>Détails travaux</strong></h3>
                <div style={{margin:"2vh 0", fontSize:"14px"}}><NewlineCommentaire text={`${details_travaux}`} /></div>
              </Grid>
              <Grid container xs={3}></Grid>
        </Grid>)}
        {jauge_mobilier === 0 
      ? null
      : (
      <Grid item container className="rightContainerMobile" style={{backgroundColor:'white'}} >
            <Grid container xs={3}></Grid>
            <Grid container xs={6} alignItems="left" justifyContent="left" direction="column">
            {jauge_mobilier.current == 50  || jauge_mobilier.current == 5 || jauge_mobilier.current == 100 ? <><h3 className="marge-top-10"><strong>Ameublement</strong></h3></> :""}
              {jauge_mobilier.current == 50 ? <><div style={{margin:"0 0 2vh", fontSize:"14px"}}>{am_level50}</div></> :""}
              {jauge_mobilier.current == 5 ? <><div style={{margin:"0 0 2vh", fontSize:"14px"}}>{am_level5}</div></>:""}
              {jauge_mobilier.current == 100 ? <><div style={{margin:"0 0 2vh", fontSize:"14px"}}>{am_level100}</div></> :""}
              {jauge_mobilier.current == 50  || jauge_mobilier.current == 5 || jauge_mobilier.current == 100 ? <><a href="/assets/pdf/Plaquette-travaux-2023.pdf" target="_blank" className="map">
               En savoir plus sur nos standards de qualité d’aménagement <LaunchIcon className="icon"></LaunchIcon>
              </a></> :""}
              
            </Grid>
            <Grid container xs={3}></Grid>
     </Grid>
     )}
      <Grid container>
      <Grid container xs={12} className="caroussel-container">
      

            {isOpenFullscreen === true
              ? (
                <div className="full-screen-swiper-container">
                  <div className="background-modal" onClick={() => setIsOpenFullscreen(false)} />
                  <div className={`modal-content ${isOpenFullscreen === true ? "opened" : "closed"}`}>
                    <CloseIcon clssName="close-icon" onClick={() => setIsOpenFullscreen(false)} />
                            {loader ? 
                          [1, 2, 3, 4, 6].map((index) => {
                            return (
                              <SwiperSlide key={index} >
                                <LinearProgressWithLabel value={progress} />
                              </SwiperSlide>
                                  );
                                }) : (
                                  
                          <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                navigation
                                Lazy={true}
                                pagination={{
                                  clickable: true,
                                  type: 'fraction',
                                    formatFractionCurrent: function (number) {
                                        return number;
                                    }
                                }}
                              >
                                
                              <SwiperSlide>
                              <div
                                            className="image-carousel lazyload" 
                                            loading="lazy" 
                                            style={{ backgroundImage: `url(${plan_bien})` }}
                                          >
                                          </div>
                              </SwiperSlide>
                              {plan_bien_2 === null
                                ? null
                                : (<SwiperSlide>
                                  <div
                                      className="image-carousel lazyload" 
                                      loading="lazy" 
                                      style={{ backgroundImage: `url(${plan_bien_2})` }}
                                  >
                                  </div>
                                </SwiperSlide>)}
                                {plan_bien_3 === null
                                ? null
                                : (<SwiperSlide>
                                  <div
                                      className="image-carousel lazyload" 
                                      loading="lazy" 
                                      style={{ backgroundImage: `url(${plan_bien_3})` }}
                                  >
                                  </div>
                                </SwiperSlide>)}
                              
                            </Swiper>
                            
                        )}
                    </div>
                  </div>
                ) : null}
            </Grid>
            <Grid container xs={3}></Grid>
            <Grid container xs={6} alignItems="left" justifyContent="left" direction="column" className="caroussel-container" style={plan_bien === null ? { display: "none" } : { display: "block" }}>
            
            {loader ? 
            [1].map((index) => { 
              return (
                <SwiperSlide key={index} >
                  <LinearProgressWithLabel value={progress} />
                </SwiperSlide>
                    );
                  }) : (
                    
            <Swiper
                  spaceBetween={0}
                  slidesPerView={1}
                  navigation
                  Lazy={true}
                  pagination={{
                    clickable: true,
                    type: 'fraction',
                      formatFractionCurrent: function (number) {
                          return number;
                      }
                  }}
                >
                  <svg className="fullscreen-icon" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setIsOpenFullscreen(true)} >
                    <path d="M15.6965 8.18768L23.4882 0.723877M23.4882 0.723877V7.21836M23.4882 0.723877H16.7084" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.01795 14.5853L1.22628 22.0491M1.22628 22.0491L1.22628 15.5546M1.22628 22.0491L8.00604 22.0491" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  
                <SwiperSlide>
                <div
                              className="image-carousel-plan lazyload" 
                              loading="lazy" 
                              style={{ backgroundImage: `url(${plan_bien})` }}
                            >
                            </div>
                </SwiperSlide>
                {plan_bien_2 === null
                                ? null
                                : (<SwiperSlide>
                                  <div
                                      className="image-carousel-plan lazyload" 
                                      loading="lazy" 
                                      style={{ backgroundImage: `url(${plan_bien_2})` }}
                                  >
                                  </div>
                                </SwiperSlide>)}
                {plan_bien_3 === null
                                ? null
                                : (<SwiperSlide>
                                  <div
                                      className="image-carousel-plan lazyload" 
                                      loading="lazy" 
                                      style={{ backgroundImage: `url(${plan_bien_3})` }}
                                  >
                                  </div>
                                </SwiperSlide>)}
                
                
              </Swiper>
              
          )}
              
              </Grid>
            <Grid container xs={3}></Grid>
        </Grid>
    </Grid >
  );
};

HomeImprovmentsMobile.propTypes = {
  loader: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
};

export default HomeImprovmentsMobile;
