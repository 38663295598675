// Modules
import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";

// Assets
import "./progress-bar.scss";

const ProgressBar = (props) => {
  const {clssName} = props;
  const [progress, setProgress] = useState(0);

  /* componentwillMount() */
  useEffect(() => {
    /**
     * Computes the progress of the scroll
     */
    const computeProgress = () => {
      const scrolled = document.documentElement.scrollTop;
      const scrollLength = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = (100 * scrolled) / scrollLength;

      setProgress(progress);
    };

    window.addEventListener("scroll", computeProgress);
    
    /* componentWillUnmount() */
    return () => window.removeEventListener("scroll", computeProgress);
  }, []);

  return (
    <div className="progress-bar-container">
      <LinearProgress className={clssName} variant="determinate" value={progress} />
    </div>
  );
};

ProgressBar.prototypes = {
  clssName: PropTypes.string,
}

ProgressBar.defaultProps = {
  clssName: "",
}

export default ProgressBar;
