// Modules
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"
import Airtable from "airtable";
import PropType from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

// Context
import config from "../../config/environment";

// util to get parameter query
const getParameterByName = (name, url = window.location.href) => {
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  let results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * Trigger le hook suivant https://zapier.com/shared/1638f714c52080ee6a680f523e4da9275e4d9dfb
 * Envoi du slack au CDP pour informer que le client se positionne sur le bien
 * + Ouvre le bon T4
 * @param {*} recid : id de la fiche
 */
const triggerHookPosition = async (propo_name, params_email, params_id, params_prenom, id_fiche) => {
  try {
    // const res = await API.graphql(graphqlOperation(createTodo, { input: createSongInput }));
    // console.log(res);

    // Triggers a slack message
    await axios.get(`https://hooks.zapier.com/hooks/catch/1924878/ovv83dk/?name=${params_prenom}&recid=${id_fiche}`);

    // Opens the right form T4
    const url = `https://bevouac.typeform.com/to/reLLuo#proposition=${propo_name}&email=${params_email}&id=${params_id}&prenom=${params_prenom}`;
    // Fake T4 for beta-testing
    // const url = `https://bevouac.typeform.com/to/UEJ7gN2w#proposition=${params_proposition}&email=${params_email}&id=${params_id}&prenom=${params_prenom}`;
    window.open(url, "_blank");
  }
  catch (err) {
    console.error("Failed in triggering Zapp for accepting proposal", err);
  }
  // trigger slack message
  // Auth.currentUserInfo().then(function (result) {
  //   fetch('https://hooks.zapier.com/hooks/catch/1924878/ovv83dk/?name=' + result.username + '&recid=' + recid)
  //     .then(response => response.json());
  // });;
};

/**
 * Trigger le hook suivant https://zapier.com/shared/1638f714c52080ee6a680f523e4da9275e4d9dfb
 * Envoi du slack au CDP pour informer que le client a une question sur le bien
 * + Ouvre le bon T4
 * @param {*} recid : id de la fiche
 */
const triggerHookQuestion = async (propo_name, params_email, params_id, params_prenom, id_fiche) => {
  try {
    // trigger slack message
    await axios.get(`https://hooks.zapier.com/hooks/catch/1924878/byogcdv/?name=${params_prenom}&recid=${id_fiche}`);

    // opens the right form T4
    const url = `https://bevouac.typeform.com/to/Pywu2j#proposition=${propo_name}&email=${params_email}&id=${params_id}&prenom=${params_prenom}`;
    // Fake T4 for beta-testing
    // const url = `https://bevouac.typeform.com/to/fiOnZ7Ww#proposition=${params_proposition}&email=${params_email}&id=${params_id}&prenom=${params_prenom}`;

    window.open(url, "_blank");
  }
  catch (error) {
    console.error("Failed in triggering Zapp for questions", error);
  }
  //Auth.currentUserInfo().then(function (result) {
  // fetch('https://hooks.zapier.com/hooks/catch/1924878/byogcdv/?name=' + result.username + '&recid=' + recid)
  //   .then(response => response.json());
  //});;
};

/**
 * Trigger le hook suivant https://zapier.com/shared/1638f714c52080ee6a680f523e4da9275e4d9dfb
 * Envoi du slack au CDP pour informer que le client refuse le bien
 * + Ouvre le bon T4
 * @param {*} recid : id de la fiche
 */
const triggerHookDecliner = async (propo_name, params_email, params_id, params_prenom, id_fiche) => {
  try {
    // Triggers a slack message
    await axios.get(`https://hooks.zapier.com/hooks/catch/1924878/byogwoy/?name=${params_prenom}&recid=${id_fiche}`);

    // Opens the right form: T4 mon retour
    const url = `https://bevouac.typeform.com/to/aT2jCl#proposition=${propo_name}&email=${params_email}&id=${params_id}&prenom=${params_prenom}`;
    // Fake T4 for beta-testing
    // const url = `https://bevouac.typeform.com/to/bbs0QcWm#proposition=${params_proposition}&email=${params_email}&id=${params_id}&prenom=${params_prenom}`;

    window.open(url, '_blank');
  }
  catch (error) {
    console.error("Failed in triggering Zapp for refusing a proposal", error);
  }
  // Auth.currentUserInfo().then(function (result) {
  //   fetch('https://hooks.zapier.com/hooks/catch/1924878/byogwoy/?name=' + result.username + '&recid=' + recid)
  //     .then(response => response.json());
  // });;
};

//util: Fonction de génération de hash:
const MD5 = function (phrase) {
  phrase = phrase.toLowerCase();
  var hash = 0, i, chr;
  if (phrase.length === 0) { return hash; }
  else {
    for (i = 0; i < phrase.length; i++) {
      chr = phrase.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
};


const UserLogged = (props) => {
  const {
    id_fiche,
    awsUserMail,
  } = props;

  // query string: ?foo=lorem&bar=&baz
  let loc = useLocation().search;
  let params_proposition = getParameterByName("proposition", loc);

  if (params_proposition == null) params_proposition = "not record id";

  const [propo_user_mail, setpropo_user_mail] = useState("");
  const [propo_status, setpropo_status] = useState("");
  const [id_client, set_id_client] = useState("");
  const [prenom_client, set_prenom_client] = useState("");
  const [decryptedAwsMail, setdecryptedAwsMail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidClient, setIsValidClient] = useState(false);
  const [propo_name, setPropoName] = useState("");

  useEffect(() => {
    // Récupération mail client hashé au niveau de la proposition déclarée dans l'url
    let base = new Airtable({ apiKey: config.AIRTABLE_API_KEY }).base(config.AIRTABLE_BASE);

    base("Propositions").find(params_proposition, function (err, record) {
      if (err) {
        console.error("propo not found", err);
        setpropo_user_mail("no proposition");
        return;
      }

      // Récupération de l'état de la proposition : 
      // Si = à "Proposition envoyée" alors OK , sinon afficher un variant de "PageNotFound" avec le message : "Cette proposition n'est plus d'actualité."
      // Ne faire ca uniquement si l'utilisateur est loggué, avec le bon mail, dans le cas contraire afficher "oups cette proposition ne vous est pas att"
      // console.log('etape proposition : ', record.fields['Etape Proposition']);

      setPropoName(record.fields['Proposition']);
      setpropo_status(record.fields['Etape Proposition']);
      setpropo_user_mail(record.fields["hashmail_acquéreur_1 (from Client)"]);
      set_id_client(record.fields["#ID client"]);
      set_prenom_client(record.fields["Prénom client"]);
      //setPropositionHash(record["fields"]);
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (awsUserMail !== "") {
      const decryptedEmail = MD5(awsUserMail);

      setdecryptedAwsMail(decryptedEmail);
    }
  }, [awsUserMail]);

  useEffect(() => {
    if (decryptedAwsMail !== null && propo_user_mail[0] !== undefined) {
      setIsLoading(false);
      // console.log("comparing :");
      // console.log(propo_user_mail[0]);
      // console.log(decryptedAwsMail);
      if (String(decryptedAwsMail) === String(propo_user_mail[0])) {
        setIsValidClient(true);
        // console.log('valid login');
      }
      else {
        // console.log('invalid login');
        setIsValidClient(false);
      }
    }
  }, [decryptedAwsMail, propo_user_mail]);

  return (
    <React.Fragment>
      {isLoading === true
        ? (
          <div className="loader-wrapper">
            <CircularProgress className="loader" />
          </div>
        ) : isLoading === false && isValidClient === true && propo_status === "Proposition envoyée"
          ? (
            <div className="buttons-wrapper">
              <button
                onClick={() => triggerHookQuestion(propo_name, awsUserMail, id_client, prenom_client, id_fiche)}
                className="action-button"
              >
                Poser une question
              </button>
              <button
                className="action-button"
                onClick={() => triggerHookPosition(propo_name, awsUserMail, id_client, prenom_client, id_fiche)}
              >
                se positionner
              </button>
              <button
                className="action-button"
                onClick={() => triggerHookDecliner(propo_name, awsUserMail, id_client, prenom_client, id_fiche)}
              >
                Décliner l'offre
              </button>
              <script src="https://apps.elfsight.com/p/platform.js" defer></script>
              <div className="elfsight-app-43a3b5a4-94b9-4705-ab47-f368d8023dcb"></div>
            </div>
          ) : isLoading === false && isValidClient === true && propo_status !== "Proposition envoyée"
            ? (
              <div className="error-wrapper">
                <h3 className="error">{"OUPS ! Cette proposition n'est plus d'actualité !"}</h3>
                <p className="message-cta">{"Si vous n’avez jamais été contacté par Bevouac et souhaitez recevoir des opportunités aussi :"}</p>
                <button
                  className="cta-button"
                  onClick={() => window.open("https://bevouac.typeform.com/to/OloPIV#conseiller=bevouac-yael-bouchoucha}}", "_blank")}
                >
                  {`Définir mon projet`}
                </button>
              </div>
            ) : (
              <div className="error-wrapper">
                <h3 className="error">{"OUPS ! Ce bien ne vous a pas été proposé !"}</h3>
                <p className="message">{"Si vous êtes déjà client Bevouac, assurez-vous d'être bien connecté avec l'adresse email que vous nous avez transmise."}</p>
                <p className="message-cta">{"Si vous n’avez jamais été contacté par Bevouac et souhaitez recevoir des opportunités aussi :"}</p>
                <button
                  className="cta-button"
                  onClick={() => window.open("https://bevouac.typeform.com/to/OloPIV#conseiller=bevouac-yael-bouchoucha}}", "_blank")}
                >
                  {`Définir mon projet`}
                </button>
              </div>)}
    </React.Fragment>
  );
};

UserLogged.propTypes = {
  awsUserMail: PropType.string.isRequired,
  id_fiche: PropType.string.isRequired,
};

export default UserLogged;