// Modules
import React, { useContext, useState } from "react";
import SwiperCore, {
  Pagination,
  Autoplay,
  Lazy,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "@material-ui/lab/Skeleton";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// Context
import ApiContext from "../../contexts/ApiContext";

// Assets
import CloseIcon from "../../assets/img/svg/Close_icon.jsx";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, Lazy]);

const CarouselMobile = (props) => {
  const {
    loader,
    data,
    rooms,
    imagesNeighborhoodArr,
  } = props;
  const {
    photo_interieur_toilettes,
    photo_copro,
    photo_facade,
    photo_interieur_idr_lot,
    descriptif_bien_2,
    descriptif_bien_3,
    m2_carrez,
    ville,
    quartier,
    est_termine,
    photo_salon_apres,
    titre_fiche_frml,
    eligible_denormandie_chck,
  } = data;
  const contentIframe = (
    <iframe
      title="view-3d"
      width="100%"
      height="100%"
      className="iframe-matterport"
      src={data["visite virtuelle"]}
      frameBorder="0"
      allowFullScreen
    />
  );

  // Slides for regular appartments
  const slidesRegular = rooms.length > 0 && (est_termine === false || est_termine === undefined)
    ? [
      contentIframe,
      ...rooms.map((room, idx) => idx >= 2 ? room.img : null),
      ...rooms.map((room, idx) => idx <= 1 ? room.img : null),
      photo_interieur_toilettes,
      photo_copro,
      photo_facade,
      
    ] : rooms.length > 0 && est_termine === true
      ? [
        photo_salon_apres,
        ...rooms.map((room) => room.imgBack),
        photo_copro,
        photo_facade,
      ] : [
        photo_salon_apres,
        contentIframe,
        photo_interieur_toilettes,
        photo_copro,
        photo_facade,
      ];
  // Slides for IDR and Lots
  const slidesIDR = rooms.length > 0 && photo_interieur_idr_lot.length > 0 && (est_termine === false || est_termine === undefined)
    ? [
      contentIframe,
      ...rooms.map((room, idx) => idx >= 2 ? room.img : null),
      ...rooms.map((room, idx) => idx <= 1 ? room.img : null),
      ...photo_interieur_idr_lot,
      photo_interieur_toilettes,
      photo_copro,
      photo_facade,
      ...rooms.map((room) => room.imgBack !== null && room.img !== null ? room.imgBack : null),
    ] : rooms.length > 0 && photo_interieur_idr_lot.length > 0 && est_termine === true
      ? [
        ...rooms.map((room) => room.imgBack),
        photo_copro,
        photo_facade,
      ] : photo_interieur_idr_lot?.length > 0
        ? [
          contentIframe,
          ...photo_interieur_idr_lot,
          photo_interieur_toilettes,
          photo_copro,
          photo_facade,
        ] : [
          contentIframe,
          photo_interieur_toilettes,
          photo_copro,
          photo_facade,
        ];
  const slides = photo_interieur_idr_lot?.length > 0 ? slidesIDR : slidesRegular;

  // Legends for regular appartments
  const slidesLegendsRegular = rooms.length > 0 && (est_termine === false || est_termine === undefined)
    ? [
      ...rooms.map((room, idx) => idx <= 1 ? room.label : null),
      "Vue 3D du bien",
      ...rooms.map((room, idx) => idx >= 2 ? room.label : null),
      "Toilettes",
      "Parties communes",
      "Façade",
      `${ville}`,
      ...imagesNeighborhoodArr.map(() => `${quartier}`),
      ...rooms.map((room) => room.imgBack !== null && room.img !== null ? `${room.label} - Exemple après travaux` : null),
    ] : rooms.length > 0 && est_termine === true
      ? [
        ...rooms.map((room) => `${room.label} après travaux`),
        "Parties communes",
        "Façade",
        `${ville}`,
        ...imagesNeighborhoodArr.map(() => `${quartier}`),
      ] : [
        "Vue 3D du bien",
        "Toilettes",
        "Parties communes",
        "Façade",
        `${ville}`,
        ...imagesNeighborhoodArr.map(() => `${quartier}`),
      ];
  // Legends for IDR and Lots
  const slidesLegendsIDR = rooms.length > 0 && photo_interieur_idr_lot?.length > 0 && (est_termine === false || est_termine === undefined)
    ? [
      ...rooms.map((room, idx) => idx <= 1 ? room.label : null),
      "Vue 3D du bien",
      ...rooms.map((room, idx) => idx >= 2 ? room.label : null),
      ...photo_interieur_idr_lot.map(() => null),
      "Toilettes",
      "Parties communes",
      "Façade",
      `${ville}`,
      ...imagesNeighborhoodArr.map(() => `${quartier}`),
      ...rooms.map((room) => room.imgBack !== null && room.img !== null ? `${room.label} - Exemple après travaux` : null),
    ] : rooms.length > 0 && photo_interieur_idr_lot?.length > 0 && est_termine === true
      ? [
        ...rooms.map((room) => `${room.label} après travaux`),
        ...photo_interieur_idr_lot.map(() => null),
        "Parties communes",
        "Façade",
        `${ville}`,
        ...imagesNeighborhoodArr.map(() => `${quartier}`),
      ] : photo_interieur_idr_lot?.length > 0
        ? [
          "Vue 3D du bien",
          ...photo_interieur_idr_lot.map(() => null),
          "Toilettes",
          "Parties communes",
          "Façade",
          `${ville}`,
          ...imagesNeighborhoodArr.map(() => `${quartier}`),
        ] : [
          "Vue 3D du bien",
          "Toilettes",
          "Parties communes",
          "Façade",
          `${ville}`,
          ...imagesNeighborhoodArr.map(() => `${quartier}`),
        ];
  const slidesLegends = photo_interieur_idr_lot?.length > 0 ? slidesLegendsIDR : slidesLegendsRegular;;

  const opportunityTitle = `${descriptif_bien_2} de ${m2_carrez}m² ${descriptif_bien_3} à ${ville}`;
  const { isMobile, isTablet, isSplitScreen } = useContext(ApiContext);
  const [isOpenFullscreen, setIsOpenFullscreen] = useState(false);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#308fe8',
    },
  }));

  function LinearProgressWithLabel(props) {
    return (
      <Box 
        spacing={2} 
        direction="row" 
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          width: '100%', 
          height: '100%', 
          background:'#000',
          color: 'grey.500'}}>
        <Box
          sx={{
            width: '40%', 
            height:'50px',
          }}
        >
            <Typography variant="caption" component="div" color="#FFFFFF" align="center">
                Chargement de la fiche {`${Math.round(props.value)} %`}
            </Typography>
            <BorderLinearProgress color="inherit" variant="determinate" {...props} />
        </Box>
      </Box>
    );
  }
  

  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div>
        <div className={`caroussel-container`}>
      {isOpenFullscreen === true
        ? (
          <div className="full-screen-swiper-container-mobile">
            <div className="background-modal" onClick={() => setIsOpenFullscreen(false)} />
            <div className={`modal-content ${isOpenFullscreen === true ? "opened" : "closed"}`}>
              <CloseIcon clssName="close-icon" onClick={() => setIsOpenFullscreen(false)} />
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                  type: 'fraction',
                    formatFractionCurrent: function (number) {
                        return number;
                    }
                }}
                Lazy={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
              }}
              >
                {loader
                  /* /!\ Numérotation ? */
                  ? [1, 2, 3, 4, 6].map((slide, index) => {
                    return (
                      <SwiperSlide key={index} >
                        <LinearProgressWithLabel value={progress} />
                      </SwiperSlide>
                    );
                  })
                  : slides.map((slide, index) => {
                    if (data["visite virtuelle"] && slide !== null) {
                      if (contentIframe === slide)
                      return null;
                      else
                        return (
                          <SwiperSlide key={index}>
                            <div
                              className="image-carousels"
                              style={{ backgroundImage: `url(${slide})` }}
                            />
                          </SwiperSlide>
                        );
                    }
                    else if (slide !== null) {
                      if (contentIframe === slide)
                        return null;
                      else
                        return (
                          <SwiperSlide key={index}>
                            <div
                              className="image-carousels"
                              style={{ backgroundImage: `url(${slide})` }}
                            />
                          </SwiperSlide>
                        );
                    }
                    else
                      return null;
                  })}
              </Swiper>
            </div>
          </div>
        ) : null}
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        Lazy={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
        type: 'fraction',
          formatFractionCurrent: function (number) {
              return number;
          }
      }}
      >
        {loader
          /* /!\ Numérotation ? */
          ? [1, 2, 3, 4, 6].map((slide, index) => {
            return (
              <SwiperSlide key={index} >
                <SwiperSlide key={1} >
                <LinearProgressWithLabel value={progress} />
                </SwiperSlide>
              </SwiperSlide>
            );
          })
          : slides.map((slide, index) => {
            if (data["visite virtuelle"] && slide !== null) {
              if (contentIframe === slide)
              return null;
              else
              
                return (
                  <SwiperSlide key={index}>

                      
                      <div
                        className="image-carousels"
                        style={{ backgroundImage:`url(${slide})`}}
                      >
                      
                      </div>

                  </SwiperSlide>
                );
            }
            else if (slide !== null) {
              if (contentIframe === slide)
                return null;
              else
                return (
                  <SwiperSlide key={index}>
                    

                      
                      <div
                        className="image-carousels"
                        style={{ backgroundImage:`url(${slide})`}}
                      >
                      
                      </div>

                  </SwiperSlide>
                );
            }
            else
              return null;
          })}
      </Swiper>
      </div>
      <div className={`${eligible_denormandie_chck === true ? "ban-denormandie" : "ban-denormandie-invisible"}`}>
        Bien éligible Denormandie
      </div>
      <div className={`caroussel-container`}>
      <Grid container item>
      <Grid item xs={11} style={{zIndex:"1"}}>
        {loader
          ? (
            <Skeleton
              variant="text"
              width="35%"
              height="8vh"
              className="opportunity-title-mobile"
            />
          ) : <h3 className="opportunity-title-mobile">{titre_fiche_frml}</h3>}
       {/* <ShareButtons
          loading={loader}
          title={opportunityTitle}
          resume={data.resume ?? ""}
          id={idFiche}
          clssName="social-networks-share"
        />*/} 
      </Grid>
      <Grid item xs={1} style={{zIndex:"1"}}></Grid>
      </Grid>
      </div>
    </div>
  );
};

CarouselMobile.propTypes = {
  loader: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  idFiche: PropTypes.string.isRequired,
  rooms: PropTypes.array.isRequired,
  imagesNeighborhoodArr: PropTypes.array.isRequired,
};

export default CarouselMobile;